body .confirmButton {
    width: 163px;
    height: 32px;
    // transform: translateX(-50%);
    border: 1.5px solid rgba(0, 134, 110, 0.2);
    border-radius: 1000px;
    text-transform: none !important;
    overflow: hidden !important;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #00866E;
    text-align: center;
    background-color: #fff;
    text-decoration: none;


    margin-left: 44px;
    margin-top: 16px;

}

.page {
    position: relative;
    background-color: #fff;
    padding-bottom: 204px;
}


.header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #130F26;
    }
}


.container {
    padding: 0 24px;

    .titleContainer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 25.5px 0;
        margin-bottom: 37px;
        display: flex;
        align-items: center;


        .titleImage {
            width: 48px;
            height: 48px;
            background: rgba(0, 134, 110, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: 21px;
            line-height: 22px;
            color: #00866E;
            margin-right: 16px;
            border-radius: 100%;
        }

        .titleTextContainer {
            flex: 1
        }

        .titleHeadertext {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
            margin-bottom: 8px;
            text-transform: capitalize;
        }

        .titleHeaderSubText {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            color: rgba(19, 15, 38, 0.6);
            text-transform: capitalize;
        }
    }

    .listHeaderContainer {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        margin-top: 37px;
        margin-bottom: 10px;
        color: #130F26;

        svg {
            margin-right: 20px;
        }
    }

    .listText {
        position: relative;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #130F26;
        display: flex;
        align-items: flex-start;
        gap: 5px;
        padding-left: 44px;

        .listTextValue {
            flex: 1;
        }

    }




}





.fab {
    position: fixed !important;
    z-index: 1;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFFFFF !important;
    background-color: #00866E !important;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2) !important;
}