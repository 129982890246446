body .confirmButton {
    width: 208px;
    height: 48px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100px !important;
    text-transform: none !important;
    overflow: hidden !important;


    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    background-color: #00866E;
    z-index: 2;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.5);

    &:disabled {
        pointer-events: none;
        opacity: 0.8;
    }
}

.page {
    position: relative;
    background-color: #fff;
    padding-bottom: 204px;
}


.header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;

    .back {
        margin-right: 16px;
    }

    .avater {
        width: 40px;
        height: 40px;
        margin-right: 12px;
    }

    .headerTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #130F26;
        margin-bottom: 6px;
    }

    .headerSubText {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        color: rgba(0, 0, 0, 0.6)
    }
}

.sliderTagContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 24px;
    margin-bottom: 32px;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }


    .sliderTag {
        flex: none;
        position: relative;
        display: flex;
        align-items: center;
        padding: 8px 5px 8px 16px;
        height: 30px;
        background-color: rgba(19, 15, 38, 0.08);
        border-radius: 100px;
        margin-right: 12px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba(19, 15, 38, 0.7);
        cursor: pointer;

        &.selected {
            background-color: #00866E;
            color: #FFFFFF
        }

        .sliderTagDot {
            height: 20px;
            background-color: #FFFFFF;
            border-radius: 100px;
            padding: 6px;
            margin-left: 8px;


            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            text-align: right;
            letter-spacing: 0.01em;
            color: #130F26;
        }
    }
}

.container {
    padding: 0 24px;
}


.filterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 18px 0;
    margin-bottom: 18px;

    .orderText {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #130F26;
        margin-right: 8px;
    }

    .filterText {
        display: flex;
        align-items: center;
        padding: 6px 6px 3px;
        height: 20px;
        background-color: rgba(0, 134, 110, 0.15);
        border-radius: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        text-align: right;
        letter-spacing: 0.01em;
        color: #00866E;
        margin-left: 8px;
    }
}


.listContent {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 12px;
    border: 1.5px solid rgba(0, 134, 110, 0.15);
    border-radius: 16px;
    margin-bottom: 8px;

    &.selected {
        border: 1.5px solid rgba(#00866E, 0.5);
    }

    .img {
        width: 48px;
        height: 48px;
        border-radius: 6px;
        overflow: hidden;
        background-color: rgba(19, 15, 38, 0.08);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .textContainer {
        flex: 1;
        padding: 0 12px;
    }

    .nameText {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #130F26;
        text-transform: capitalize;
        margin-bottom: 6px;
    }

    .descText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba($color: #130F26, $alpha: 0.6)
    }
}


.sortContainer {
    position: fixed;
    max-height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    z-index: 2001;
    background-color: rgba(19, 15, 38, 0.2);

    &.makeMobile {
        max-width: 375px !important;
    }

    .contentContainer {
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: #fff;
        padding: 40px 24px;
        border-radius: 24px 24px 0 0;
    }

    .sortHeader {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #130F26;
        padding-bottom: 20px;
        border-bottom: 1.5px solid rgba(0, 134, 110, 0.15);
    }

    .sortList {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 1.5px solid rgba(0, 134, 110, 0.15);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #130F26;

        &.selected {
            color: #00866E;
        }
    }
}

.tagContainer {
    position: fixed;
    max-height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    z-index: 2001;
    background-color: rgba(19, 15, 38, 0.2);

    &.makeMobile {
        max-width: 375px !important;
    }

    .contentContainer {
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: #fff;
        padding: 40px 0;
        border-radius: 24px 24px 0 0;
    }

    .tagHeader {
        position: sticky;
        width: 100%;
        top: -40px;
        z-index: 1;
        background-color: #fff;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1.5px solid rgba(0, 134, 110, 0.15);

        .tagHeaderText {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
        }

        .tagHeaderCount {
            min-width: 20px;
            background-color: #130F26;
            border-radius: 100px;
            padding: 6px 6px 3px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            text-align: right;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            text-align: center;

        }
    }

    .tagSearch {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        width: 176px;
        height: 48px;

        background-color: #00866E;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
        border-radius: 100px;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;

        color: #FFFFFF;
    }


}