.page {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    padding: 24px 0;
    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px;
      padding-bottom: 50px;
      .head {
        color: #130f26;
        text-align: center;
        font-family: Epilogue;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  table {
    margin: 20px;
    width: 100%;
    td, th {
      padding: 10px;
      font-size: 14px;
      text-align: left;
    }
  }
  
  
  