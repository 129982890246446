.emailPageContent {
    padding: 24px;

    .subTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #3C3C3C;
        margin-bottom: 33px;
    }

    .textField {
        margin-bottom: 28px;
    }

    .helperText {
        flex-grow: 1;
        display: flex;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #BAC2D0;
        margin-right: 16px;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 28px;

        .timer {
            display: inline-block;
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            text-align: center;
        }
    }

    .resend {
        height: 48px;
        border-radius: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFF;
        text-transform: none;
        margin-bottom: 28px;
    }

    .button {
        height: 48px;
        border-radius: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFF;
        text-transform: none;
        margin-bottom: 28px;
    }
}