.page {
    padding-top: 24px;

    .filterTagContainer {
        padding: 0 24px;
        margin-bottom: 32px;

        .tapHeaderText {
            display: block;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: #130F26;
            margin-bottom: 12px;
        }

        .chip {
            height: 36px;
            min-width: 56px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
            background-color: #fff;
            border-radius: 100px;
            margin: 0 12px 12px 0;
            mix-blend-mode: normal;
            border: 1.5px solid rgba($color: #130F26, $alpha:0.2);
            text-transform: capitalize;
        }

        .chipSelected {
            height: 36px;
            min-width: 56px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #ffff;
            background-color: #130F26;
            border-radius: 100px;
            margin: 0 12px 12px 0;
            text-transform: capitalize;
        }

        .selectAndAddContainer {
            display: flex;
            align-items: center;
        }

        .selectInput {
            width: 193px;
            overflow: hidden;
            border: 1.5px solid rgba($color: #130F26, $alpha: 0.2);
            border-radius: 100px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;

            .select {
                padding-left: 16px;
                padding-top: 9px;

                &:focus {
                    background-color: transparent;
                }
            }

            .dropDownIcon {
                width: 12px;
                height: 12px;
                top: calc(50% - 6px);
                right: 8px;
                transform: rotateZ(-90deg);
            }
        }

        .addIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            min-width: 36px;
            min-height: 36px;
            margin: 12px 6px;
            color: #130F26;
            border: 1.5px solid rgba($color: #130F26, $alpha: 0.2);
            border-radius: 100%;
        }

    }
}