.page {
    position: fixed;
    background-color: #00866E;
    width: 100%;
    height: 100%;
    z-index: 2005;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    padding-top: 36px;  

    .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 20px 20px 0 0;
        position: relative;
        padding: 40px 24px;
        gap: 24px;
        overflow-y: auto;
     }

     .header {
        display: flex;
        flex-direction: column;

     }

     .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        // overflow-y: scroll;
        padding-bottom: 24px;
     }

     .products{
        display: flex;
        // flex-direction: column;
        align-items: center;
        padding: 16px;
        grid-gap: 8px;
        gap: 8px;
        width: 100%;
        border: 1.5px solid #e5f3f0;
        border-radius: 16px;
        background: #e5f3f0;
     }
     
     .addPictureBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        gap: 8px;
        margin: 5px 0;
        background: transparent;
        border-radius: 16px;
        border: 1.5px solid rgba(19, 15, 38, 0.1);
        color: #FFFFFF;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;

        .editIcon {
           width: 24px;
           height: 24px;
           cursor: pointer;
           color: #130F26;
       }

        .text{
           margin: 0;
           font-style: normal;
           font-weight: 400;
           font-size: 14px;
           line-height: 16px;
           color: #130f26;
        }
     }

     .title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #130F26;        
     }

     .text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(19, 15, 38, 0.6);
        margin: 0;
     }


     .listImage {
      width: 48px;
      height: 48px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 6px;
      margin-right: 12px;
   }

   .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: contain;
   }

   .input{
      display: none;
  }

  .backIcon{
      margin: 24px;
      color: white;
  }
}

.open {
    opacity: 1;
    pointer-events: visible;
 
    .modalContent {
       transform: translateY(0);
    }
}

.disableScrollbars {
   scrollbar-width: none;
   /* Firefox */
   -ms-overflow-style: none;
   /* IE 10+ */

   &::-webkit-scrollbar {
      background: transparent;
      /* Chrome/Safari/Webkit */
      width: 0px;
   }
}

.doneBtn {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   padding: 10px 0;

   .btnCtn {
      width: 154px;
      height: 48px;

      .btn {
         background-color: #00866e;
         box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
         border: none !important;
         border-radius: 100px !important;
         z-index: 3000;
         text-transform: none !important;
         overflow: hidden !important;
         width: 100%;
         height: 100%;
         font-style: normal;
         font-weight: 600;
         font-size: 16px;
         line-height: 16px;
         text-align: center;
         color: #ffffff;

         &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            backdrop-filter: blur(10px);
         }
      }
   }
}