.modal {
  position: fixed;
  background: rgba(19, 15, 38, 0.2);
  width: 100%;
  display: flex;
  max-width: inherit;
  z-index: 2005;
  top: 0%;
  bottom: 0;
  max-height: 100vh;
  align-items: flex-end;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  pointer-events: none;

  &.makeMobile {
    max-width: 375px !important;
  }

  .modalContent {
    width: 100%;
    background: #ffffff;
    border-radius: 20px 20px 0 0;
    position: relative;
    transform: translateY(100%);
    transition: all 0.5s ease-in-out;
  }

  .modalHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-top: 4px;

    .modalHeaderText {
      color: #000000;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      margin: 0;
    }
  }
}

.open {
  opacity: 1;
  pointer-events: visible;

  .modalContent {
    transform: translateY(0);
  }
}

.hide {
  display: none;
}
.icns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  .it {
    color: var(--Black, #130f26);
    font-family: Epilogue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 0;
  }
}
