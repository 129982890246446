.header {
    background-color: #00866E;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
}

.headerTop {
    position: relative;
    height: 56px;
    padding: 0 24px;
    background-color: #00866E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;

    .closeIcon {
        width: 24px;
        height: 24px;
        color: #fff;
    }

    .MoreVertIcon {
        color: #fff;
    }

    .circleSvg {
        position: absolute;
        right: 56px;
        top: -18px;
        width: 66px;
        height: 66px;
    }

    .blueWave {
        position: absolute;
        right: 120px;
        top: 40%;
        width: 31px;
        height: 7px;
        fill: #1D65CE;
        stroke: #1D65CE;
    }
}


.headerMiddle {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 156px;
    background-color: #00866E;
    border-bottom-left-radius: 200px 40px;
    border-bottom-right-radius: 200px 40px;
    position: -webkit-sticky;
    position: sticky;
    top: 56px;
    z-index: 1000;

    .collectionText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 12px;
    }

    .pointsText {
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 41px;
        text-align: center;
        color: #FFFFFF;
    }

    .blueWave {
        position: absolute;
        right: 0px;
        top: 40%;
        width: 31px;
        height: 7px;
        fill: #1D65CE;
        stroke: #1D65CE;
    }

    .yellowWave {
        position: absolute;
        right: 220px;
        bottom: 30px;
        width: 31px;
        height: 7px;
        fill: #FFD772;
        stroke: #FFD772;
    }

    .cirleDotOne {
        position: absolute;
        right: 50px;
        bottom: 50px;
        width: 7px;
        height: 7px;
        color: #1D65CE;
    }

    .cirleDotTwo {
        position: absolute;
        left: 100px;
        top: 0px;
        width: 7px;
        height: 7px;
        color: #1D65CE;
    }

    .cirleDotThree {
        position: absolute;
        left: 20px;
        top: 50%;
        width: 7px;
        height: 7px;
        color: #FFD772;
    }

    .cirleDotOutlineOne {
        position: absolute;
        right: 29.6%;
        top: 7.14%;
        width: 7px;
        height: 7px;
        color: #FFD772;
    }

    .cirleDotOutlineTwo {
        position: absolute;
        right: 53.83%;
        bottom: 10%;
        width: 7px;
        height: 7px;
        color: #FFD772;
    }

    .cirleDotOutlineThree {
        position: absolute;
        left: 30px;
        top: 45%;
        width: 10px;
        height: 10px;
        color: #1D65CE;
    }

    .cirleDotLarge {
        position: absolute;
        right: 100px;
        bottom: -20px;
        width: 57px;
        height: 57px;
        color: #fff;
        opacity: 0.2;
    }
}

.appBar {
    box-shadow: none !important;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    background-color: #fff !important;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 212px !important;
    z-index: 1000;
    margin-bottom: 24px;


    .tab {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        text-transform: none;
        color: #130F26;
    }


}


.yourRecipe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #130F26;
    margin-bottom: 16px;

    .seeAll {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
        color: #00866E;
        padding-left: 5px;
        cursor: pointer;
    }
}

.slider {
    margin-bottom: 18px;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    .card {
        min-width: 88px !important;
        margin: 0 0 0 16px;
        box-shadow: none;
        border-radius: 20px;
        overflow: hidden;
        background-color: rgba(0, 134, 110, 0.06);
        cursor: pointer;
    }

    .cardMedia {
        height: 88px !important;
    }
}


.list {
    .listAvatar {
        min-width: 64px;
    }

    .avatar {
        width: 48px;
        height: 48px;
        background-color: rgba(19, 15, 38, 0.04);
        border-radius: 12px;

        img {
            width: 24px;
            height: 24px;
        }
    }

    .primaryTextContainer {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .primaryText {
            flex-grow: 1;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .primaryTextRight {
            flex: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
        }

        .badge {
            flex: none;
            width: 16px;
            height: 16px;
            margin: 0 6px;
        }
    }


    .secondaryTextContainer {
        width: 100%;
        display: flex;
        align-items: center;

        .secondaryText {
            flex-grow: 1;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.6);
        }

        .secondaryTextRight {
            flex: none;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.6);
            margin-left: 6px;
        }
    }


}