.recipeWidgetContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.recipeWidgetContainerPadding {
    padding: 24px;
}

.popularCard {
    border-radius: 20px !important;
    overflow: hidden !important;
    cursor: pointer !important;
    background-color: rgba(0, 134, 110, 0.06);
}

.card {
    flex: none;
    width: calc(50% - 8px) !important;
    mix-blend-mode: normal !important;
    box-shadow: none !important;
    outline: none !important;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    .img {
        height: 220px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(0, 134, 110, 0.06);
        border-radius: 20px;
    }

    .titleContainer {
        padding: 0;
        display: flex;
        align-items: center;
        position: relative;
    }

    .chefImg {
        width: 32px;
        height: 32px;
        margin-right: 6px;
        border: 2px solid #fff;
        overflow: hidden !important;
        transform: translateY(-25%);

        img {
            border-radius: 100%;
            overflow: hidden;
        }
    }


    .chefName {
        text-transform: capitalize;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: rgba($color: #130F26, $alpha: 0.7);
    }

    .title {
        text-transform: capitalize;
        padding: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #130F26;
        /* number of lines to show */
        height: 37px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

}