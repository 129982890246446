.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 76px;

    .seeAllButton {
        width: 118px;
        height: 48px;
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        border: 2px solid rgba(19, 15, 38, 0.15) !important;
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        background-color: #fff;
        z-index: 2;
    }


    .header {
        height: 56px;
        padding: 0 24px 0;
        display: flex;
        align-items: center;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: #fff;

        .headerText {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #130F26;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .appBar {
        box-shadow: none !important;
        position: -webkit-sticky;
        position: sticky;
        top: 56px !important;
        z-index: 1000;
        border-bottom: none;
        background-color: #fff !important;

        .tab {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: rgba(19, 15, 38, 0.7);
            text-transform: capitalize !important;
            min-width: 72px !important;
        }

        .tabSelected {
            color: #00866E;
        }

        .indicator {
            display: flex;
            justify-content: center;
            background-color: transparent !important;

            span {
                width: 100%;
                max-width: 40px;
                height: 2px;
                background-color: #00866E;
            }
        }

    }

    .contentContainer{
        padding-top: 24px;
    }
}