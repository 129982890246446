@import url(https://fonts.googleapis.com/css?family=Montserrat);
.baseContainer {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 250px;
}
.friendTextDiv {
  display: flex;
  margin-left: 0.5rem;
  position: relative;
}

.friendTextContainer {
  display: flex;
  flex-direction: column;
  // width: 380px;
}

.friendText {
  background: rgba(19, 15, 38, 0.05);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #130f26;
  height: fit-content;
  min-width: fit-content;
  width: fit-content;
  // max-width: 100%;
  padding: 0.5rem 1rem;
  margin: 0.42rem 0.5rem;
  margin-left: 20px;
  margin-right: 20px;
}

.myTextDiv {
  display: flex;
  justify-content: flex-end;
}
.myTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.myText {
  background: rgba(29, 101, 206, 0.1);
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: right;
  color: #1d65ce;
  height: fit-content;
  //   width: fit-content;
  max-width: 250px;
  padding: 0.5rem 1rem;
  margin: 0.42rem 0.5rem;
  margin-right: 20px;
}

.myTextContainer > div:first-child {
  border-radius: 0.5rem 1rem 0.2rem 0.5rem;
}
.myTextContainer > div:last-child {
  border-radius: 0.5rem 0.2rem 1rem 0.5rem;
}
.friendTextContainer > div:first-child {
  border-radius: 1rem 0.5rem 0.2rem 0.5rem;
}
.friendTextContainer > div:last-child {
  border-radius: 0.5rem 0.2rem 0.5rem 1rem;
}
.input {
  padding: 15px;
  background: #dddfe4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.inp {
  width: 351px;
  resize: none;
  background: #ffffff;
  border-radius: 25px;
  outline: none;
  border: none;
  padding: 20px 20px;
  padding-right: 35px;
  color: #1d65ce;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  max-height: 100px; 
  overflow-y: auto; 
  resize: vertical;
  ::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba(19, 15, 38, 0.5);
  }
}
.hold {
  width: 351px;
  position: relative;
}
.send {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 5px;
}
.loader {
  background-color: rgba(19, 15, 38, 0.05);
  border-radius: 20px;
  width: 78px;
  height: 30px;
  margin-top: 50px;
}
.loader {
  margin-left: 20px;
}
.create {
  position: absolute;
  bottom: -60px;
  left: -15px;
  border: none;
  color: #000;
  // background: linear-gradient(92deg, #1d65ce 0%, #00866e 100%);
  border-radius: 100px;
  border: 1.5px solid rgba(19, 15, 38, 0.1);
  background-color: white;
  font-size: 12px;
  padding: 5px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  .reload {
    padding-right: 5px;
  }
}
.create3 {
  border: none;
  color: #000;
  // background: linear-gradient(92deg, #1d65ce 0%, #00866e 100%);
  border-radius: 100px;
  border: 1.5px solid rgba(19, 15, 38, 0.1);
  background-color: white;
  font-size: 12px;
  padding: 5px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  .reload {
    padding-right: 5px;
  }
}
.create1 {
  position: absolute;
  bottom: -60px;
  left: 105px;
  border: none;
  color: #000;
  // background: linear-gradient(92deg, #1d65ce 0%, #00866e 100%);
  border-radius: 100px;
  border: 1.5px solid rgba(19, 15, 38, 0.1);
  background-color: white;
  font-size: 12px;
  padding: 5px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  .reload {
    padding-right: 5px;
  }
}
.create2 {
  position: absolute;
  bottom: -40px;
  left: 55px;
  border: none;
  color: #ffffff;
  background: linear-gradient(92deg, #1d65ce 0%, #00866e 100%);
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.retry {
  margin-right: 20px;
}
.pre {
  position: relative;
  padding-bottom: 30px;
  //   width: 100%;
  //   white-space: pre-wrap;
  //   overflow-wrap: break-word;
  max-height: 100%;
  //   overflow-y: auto;
  //   flex: 1;
  white-space: pre-line;
}
.fab {
  width: 15px;
  height: 15px;
  font-size: 20px;
}

.input-container {
  width: 300px; /* Adjust the width as per your requirement */
}

.input-container textarea {
  width: 100%;
  min-height: 50px; /* Set a minimum height as needed */
  resize: vertical;
  overflow: auto; /* Enable vertical scrolling if needed */
  white-space: pre-wrap; /* Allow wrapping of long lines */
}
.sliderTagContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 24px;
  // margin-bottom: 32px;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .sliderTag {
    flex: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px 8px 22px;
    height: 36px;
    background-color: rgba(0, 134, 110, 0.1);
    border-radius: 0px 100px 100px 0px;
    margin-right: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #130f26;
    cursor: pointer;

    .sliderTagDot {
      width: 6px;
      height: 6px;
      background-color: #ffffff;
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }
}
.featureHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
}
.modes1 {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-top: 20px;

  .mode {
    width: 110px;
    height: 100%;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin: 5px 5px;
    border-radius: 16px;
    border: 1px solid #1d65ce;

    .be {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      padding-left: 5px;
    }
    img {
      width: 15px;
    }
  }
  .disabled {
    background-color: #dddfe4;
    border: 1px solid #dddfe4;
  }
  .active {
    background: linear-gradient(92deg, #1d65ce 0%, #00866e 100%);
    color: white;
    border-radius: 100px;
    border: 1.5px solid #1d65ce;
  }
}
.modes {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  position: fixed;
  bottom: 95px;
  left: 0;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .mode {
    width: 160px;
    height: 100%;
    padding: 10px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin: 0 5px;
    border-radius: 16px;
    border: 1px solid #1d65ce;

    .be {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      padding-left: 5px;
    }
    img {
      width: 15px;
    }
  }
  .active {
    background: linear-gradient(92deg, #1d65ce 0%, #00866e 100%);
    color: white;
    border-radius: 100px;
    border: 1.5px solid #1d65ce;
  }
  .disabled {
    background-color: #dddfe4;
    border: 1px solid #dddfe4;
  }
}
.fixed {
  position: fixed;
  width: 100vw;
  top: 50px;
  left: 0px;
  background-color: white;
  z-index: 2;
  padding-top: 60px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #d9d8df;
}
.conta {
  color: #130f26;
  font-family: Epilogue;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.12px;
  width: 300px;
}
.featureTitle {
  color: #130f26;
  font-family: Epilogue;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.14px;
}
