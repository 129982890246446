.dataBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 100%;
  // height: 71px;
  border: 1.5px solid rgba(19, 15, 38, 0.1);
  border-radius: 16px;
  margin: 9px 0;

  .dataContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .listImage {
    width: 48px;
    height: 48px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 6px;
    margin-right: 12px;
  }

  .dataText {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    max-width: 235px;

    .dataTextHeading {
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #130f26;
      margin: 0;
      text-transform: capitalize;
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #130f26;
      opacity: 0.6;
      margin: 0;
    }
  }
}

.add {
  background: #e5f3f0;
  border: 1.5px solid #e5f3f0;
}
.header {
  padding: 40px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .title {
    color: #130f26;

    text-align: center;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.con {
  padding: 10px 20px;
}
