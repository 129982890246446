.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 16px;

    .container{
        padding: 16px 0 16px 24px;
        display: flex;
        flex-direction: column;

        .heading{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
            margin-bottom: 8px;
        }
    }

}