.dateTimeContainer {
  display: flex;
  gap: 23px;
  width: 100%;
  display: flex;

  .date {
    display: flex;
    flex-direction: column;
    max-width: 152px;
    width: 100%;

    .dateField {
      display: flex;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      justify-content: space-between;
      align-items: center;
      color: #000000;
      gap: 10.5px;
      border-bottom: 1px solid rgba(19, 15, 38, 0.3);
      padding: 7px 0;
      position: relative;
    }
  }

  .time {
    display: flex;
    flex-direction: column;
    max-width: 152px;
    width: 100%;

    .timeField {
      display: flex;
      gap: 10.5px;
      border-bottom: 1px solid rgba(19, 15, 38, 0.3);
      padding: 7px 0;

      .timeContainer {
        max-width: 120px;
        border: none;
      }
    }
  }

  .headers {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    color: rgba(19, 15, 38, 0.6);
  }

  .text {
    margin: 0;
  }

  .input {
    border: none;
  }
}
.filler {
  position: absolute;
  background-color: black;
  width: 110px;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
