.page {
    background-color: #fff;
    padding-bottom: 104px;
}



.header {
    height: 56px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;


    .arrowback {
        width: 24px;
        height: 24px;
        color: #130F26;
    }

    .editIcon {
        width: 20px !important;
        height: 20px !important;
        color: #130F26;

    }
}


.title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #130F26;
    padding: 24px 24px 0;
    margin: 0;
}


.accountContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 24px 0;
    margin-top: 40px;
    margin-bottom: 20px;

    .profileImage {
        width: 112px;
        height: 112px;
        margin-bottom: 20px;
    }

    .username {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        text-align: center;
        color: #130F26;
    }

    .email {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.6);
    }
}









.logoutButton {
    position: fixed !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF !important;
    background-color: #00866E !important;
    width: 140px;
    height: 48px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
    border-radius: 100px !important;
    z-index: 1000;
}

.logoutIcon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 12px;
}


.logOutDialog {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #898989;
    z-index: 99999;
    cursor: pointer;


    .logOutText {
        color: #fff;
        width: 80%;
        text-align: center;
        margin-bottom: 40px;
        font-size: 1.3em;
    }

    .cancelLogoutButton {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        background-color: #f4f5f8;
        color: #000;
        width: 100%;
        height: 48px;
        z-index: 1;
    }

    .confirmLogoutButton {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        background-color: #00866E;
        color: #fff;
        width: 100%;
        height: 48px;
        z-index: 1;
    }
}



.dialog {
    z-index: 2001 !important;

    .thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 16px;
        background-color: rgba(0, 134, 110, 0.06);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        margin: auto;
    }

    .editIcon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .dialogAction {
        padding: 8px 24px;
    }
}