.pinInputContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .containerStyle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .inputStyle {
            width: 48px;
            height: 48px;
            min-width: 48px;
            max-width: 48px;
            background-color: #FFFFFF;
            color: #3C3C3C;
            border: 1px solid #BAC2D0;
            border-radius: 5px;
        }

        .focusStyle {
            border: 1.5px solid #00866E;
            outline: none;
        }

        .errorStyle {
            border: 1.5px solid #E25353;
            outline: none;
        }
    }
}