.page {
    background-color: #fff;
    padding-bottom: 104px;
}


.header {
    height: 56px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;


    .arrowback {
        width: 24px;
        height: 24px;
        color: #130F26;
    }
}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #130F26;
    padding: 24px 24px 0;
    margin: 0;
}