.header {
    height: 56px;
    padding: 0 24px;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;

}

.sliderCategoryContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 116;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 24px;
    padding-top: 24px;
    margin-bottom: 32px;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    .sliderCategoryContent {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(19, 15, 38, 0.08);
        border-radius: 100px;
        padding: 8px 16px;
        margin-right: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: rgba(19, 15, 38, 0.7); 

        &:focus {
            outline: none;
            background-color: #00866E;
            color: #FFFFFF;
        }
    }

}