.modal {
    position: fixed;
    background: rgba(19, 15, 38, 0.2);
    width: 100%;
    display: flex;
    max-width: inherit;
    z-index: 2005;
    top: 0%;
    bottom: 0;
    max-height: 100vh;
    align-items: flex-end;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    pointer-events: none;

    &.makeMobile {
        max-width: 375px !important;
    }


    .modalContent {
        width: 100%;
        background: #FFFFFF;
        border-radius: 20px 20px 0 0;
        position: relative;
        transform: translateY(100%);
        transition: all 0.5s ease-in-out;
        padding-bottom: 40px;

    }

    .modalHeader {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        margin-top: 4px;
        

        .modalHeaderText {
            color: #000000;
            font-family: 'Epilogue';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            margin: 0;
        }

    }

    .modalDesc {
        display: flex;
        flex-direction: column;
        padding: 32px 46px 40px;

        .imgContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;

            .textCtn {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .text {
                    margin: 0;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 20px;
                    text-align: center;
                    color: #000000;
                }

                .text2 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }

        .checkMark {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 35px
        }
    }

    .modalBody {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
        padding: 0 24px;
        margin-top: 32px;

        .img {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
        }

        .text {
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            color: #130F26;
        }

    }

    .advert {
        padding: 40px 24px 24px 24px;

        .content {
            position: relative;
            display: flex;
            width: 100%;
            height: 112px;
            border-radius: 20px;
            box-shadow: 0px 10px 20px rgba(27, 70, 100, 0.2);
            cursor: pointer;
            text-decoration: none;
            background-image: url(../../../assets/ad-dietitian-top-bg.png), url(../../../assets/ad-dietitian-bottom-bg.png);
            background-size: 61px 63px, 112px 52px;
            background-position: 8.26% -6.25%, 53.72% 100%;
            background-repeat: no-repeat;
            background-color: #FFD772;

            &:visited {
                text-decoration: none;
            }

            &:active {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            .textContent {
                flex: none;
                width: calc(100% - 117px);
                padding: 20px 0 20px 20px;

                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 20px;
                    margin-bottom: 10px;
                    color: #002E25;
                    ;

                }

                .subTitle {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 0;
                    color: #002E25;
                    width: 198px;
                    ;


                }
            }

            .advertImage {
                display: inline-block;
                width: 117px;
                height: 119px;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }

    .addManual {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 40px;

        .text {
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            color: #130F26;
            text-decoration: underline;     
        }
    }
}




.open {
    opacity: 1;
    pointer-events: visible;

    .modalContent {
        transform: translateY(0)
    }
}


// weight and symptoms container
.addWS {
    z-index: 2005;
    top: 0;
    left: 0;
    background: #FFFFFF;
    width: 100%;
    position: fixed;
    // max-width: inherit;
    padding: 24px;
    height: 100%;
    gap: 50px;
    display: flex;
    flex-direction: column;


    .sticky {
        position: sticky;

        .header {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            .headerText {
                display: flex;
                gap: 25.65px;
                align-items: center;

                .text {
                    margin: 0;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    color: #130F26;
                }
            }
        }
    }


    .add {
        width: 154px;
        height: 48px;
        position: fixed;
        left: 50%;
        bottom: 24px;
        transform: translateX(-50%);
        background-color: #00866e;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
        border: none !important;
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        z-index: 1000;

        &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            backdrop-filter: blur(10px);
        }
    }

    .disableScrollbars {
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* IE 10+ */

        &::-webkit-scrollbar {
           background: transparent;
           /* Chrome/Safari/Webkit */
           width: 0px;
        }
     }

    .modalContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        
        // padding-bottom: 100px;

        .symptomsCtn {
            display: flex;
            flex-direction: column;
    
            .allSymptoms {
                margin-top: 48px;
    
                .chip {
                    height: 46px;
                    min-width: 56px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 14px;
                    color: #130F26;
                    background-color: #fff;
                    border-radius: 100px;
                    margin: 0 12px 12px 0;
                    mix-blend-mode: normal;
                    border: 1.5px solid rgba($color: #130F26, $alpha:0.2);
                    text-transform: capitalize;
    
                    &:hover {
                        background-color: transparent;
                    }
    
                    ;
    
                    &:focus {
                        background-color: transparent;
                    }
                }
    
                .chipSelected {
                    height: 46px;
                    min-width: 56px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 14px;
                    color: #ffff;
                    background-color: #130F26;
                    border-radius: 100px;
                    margin: 0 12px 12px 0;
                    text-transform: capitalize;
    
                    &:hover {
                        background-color: #130F26;
                    }
    
                    ;
    
                    &:focus {
                        background-color: #130F26;
                    }
                }
    
                .addChip {
                    height: 46px;
                    min-width: 56px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 14px;
                    color: #00866E;
                    background-color: #fff;
                    border: 1.5px solid rgba(0, 134, 110, 0.2);
                    border-radius: 100px;
                    margin: 0 12px 12px 0;
                    text-transform: capitalize;
                }
            }
    
            .description {
                margin-top: 48px;
    
                .textarea {
                    background: rgba(19, 15, 38, 0.04);
                    border-radius: 16px;
                    width: 100%;
                    padding: 16px;
                    border: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                }
            }
    
            .addCondition {
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-top: 40px;
                justify-content: center;
                align-items: flex-end;
    
    
            }
    
        }

        .doneBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 10px 0;
   
            .btnCtn {
               width: 154px;
               height: 48px;
   
               .btn {
                  background-color: #00866e;
                  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
                  border: none !important;
                  border-radius: 100px !important;
                  z-index: 3000;
                  text-transform: none !important;
                  overflow: hidden !important;
                  width: 100%;
                  height: 100%;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 16px;
                  text-align: center;
                  color: #ffffff;
   
                  &:disabled {
                     pointer-events: auto;
                     opacity: 0.8;
                     background: #a7a6a6;
                   
                  }
               }
            }
         }
    }
}

.hide {
    display: none;
}

.bt {
    position: relative;
    margin-top: 96px;

    .selector {
        position: absolute;
        top: 13px;
        right: 20px;
        display: flex;
        flex-direction: row;
        background-color: rgba(19, 15, 38, 0.1);
        border-radius: 100px;
        width: 100px;
        font-size: 14px;

        .good {
            background-color: #000000;
            padding: 5px;
            color: white;
            cursor: pointer;
            width: 50%;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .bad {
            padding: 5px;
            color: #000000;
            cursor: pointer;
            width: 50%;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}