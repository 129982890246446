.page {
    position: relative;
    background-color: #fff;
    padding-bottom: 76px;
}

.header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;

    .inputContainer {
        flex: 1;
        height: 40px;
        background-color: rgba(19, 15, 38, 0.05);
        border-radius: 100px;
        display: flex;
        align-items: center;
        padding: 12px 18px;
    }

    .searchIcon {
        margin-right: 12px;
    }

    .headerIcon {
        position: relative;
        margin-left: 12px;
    }

    .cartNotify {
        border-radius: 100px;
        background-color: #00866E;
        min-width: 18px;
        height: 14px;
        position: absolute;
        top: -6px;
        right: -3px;
        padding: 3px 4px;
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 9px;
        text-align: center;
        color: #FFFFFF;
    }

}


.advert {
    padding: 0 24px 24px;

    .content {
        display: block;
        position: relative;
        width: 100%;
        height: 112px;
        border-radius: 20px;
        padding: 20px 20px 0;
        cursor: pointer;
        text-decoration: none;

        &:visited {
            text-decoration: none;
        }

        &:active {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        &.promo {
            background-image: url(./../../assets/ad-promo-top-bg.png), url(./../../assets/ad-promo-bottom-bg.png);
            background-size: 61px 63px, 112px 52px;
            background-position: 8.26% -6.25%, 80.72% 100%;
            background-repeat: no-repeat;
            background-color: #00866E;
            color: #fff;

            .title {
                max-width: 255px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 10px;
                z-index: 1;
            }

            .subTitle {
                max-width: 232px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                margin-bottom: 0;
                z-index: 1;
            }

            .image1 {
                display: inline-block;
                position: absolute;
                width: 124px;
                height: 124px;
                left: 63%;
                bottom: -10.41%;
            }

            .image2 {
                display: inline-block;
                position: absolute;
                width: 83.5px;
                height: 72.6px;
                left: 70%;
                bottom: 0;
            }

            .image3 {
                display: inline-block;
                position: absolute;
                width: 58.34px;
                height: 93.93px;
                left: 79%;
                bottom: 0;
            }
        }

        &.tracking {
            background-image: url(./../../assets/ad-tracking-top-bg.png), url(./../../assets/ad-tracking-bottom-bg.png);
            background-size: 61px 63px, 112px 52px;
            background-position: 8.26% -6.25%, 80.72% 100%;
            background-repeat: no-repeat;
            background-color: #1D65CE;
            color: #fff;

            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 10px;
                z-index: 1;
            }

            .subTitle {
                max-width: 150px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                margin-bottom: 0;
                z-index: 1;
            }

            .image1 {
                display: inline-block;
                position: absolute;
                width: 30px;
                height: 30px;
                left: 65%;
                bottom: 10%;
            }

            .image2 {
                display: inline-block;
                position: absolute;
                width: 30px;
                height: 30px;
                left: calc(65% + 35px);
                bottom: 10%;
            }

            .image3 {
                display: inline-block;
                position: absolute;
                width: 30px;
                height: 30px;
                left: calc(65% + 35px + 35px);
                bottom: 10%;
            }
        }

        &.dietitian {
            background-image: url(./../../assets/ad-dietitian-top-bg.png), url(./../../assets/ad-dietitian-bottom-bg.png);
            background-size: 61px 63px, 112px 52px;
            background-position: 8.26% -6.25%, 80.72% 100%;
            background-repeat: no-repeat;
            background-color: #FFD772;
            color: #130F26;

            .title {
                max-width: 246px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 10px;
                z-index: 1;
            }

            .subTitle {
                max-width: 200px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                margin-bottom: 0;
                z-index: 1;
            }

            .image {
                display: inline-block;
                position: absolute;
                width: 100px;
                height: 102px;
                left: 70%;
                bottom: 0%;
            }
        }

        &.community {
            background-image: url(./../../assets/ad-community-top-bg.png), url(./../../assets/ad-community-bottom-bg.png);
            background-size: 61px 63px, 112px 52px;
            background-position: 8.26% -6.25%, 80.72% 100%;
            background-repeat: no-repeat;
            background-color: #C2E2DC;
            color: #130F26;

            .title {
                max-width: 246px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 10px;
                z-index: 1;
            }

            .subTitle {
                max-width: 230px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                margin-bottom: 0;
                z-index: 1;
            }

            .image1 {
                display: inline-block;
                position: absolute;
                width: 41.6px;
                height: 41.6px;
                left: 70%;
                bottom: 50%;
            }

            .image2 {
                display: inline-block;
                position: absolute;
                width: 41.6px;
                height: 41.6px;
                left: calc(70% + 46.6px);
                bottom: 50%;
            }

            .image3 {
                display: inline-block;
                position: absolute;
                width: 41.6px;
                height: 41.6px;
                left: 70%;
                bottom: 50%;
                transform: translateY(calc(100% + 5px));
            }

            .image4 {
                display: inline-block;
                position: absolute;
                width: 41.6px;
                height: 41.6px;
                left: calc(70% + 46.6px);
                bottom: 50%;
                transform: translateY(calc(100% + 5px));
            }
        }


        &.work {
            background-image: url(./../../assets/ad-work-top-bg.png), url(./../../assets/ad-work-bottom-bg.png);
            background-size: 61px 63px, 112px 52px;
            background-position: 8.26% -6.25%, 80.72% 100%;
            background-repeat: no-repeat;
            background-color: #C9DAF3;
            color: #130F26;

            .title {
                max-width: 246px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 10px;
                z-index: 1;
            }

            .subTitle {
                max-width: 200px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                margin-bottom: 0;
                z-index: 1;
            }

            .image {
                display: inline-block;
                position: absolute;
                width: 96.7px;
                height: 96.7px;
                left: 70%;
                bottom: 8%;
            }
        }

        &.grocery {
            background-image: url(./../../assets/groceries/ad-grocery-top-bg.png), url(./../../assets/groceries/ad-grocery-bottom-bg.png);
            background-size: 61px 63px, 112px 52px;
            background-position: 8.26% -6.25%, 80.72% 100%;
            background-repeat: no-repeat;
            background-color: #D5F0D0;
            color: #002E25;

            .title {
                max-width: 175px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 10px;
                z-index: 1;
            }

            .subTitle {
                max-width: 210px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 0;
                z-index: 1;
            }

            .image {
                display: inline-block;
                position: absolute;
                width: 106px;
                height: 120px;
                right: -8px;
                top: -8px;
            }
        }



        &.order {
            background-image: url(./../../assets/groceries/groceries-slide2.png);
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-color: lightgreen;
            color: #130F26;

            .title {
                max-width: 246px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 18px;
                z-index: 1;
            }

            .subTitle {
                max-width: 100px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                margin-bottom: 0;
                z-index: 1;
            }

            .image {
                display: inline-block;
                position: absolute;
                width: 96.7px;
                height: 96.7px;
                left: 70%;
                bottom: 8%;
            }

            .orderButton {
                position: absolute;
                width: 28px;
                height: 28px;
                right: 12px;
                bottom: 12px;
                background-color: #FFFFFF;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }



    }
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    margin-bottom: 15px;

    .titleText {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #130F26;
    }

    .seeAllButton {
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 100px;
    }

    .seeAllText {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
        color: #00866E;
        margin-right: 6px;
    }
}


.sliderCategoryContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 116;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 24px;
    margin-bottom: 12px;


    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    .sliderCategoryContent {
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
    }

    .sliderCategoryContentImage {
        width: 64px;
        height: 64px;
        border-radius: 12px;
        background-size: 150%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba($color: #000000, $alpha: 0.5);
        margin-bottom: 8px;
    }

    .sliderCategoryContentText {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #000000;
        width: 70px;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        margin-bottom: 8px;
        /* number of lines to show */
        height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.sliderTagContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 24px;
    margin-bottom: 32px;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }


    .sliderTag {
        flex: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px 8px 22px;
        height: 36px;
        background-color: rgba(0, 134, 110, 0.1);
        border-radius: 0px 100px 100px 0px;
        margin-right: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #130F26;
        cursor: pointer;

        .sliderTagDot {
            width: 6px;
            height: 6px;
            background-color: #FFFFFF;
            position: absolute;
            left: 6px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }
}