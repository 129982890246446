.container {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-top: 40px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 50px;
  .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #130F26;
  }
  .back {
      width: 24.68px;
      height: 24px;
      position: absolute;
      left: 20px;
      cursor: pointer;
  }
}

.add {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid rgb(243, 241, 241);
    background: rgba(255, 245, 221, 1);
    margin-top: 10px;
    padding: 12px;
    border-radius: 20px;
    width: 100%;
    gap: 5px;

    .icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      .text {
        margin-bottom: 10px;
      }
      img {
        margin: 0;
        padding: 0;
      }
      .name {
        color: #130f26;
        font-weight: 600;
        font-size: 14px;
      }
      .name::first-letter {
        text-transform: uppercase;
      }
      .sub {
        font-size: 11px;
        color: rgba(19, 15, 38, 0.6);
        font-family: Epilogue;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
      }
      .status {
        font-size: 12px;
        color: rgba(19, 15, 38, 0.6);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px; 
        text-transform: capitalize;      
      }
      .index {
        background-color: rgba(0, 134, 110, 0.1);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--Green, #00866e);
        font-family: Epilogue;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 13px;
      }
    }
    .arr {
      background: rgba(19, 15, 38, 0.04);
      border-radius: 50%;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .noTask {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
   
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #130F26;
  }