.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px 24px 16px 0;

    .promotionalTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #130F26;
        margin: 0;
        padding: 24px 0;
    }

    .contentContainer{
       display: flex;
       align-items: center;
       justify-content: space-between;
       gap: 27px;

       .textContent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        .title{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
            margin: 0;
        }

       

        .description{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #130F26;
            opacity: 0.5;
            margin: 0;
        }
    }
    }

    
}