body .confirmButton {
    width: 208px;
    height: 48px;
    position: fixed;
    bottom: 78px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100px !important;
    text-transform: none !important;
    overflow: hidden !important;


    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    background-color: #00866E;
    z-index: 2;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.5);

    &:disabled {
        pointer-events: none;
        opacity: 0.8;
    }
}

.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 106px;




    .header {
        height: 88px;
        background-color: #00866E;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        .icon {
            width: 24px;
            height: 24px;
            color: #fff;
        }

        .headertext {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #FFFFFF;
        }
    }



    .headerImgContainer {
        width: 100%;
        height: 232px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        transform: translateY(-22px);
        border-radius: 20px 20px 0 0;
        background-color: #fff;


        .letsCook {
            width: 176px;
            height: 48px;
            background: #00866E;
            box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
            border-radius: 100px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #FFFFFF;
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-88px);
            z-index: 2;
        }

        .headerBottom {
            height: 32px;
            width: 100%;
            background: #fff;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            position: absolute;
            top: calc(100% - 16px);
            z-index: 1;
        }
    }


    .course {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.04em;
        color: rgba(0, 0, 0, 0.5);
        margin-right: auto;
    }


    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: #130F26;
        padding: 0 24px;
    }


    .chefIcon {
        width: 36px;
        height: 36px;
    }

    .chefName {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
    }

    .levelContain {
        min-width: 0;
        flex: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .icon {
            width: 24px;
            height: 24px;
            margin-bottom: 12px;

        }

        .firstText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 6px;
        }

        .secondText {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
        }
    }

    .line {
        height: 1px;
        background-color: rgba(19, 15, 38, 0.12);
        margin-bottom: 24px;
    }

    .calulatorTextContain {
        min-width: 0;
        flex: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .firstText {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            color: #130F26;
            margin-bottom: 8px;

        }

        .secondText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: rgba(19, 15, 38, 0.6);
        }

    }


    .calculator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 104px;
        height: 40px;
        padding: 6px;
        background: rgba(0, 134, 110, 0.06);
        border-radius: 100px;

        .button {
            cursor: pointer;
            color: #00866E;
        }

        .number {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #130F26;
        }
    }


    .lineHalf {
        height: 1px;
        background-color: rgba(19, 15, 38, 0.12);
        margin-bottom: 16px;
    }

    .ingredientListBoldText {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        margin-bottom: 6px;
    }

    .ingredientListListText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 16px;
    }

    .chipHeaderText {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #130F26;
        margin-bottom: 16px;
    }

    .chip {
        height: 36px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #00866E;
        background-color: #fff;
        border-radius: 100px;
        margin: 0 12px 12px 0;
        mix-blend-mode: normal;
        border: 1.5px solid rgba($color: #00866E, $alpha:0.2);
    }

}