.page {
	position: relative;
	background-color: #fff;
	height: 100vh;
	overflow-y: scroll;
}
.line{
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin: 15px 0;
}

.both {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .name {
        font-family: 'Epilogue';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #130F26;
    }
    .calculator {
        background-color: rgba(0, 134, 110, 0.1);
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 6px;
        margin-right: 12px;
        width: 128px !important;
        height: 48px;
    
        .number {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #130F26;
        }
    }
}

.main {
	width: 100%;
	padding: 20px 0;
	background-color: #fff;

	.pad {
		padding: 0 20px;
	}

	.addInput {
		border: none;
		outline: none;
	}

	::placeholder {
		color: #130F26;
		opacity: 0.3;
	}

	:-ms-input-placeholder {
		color: #130F26;
		opacity: 0.3;
	}

	::-ms-input-placeholder {
		color: #130F26;
		opacity: 0.3;
	}

	.title {
		color: #000000;
		font-size: 20px;
		font-weight: 600;
		line-height: 20px;
		color: #130F26;
	}

	.sub {
		font-size: 14px;
		font-weight: 400px;
		color: rgba(19, 15, 38, 0.6);
		margin-bottom: 20px;
		line-height: 19px;
		margin-top: 15px;
	}

	.tabHead {
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;

		.activeTab {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #00866E;
			width: 50%;
			border-bottom: 2px solid #00866E;
			padding-bottom: 14px;

		}

		.nonActiveTab {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #130F26;
			width: 50%;
			border-bottom: 0.5px solid #d4d2d8;

		}
	}

	.click {
		color: #06b494;
		cursor: pointer;
		text-decoration: underline;
		display: block;
		margin-top: 10px;
	}

	.chip {
		height: 46px;
		font-style: normal;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400px;
		padding: 0 10px;
		color: #130F26;
		background-color: #fff;
		border-radius: 100px;
		margin: 0 12px 12px 0;
		mix-blend-mode: normal;
		border: 1.5px solid rgba($color: #130F26, $alpha:0.2);
		text-transform: capitalize;
		&:hover {
			background-color: transparent;
		}

		;

		&:focus {
			background-color: transparent;
		}
	}

	.chipSelected {
		height: 46px;
		font-style: normal;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400px;
		padding: 0 10px;
		color: #ffff;
		background-color: #130F26;
		border-radius: 100px;
		margin: 0 12px 12px 0;
		text-transform: capitalize;
		&:hover {
			background-color: #130F26;
		}

		;

		&:focus {
			background-color: #130F26;
		}
	}

	.addChip {
		height: 46px;
		font-style: normal;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400px;
		padding: 0 10px;
		color: #00866E;
		background-color: #fff;
		border: 1px solid #00866E;
		border-radius: 100px;
		margin: 0 12px 12px 0;
		text-transform: capitalize;
	}
}

.bottomButton {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: auto;
	margin-top: 30px;


	.prev {
		width: 48px;
		height: 48px;
		background: rgba(0, 134, 110, 0.1);
		border-radius: 1000px;
		color: #00866E;
		border: none;
		outline: none;
		font-size: 20px;
		font-weight: 800;
		position: fixed;
		bottom: 100px;
		left: 20px;
	}

	.next {
		width: 104px;
		height: 48px;
		background: #00866E;
		box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
		border-radius: 100px;
		color: white;
		border: none;
		outline: none;
		position: fixed;
		bottom: 100px;
		right: 20px;
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
	}

	.more {
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		text-align: center;
		color: #00866E;
		cursor: pointer;
		margin-top: 20px;
        position: fixed;
		bottom: 100px;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
	}

	.nextCenter {
		width: 104px;
		height: 48px;
		background: #00866E;
		box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
		border-radius: 100px;
		color: white;
		border: none;
		outline: none;
		position: fixed;
		bottom: 130px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
	}
}

.question {
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: -0.01em;
	margin-top: 30px;
	margin-bottom: 20px;
	color: #130F26;
}

.imgBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px;
	gap: 40px;
	margin-top: 20px;

	.text {
		font-weight: 600;
		font-size: 24px;
		line-height: 140%;
		text-align: center;
		color: #130F26;
		;
		padding-top: 20px;
	}

	.subText {
		font-weight: 400;
		font-size: 14px;
		line-height: 160%;
		text-align: center;
		color: #130F26;
		margin-top: 10px;
		padding-bottom: 150px;
	}

	img {
		width: 260px;
		height: 260px;
	}
}

.bt {
	position: relative;
	margin-top: 40px;

	.selector {
		position: absolute;
		top: 13px;
		right: 20px;
		display: flex;
		flex-direction: row;
		background-color: rgba(19, 15, 38, 0.1);
		border-radius: 100px;
		width: 100px;
		font-size: 14px;

		.good {
			background-color: #000000;
			padding: 5px;
			color: white;
			cursor: pointer;
			width: 50%;
			border-radius: 100px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.bad {
			padding: 5px;
			color: #000000;
			cursor: pointer;
			width: 50%;
			border-radius: 100px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.check {
	display: flex;
	flex-direction: row;
	margin-top: 40px;

	span {
		font-size: 14px;
	}
}

.header {
	height: 90px;
	color: #fff;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	font-size: 16px;

	.space {
		width: 100%;
		margin-top: 15px;
	}

	.down {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		background-color: white;
		height: 200px;
		width: 100%;
	}

	.back {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px;
		width: 100%;
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
	}

	img {
		width: 15px;
		height: 15px;
		cursor: pointer;
	}

}

.others {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	padding-top: 56px;

	.text {
		font-weight: 600;
		font-size: 24px;
		line-height: 140%;
		text-align: center;
		color: #130F26;
		;
		padding-top: 20px;
	}

	.subText {
		font-weight: 400;
		font-size: 14px;
		line-height: 160%;
		text-align: center;
		color: #130F26;
		padding-bottom: 150px;
	}

	img {
		width: 260px;
		height: 260px;
	}

	.otherConditionsContainer{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 16px;
		position: relative;
	
		.chip{
			display: inline-flex;
			padding: 9px 9px 9px 20px;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			gap: 8px;
			border-radius: 100px;
			border: 1.5px solid rgba(19, 15, 38, 0.20);
		}
	
	
	}

}