.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 96px;

    .confirmButton {
        width: 237px;
        height: 48px;
        position: fixed;
        left: 50%;
        bottom: 24px;
        transform: translateX(-50%);
        background-color: #00866E;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
        border: none !important;
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        z-index: 1000;

        &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            backdrop-filter: blur(10px);
        }
    }
}




.content {

    padding: 48px 24px 0;

    .uploadImage {
        display: block;
        width: 90%;
        max-width: 210.09px;
        height: auto;
        margin: 0 auto 24px;

    }

    .congratulations {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: #130F26;
        margin-bottom: 8px;
    }

    .congratulationsLightText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #130F26;
        margin-bottom: 40px;
    }


}