.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-top: 40px;

    .accordionContainer{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 24px;

        .accordion {
            padding: 16px 0px; 
            box-shadow: none;
        }

        .rounded {
            // border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        }

        .expanded{
            margin: 0;
        }

        .accordionSummary {
            padding: 0px 0px;
            margin: 0px 0px;
        }

        .titleContainer{
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .heading {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #130F26;           
        }
        .subHeadingCtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
        }

        .subHeading {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #00866E;            
        }

        .arrow {
            color: #00866E;
            font-size: 8px;
        }

        .divider{
            height: 0.1px;
            background-color: rgba(0, 0, 0, 0.1);       
         }

        
    }

    .accordionDetails {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 12px;
        margin-top: 12px;
     }
}