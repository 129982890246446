.page {
  position: relative;
  background-color: #fff;
  padding-bottom: 76px;
  margin-top: 24px;
}

.spacing {
  min-height: 182px;
}

.header {
  height: 68px;
  // padding: 0 24px 12px;
  // background-color: #fff;
  // position: -webkit-sticky;
  // position: sticky;
  // top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);
  margin-bottom: 24px;

  .headertext {
    // font-style: normal;
    // font-weight: 500;
    // font-size: 18px;
    // line-height: 18px;
    // color: #000000;
    // margin-right: auto;

    color: rgba(19, 15, 38, 0.6);
    font-family: Epilogue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .headerIcon {
    margin-right: 24px;
  }
}

.dietitianContainer {
  padding: 0 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .dietitianImg {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    border-radius: 50%;
  }

  .dietitianTextContentContainer {
    flex: 1;
  }

  .dietitianNameContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .dietitianName {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    color: #130f26;
    text-transform: capitalize;

    svg {
      margin-right: 6px;
    }
  }

  .dietitianOnline {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;

    color: rgba(19, 15, 38, 0.6);
  }

  .dietitianDesc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgba(19, 15, 38, 0.6);
  }

  .appointment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 6px;
    gap: 4px;

    .subTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: rgba(19, 15, 38, 0.6);
    }
  }
}

.chatContainer {
  padding: 0 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .sessionButton {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    width: 138px;
    height: 32px;
    background-color: #00866e !important;
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff !important;
    margin-right: 24px;
    text-transform: none;
  }

  .chatButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 16px;
    width: 66px;
    height: 32px;
    background-color: #fff !important;
    border: 1.5px solid rgba(0, 134, 110, 0.2);
    border-radius: 1000px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #00866e !important;
    text-transform: none;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin: 16px 0;

  .titleText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #130f26;
  }

  .seeAll {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    color: #00866e;
    cursor: pointer;
  }
}

.slider {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.card {
  flex: none;
  width: 204px !important;
  height: 270px !important;
  mix-blend-mode: normal !important;
  border-radius: 20px !important;
  // box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08) !important;
  outline: none !important;
  margin-bottom: 40px;
  margin-left: 16px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .img {
    height: 104px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0, 134, 110, 0.06);
  }

  .recipeTitleContainer {
    padding: 0 16px;
    display: flex;
    align-items: center;
    position: relative;
  }

  .chefImg {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border: 2px solid #fff;
    overflow: hidden !important;
    transform: translateY(-25%);

    img {
      border-radius: 100%;
      overflow: hidden;
    }
  }

  .chefName {
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }

  .mealAndDiffText {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #130f26;
    opacity: 0.7;
    padding: 0 16px;
    margin-top: 6px;
  }

  .title {
    text-transform: capitalize;
    padding: 0 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 12px;
    /* number of lines to show */
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .footer {
    padding: 0 16px;
    height: 39px;
    display: flex;
    align-items: center;

    .planButton {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      width: 62px;
      height: 32px;
      background-color: #00866e;
      border-radius: 100px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: #ffffff;
      text-transform: none;
      margin-right: auto;
    }

    .img {
      width: 14px;
      height: 14px;
      margin-right: 7px;
    }

    .footerText {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: #130f26;
    }
  }
}

.recommendedHeader {
  padding: 0 24px;

  .recommendedText {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    padding: 6px 0;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(19, 15, 38, 0.12);
  }
}

.groceryTiles {
  flex: none;
  width: 157.5px;
  margin-top: 12px;
  margin-bottom: 24px;
  margin-left: 16px;
}

.additionalResourceContainer {
  background: rgba(19, 15, 38, 0.03);
  padding: 32px 24px 38px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  margin: 24px;

  .peel {
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background-image: url(./../../../assets/paperpeel.png);
    background-repeat: no-repeat;
  }

  .titleText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

  .articleContainer {
    display: flex;
    align-items: flex-start;
    // flex-direction: column;
    gap: 16px;

    .articleImage {
      flex: none;
      width: 72px;
      height: 72px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: rgba(0, 134, 110, 0.06);
      border-radius: 20px;
      // margin-right: 16px;
      border-radius: 12px;
    }

    .resourceText {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      // height: 72px;
    }

    .articleTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #00866e;
    }

    .articleText {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-decoration-line: underline;
      color: #130f26;
      opacity: 0.7;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.nextSteps {
  padding: 0 24px;
  margin-bottom: 24px;
}

.listHeaderContainer {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  // margin-top: 37px;
  margin-bottom: 8px;
  color: #130f26;

  svg {
    margin-right: 10px;
  }
}

.listText {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #130f26;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding-left: 24px;

  .listTextValue {
    flex: 1;
  }
}
.all {
  width: 100vw;
  padding: 0 20px;
}
.plan {
  border-radius: 16px;
  background: rgba(19, 15, 38, 0.04);
  position: relative;
  width: 100%;
  margin-top: 50px;
  .im {
    position: absolute;
    bottom: 0;
    right: 30px;
  }
  .text {
    padding: 30px 20px;
    .head {
      color: #130f26;
      font-family: Epilogue;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 10px;
    }
    .list {
      display: flex;
      flex-direction: row;
      padding-top: 5px;
      .arrow,
      .splan {
        color: rgba(19, 15, 38, 0.6);
        font-family: Epilogue;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 15.4px */
      }
      .arrow {
        padding-right: 10px;
      }
    }
  }
}
