.homePage {
  position: relative;
  padding-bottom: 96px;

  .confirmButton {
    width: 237px;
    height: 48px;
    position: fixed;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    background-color: #00866e;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
    border: none !important;
    border-radius: 100px !important;
    text-transform: none !important;
    overflow: hidden !important;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    z-index: 1000;

    &:disabled {
      pointer-events: auto;
      opacity: 0.8;
      backdrop-filter: blur(10px);
    }
  }
}

.header {
  height: 56px;
  padding: 0 24px;
  background-color: #fff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2000;

  .backIcon {
    width: 24px !important;
    height: 24px !important;
    color: #130f26;
  }

  .headerText {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #130f26;
    margin-left: 25px;
    margin-right: auto;
  }

  .stepText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    color: #130f26;
  }

  .stepTextRed {
    color: #00866e;
  }
}

.shippingDetailsContainer {
  background-color: rgba(0, 134, 110, 0.06);
  border-radius: 20px;
  padding: 22px 20px 8px;

  .shippingDetails {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
    color: #00866e;
  }

  .nameText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #130f26;
    margin-bottom: 8px;
  }

  .addressText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #130f26;
  }

  .line {
    height: 1px;
    background-color: rgba(0, 134, 110, 0.06);
    margin: 20px -20px;
  }

  .whentext {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #130f26;
    margin-bottom: 20px;
  }
}

.paymentContainer {
  min-height: 100px;
  background-color: rgba(0, 134, 110, 0.06);
  border-radius: 20px;
  padding: 22px 20px 8px;

  .paymentText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
    color: #00866e;
  }
}

.errorText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #00866e;
  margin: 10px 0;
  text-align: center;
}

.subtotalContainer {
  border-bottom: 0.5px solid rgba($color: #000000, $alpha: 0.1);
  height: 52px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .totalText {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #130f26;
  }

  .totalPriceText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #130f26;
  }
}

.shippingContainer {
  height: 52px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .totalText {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #130f26;
  }

  .totalPriceText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #130f26;
  }
}

.totalContainer {
  border-top: 0.5px solid #000000;
  border-bottom: 0.5px solid #000000;
  height: 52px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .totalText {
    font-style: normal;
    font-weight: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #130f26;
  }

  .totalPriceText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #130f26;
  }
}

.points {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: rgba(19, 15, 38, 0.04);
  border-radius: 16px;
  margin-top: 8px;

  .pointsIcon {
    margin: 0;
    gap: 12px;
    display: flex;
    align-items: flex-end;

    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #130F26;
    }
  }
}

.saveContainer {
  min-height: 118px;
  background-color: rgba(19, 15, 38, 0.04);
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #130f26;

  .saveText {
    flex-grow: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: rgba(19, 15, 38, 0.6);
    padding-right: 24px;
  }

  .thumb {
    flex: none;
    width: 70.9px;
    height: 60px;
  }
}

.promotion {
  display: flex;
  width: 100%;

  .input {
    width: 100%;
    padding: 6px 6px 6px 20px;
    border: 1.5px solid rgba(19, 15, 38, 0.2);
    border-radius: 2rem;
    transition: all 0.25s linear;
    outline: none;
  }

  .button {
    width: 20%;
    padding: 0.5rem 0;
    background-color: #00866e;
    color: #ffffff;
    border-radius: 0 2rem 2rem 0;
    transition: all 0.25s linear;
    cursor: pointer;

    .icon {
      position: relative;
      right: 0;
      transition: all 0.15s linear;

      &:focus {
        outline: none;
      }

      &:hover {
        right: -5px;
      }
    }

  }
}