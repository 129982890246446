.page {
    position: relative;
    background-color: #fff;
    padding-bottom: 24px;
}

.header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;

    .back {
        margin-right: 16px;
    }

    .avater {
        width: 40px;
        height: 40px;
        margin-right: 12px;
    }

    .headerTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #130F26;
        margin-bottom: 6px;
    }

    .headerSubText {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        color: rgba(0, 0, 0, 0.6)
    }
}

.content {
    display: flex;
    flex-direction: column;
    padding: 22px 24px 81px;
    gap: 12px;
}

.showFeatures {
    display: flex;
    flex-direction: column;
    background: rgba(19, 15, 38, 0.04);
    border-radius: 20px;

    .featureHeading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        gap: 8px;
        gap: 12px;

        .featureTitle {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #130F26;
        }
    }

    .featureDescription {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        gap: 8px;
        border-top: 0.5px solid rgba(0, 0, 0, 0.1);

        .descriptionText{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

.calories{
    display: flex;
    flex-direction: column;
    background: rgba(19, 15, 38, 0.04);
    border-radius: 20px;
    padding: 16px;
    gap: 8px;

    .caloriesHeading {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .caloriesTitle {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #130F26;
        }
    }
        .caloriesSubText{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
        }
}

.macroNutrients {
    display: flex;
    flex-direction: column;
    background: rgba(19, 15, 38, 0.04);
    border-radius: 20px;
    padding: 16px;
    gap: 16px;

    .macroNutrientsHeading {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .macroNutrientsTitle {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #130F26;
        }
    }
        .macroNutrientsSubText{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.6);
        }

        .macroNutrientsSlider{
            display: flex;
            flex-direction: column;
            gap: 8px;

            .macroNutrientsSliderCtn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px; 
                padding: 20px 0;
    border-top: 0.5px solid rgba(0, 0, 0, 0.1);             

                .macroNutrientsSliderTitle{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: #130F26;
                    width: 30%;
                }

                .sliderCtn{
                    width: 100%;
            

                
                }
            }

        }
}


.caloriesSlider{
    display: flex;
    flex-direction: column;
    gap: 8px;

    .caloriesSliderTextCtn {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;

        .caloriesSliderText{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.6);
        }
    }

}

.showFeaturesOff {
    display: flex;
    flex-direction: column;
    background: rgba(19, 15, 38, 0.04);
    opacity: 0.3;
    border-radius: 20px;
    padding: 16px;
}

