.multiselect {
	display: flex;
	flex-direction: column;
	width: 100%;

	button {
		border: none;
		outline: none;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-radius: 10px;
		margin: 7px auto;
		width: 100%;
		height: 56px;
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		letter-spacing: -0.01em;
		padding: 0 16px;
	}
	.active {
		background-color: #130F26;
		color: white;
	}
	.nonActive {
		background-color: #FFFFFF;
		color: #130F26;
		border: 1.5px solid rgba(19, 15, 38, 0.2);
	}
	img {
		width: 24px;
		height: 24px;
	}
	.other {
		color: #00866E;
		font-size: 13px;
		padding-top: 10px;
		cursor: pointer;
	}
}