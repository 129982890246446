.header {
    height: 56px;
    padding: 0 27px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    margin: 24px 0;
    background-color: #fff;

    .headerText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #130F26;
        margin-left: auto;
        margin-right: auto;
    }
}