.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 50px;
    min-height: 100vh;

    .previousButtonBack {
        position: fixed;
        width: 48px;
        height: 48px;
        left: calc(50% - 48px);
        transform: translateX(-115.5px);
        bottom: 24px;
        background-color: #fff;
        border-radius: 100px;
    }

    .previousButton {
        position: fixed;
        width: 48px;
        height: 48px;
        left: calc(50% - 48px);
        transform: translateX(-115.5px);
        bottom: 24px;
        background-color: rgba(0, 134, 110, 0.06);
        border-radius: 100px;
        z-index: 2;
    }

    .nextButton {
        position: fixed;
        width: 104px;
        height: 48px;
        right: calc(50% - 104px);
        transform: translateX(59.5px);
        bottom: 24px;
        background-color: #00866E;
        border-radius: 100px;
        z-index: 2;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
    }


    .header {
        height: 56px;
        padding: 0 24px;
        background-color: #fff;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;

        .closeIcon {
            width: 24px;
            height: 24px;
            color: #130F26;
        }

        .arrowback {
            width: 24px;
            height: 24px;
            color: #130F26;
        }

        .listIcon {
            width: 24px;
            height: 24px;
            color: #130F26;
        }

        .listHeaderText {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;

        }

    }



}

.line {
    height: 0.5px;
    background-color: rgba(19, 15, 38, 0.12);
    margin-left: 68px;
}

.listItem {
    padding-left: 24px !important;

    .avatar {
        width: 44px;
        min-width: 44px;
    }

    .avatarIcon {
        width: 24px;
        height: 24px;
    }

    .primarytext {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;
    }

    .secondaryText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
        font-feature-settings: 'salt'on;
        color: #130F26;
    }
}

.stepContainer {
    position: relative;

    .image {
        height: 294px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
    }

    .stepHeaderTop {
        height: 32px;
        background-color: #fff;
        border-radius: 20px;
        transform: translateY(-16px);
        z-index: 1;
    }

    .contentContainer {
        padding: 0 24px 96px;
    }

    .headerTextContainer {
        display: flex;
        align-items: flex-start;
    }

    .headerTextIcon {
        flex: none;
        width: 24px;
        height: 24px;
        color: #130F26;
        margin-right: 8px;
    }

    .headerText {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        letter-spacing: -0.01em;
        color: #130F26;
    }

    .slideLine {
        height: 0.5px;
        background-color: rgba(19, 15, 38, 0.12);
        margin: 24px 0;
    }

    .prepareText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;
    }

    .prepareTextContent {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #130F26;
    }

    .description {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 160%;
        color: rgba(19, 15, 38, 0.6);
    }
}


.dots {
    list-style: none;
    display: flex;
    position: absolute;
    left: 50%;
    top: 248px;
    transform: translateX(-50%);
    text-align: right;
    margin: 0;
    padding: 0;
}

.dots li {
    position: relative;
    display: inline-block;
    width: auto;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    transition: width 0.3s ease-in-out;
}

.dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: transparent;
    background-color: #fff;
    border: 0;
    outline: none;
    overflow: hidden;
    border-radius: 100%;
    border: 0.5px solid rgba(19, 15, 38, 0.15);
}

.dots li button:hover,
.dots li button:focus {
    outline: none;
}

.dots li button:hover:before,
.dots li button:focus:before {
    opacity: 1;
}

.dots li button:before {
    display: none !important;
}

.dots li.activeDot button:before {
    display: none !important;
}

.dots li.activeDot button {
    width: 24px !important;
    border-radius: 100px;
}

.lineHalf {
    height: 1px;
    background-color: rgba(19, 15, 38, 0.12);
    margin-bottom: 16px;
}

.ingredientListBoldText {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #130F26;
    margin-bottom: 6px;
}

.ingredientListListText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 16px;
}

.headerBottom {
    background: #fff;
    border-radius: 100px;
    transform: translateY(-16px);
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    bottom: 10px;

    .iconButton {
        padding: 20px;
        

        .icon{
            width: 24px;
            height: 24px;
            // color: #130F26;
        }
    }

    .label {
        margin: auto;
        cursor: pointer;
    }
}