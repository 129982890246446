.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 96px;

    .addStepButton {
        display: flex;
        width: 100%;
        max-width: 327px;
        height: 56px;
        mix-blend-mode: normal;
        border: 1.5px solid rgba($color: #00866E, $alpha: 0.2);
        border-radius: 100px;
        text-transform: none;
        overflow: hidden;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #00866E;
        margin: 0 auto;
    }

    .nextButton {
        width: 104px;
        height: 48px;
        position: fixed;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        background-color: #00866E;
        z-index: 2;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);

        &:disabled {
            pointer-events: none;
            opacity: 0.8;
        }
    }
}



.header {
    height: 88px;
    background-color: #00866E;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;

    .icon {
        width: 24px;
        height: 24px;
        color: #fff;
    }

    .stepContainer {
        flex: none;
        min-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .stepIndicator {
            width: 24px;
            height: 4px;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 100px;
            cursor: pointer;
        }

        .stepIndicatorActive {
            width: 24px;
            height: 4px;
            background-color: #fff;
            border-radius: 100px;
        }
    }
}




.headerBottom {
    height: 44px;
    background: #fff;
    border-radius: 20px 20px 0 0;
    transform: translateY(-22px);
    position: -webkit-sticky;
    position: sticky;
    top: 88px;
    z-index: 1001;
}

.content {

    padding: 0 24px;


    .headerText {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: #130F26;
    }

    .lightText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: rgba(19, 15, 38, 0.6);
    }

    .expandItemContainer {
        position: relative;
        border-radius: 16px;
        margin-bottom: 12px;

        .closeButton {
            position: absolute;
            top: -10px;
            left: -10px;
            z-index: 1;
        }
    }

    .thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border: 1.5px solid rgba($color: #00866E, $alpha: 0.2);
        border-radius: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        margin: auto;
    }


    .selectedInputContainer {
        position: relative;

        .closeButton {
            position: absolute;
            top: -10px;
            left: -10px;
            z-index: 1;
        }
    }


    .expandItem {
        background-color: #fff;
        mix-blend-mode: normal;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
        border-radius: 20px !important;
        overflow: hidden;
        margin: 0 !important;

        .expandItemArrow {
            color: #130F26;
        }

        .labelIcon {
            flex: none;
            width: 24px;
            height: 24px;
            margin-right: 12px;
        }

        .itemHeaderText {
            flex-grow: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
            margin-right: 12px;
        }

        .checkedIcon {
            flex: none;
            width: 20px;
            height: 20px;
            color: #fff;
            background-color: #00866E;
            border-radius: 50%;
            padding: 3px;
            margin-right: 12px;
        }

        .titleText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: rgba(19, 15, 38, 0.6);
            margin-bottom: 12px;
        }


        .selectInput {
            width: 100%;
            overflow: hidden;
            border: 1.5px solid rgba($color: #00866E, $alpha: 0.1);
            border-radius: 16px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
            margin-bottom: 12px;
            text-transform: capitalize;

            .select {
                padding-left: 16px;
                padding-top: 9px;

                &:focus {
                    background-color: transparent;
                }
            }

            .dropDownIcon {
                width: 12px;
                height: 12px;
                top: calc(50% - 6px);
                right: 8px;
                transform: rotateZ(-90deg);
                color: #00866E;
            }

            .placeHolder {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 19px;
                color: rgba(19, 15, 38, 0.5);
            }
        }


        .selectedInput {
            width: 100%;
            overflow: hidden;
            background-color: rgba(0, 134, 110, 0.1);
            border-radius: 16px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
            margin-bottom: 12px;
            text-transform: capitalize;

            .select {
                padding-left: 16px;
                padding-top: 9px;

                &:focus {
                    background-color: transparent;
                }
            }

            .dropDownIcon {
                width: 12px;
                height: 12px;
                top: calc(50% - 6px);
                right: 8px;
                transform: rotateZ(-90deg);
                color: #00866E;
            }
        }
    }

    .descriptionInput {
        background-color: rgba(0, 134, 110, 0.1);
        border-radius: 16px;
        padding: 16px;
        margin-bottom: 12px;
    }


    .pointer {
        cursor: pointer;
    }

    .line {
        width: 100%;
        border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);
        // margin: 0 -16px;
    }

}