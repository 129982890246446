.container {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   margin-bottom: 30px;

   .sin {
      width: 79px;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: rgba(19, 15, 38, 0.6);
   }

   sin b {
      color: #000000;
   }
   .fles {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
   }
   ::-webkit-progress-bar {
      background-color: rgba(19, 15, 38, 0.2);
   }

   ::-webkit-progress-value {
      background-color: #130f26;
   }

   ::-moz-progress-bar {
      background-color: #130f26;
   }

   progress {
      -webkit-appearance: none;
      border: 0;
      height: 7px;
      border-radius: 20px;
      margin: 10px 0;
      width: 50px;
   }
   progress::-webkit-progress-bar {
      border: 0;
      height: 7px;
      border-radius: 20px;
   }
   progress::-webkit-progress-value {
      border: 0;
      height: 7px;
      border-radius: 20px;
   }
   progress::-moz-progress-bar {
      border: 0;
      height: 7px;
      border-radius: 20px;
   }

   // .bod {
   //    border-right: 1px solid rgba(19, 15, 38, 0.2);
   // }
}
