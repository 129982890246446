.page {
  width: 100%;
  margin: auto;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.vi {
  position: absolute;
  background-color: white;
  padding: 5px;
  width: 130px;
  z-index: 1;
}

.date {
  width: 160px;
  position: relative;
  
  .cal {
    width: 20px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
}
.dats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.reload {
  cursor: pointer;
}
.btn {
  background-color: #00866e;
  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
  border: none !important;
  border-radius: 100px !important;
  z-index: 30;
  text-transform: none !important;
  overflow: hidden !important;
  height: 100%;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 5px;

  &:disabled {
    pointer-events: auto;
    opacity: 0.8;
    backdrop-filter: blur(10px);
  }
}

// loader
.con {
  position: fixed;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 75px;
  height: 34px;
  background: rgb(245, 245, 245);
  border-radius: 20px;
}

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsEllipsis div {
  position: absolute;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1d65ce;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: ldsEllipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: ldsEllipsis3 0.6s infinite;
}
@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.list {
  padding-top: 20px;
  width: 100%;
  .head {
    color: #130f26;
    font-family: Epilogue;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid rgb(210, 208, 208);
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .body {
    padding-left: 10px;
    position: relative;
    width: 100%;
    .sing {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .both {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 90%;
      }
    }
    .strike {
      height: 1px;
      width: 85%;
      background-color: #130f26;
      position: absolute;
      top: 20px;
    }
  }
  .title {
    color: #130f26;
    font-family: Epilogue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .unit {
    color: rgba(19, 15, 38, 0.6);
    text-align: right;
    font-family: Epilogue;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
}
.message {
  position: fixed;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 100%;
  text-align: center;
  padding: 0 10px;
  height: 34px;
  border-radius: 20px;
  color: #00866e;
  font-size: 15px;
}

.calulatorTextContain {
  min-width: 0;
  flex: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .firstText {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #130F26;
      margin-bottom: 8px;

  }

  .secondText {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.01em;
      color: rgba(19, 15, 38, 0.6);
  }

}


.calculator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 104px;
  height: 40px;
  padding: 6px;
  background-color: rgba(0, 134, 110, 0.06);
  border-radius: 100px;

  .button {
      cursor: pointer;
      color: #00866E;
  }

  .number {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #130F26;
  }
}
.col {
  width: 150px;
}
.typ {
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
}