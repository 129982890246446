.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;
    background: rgba(19, 15, 38, 0.06);
    border-radius: 16px;
    width: 100%;

    .cardHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 8px;

        .cardTitle{
          display: flex;
          gap: 6px;
          align-items: center;

          .cardNo {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.7);
            margin: 0;
            
          }

          .cardText {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #000000;            
          }
        }

        .cardEditText {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px; 
            color: #00866E;
        }
    }

    .cardBody {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .cardTags {
            padding: 5px 10px;
            background: #FFFFFF;
            border-radius: 4px;

        }

        .cardTagText {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #130F26;
        }
    }
}

.cuisineContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;

    .p {
      margin: 0;
    }
}

.noData {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    color: #00866E;;
    margin: 0;
}