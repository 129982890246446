.homePage {
    position: relative;
    padding-bottom: 96px;

    .confirmButton {
        width: 237px;
        height: 48px;
        position: fixed;
        left: 50%;
        bottom: 24px;
        transform: translateX(-50%);
        background-color: #00866E;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
        border: none !important;
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        z-index: 1000;

        &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            backdrop-filter: blur(10px);
        }
    }
}

.img {
    position: absolute;
    top: 12px;
    left: 24px;
    height: 32px;
    width: auto;
    z-index: 3;
}


.header {
    height: 268px;
    padding: 0;
    background-color: #fff;
    position: relative;

    .headerImage {
        width: auto;
        max-width: 100%;
        height: 196px;
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        z-index: 2;
    }
}


.content {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: relative;
    padding: 32px 24px 0;
    z-index: 1;
    transform: translateY(-16px);
    border-radius: 20px 20px 0 0;

    .leftPan {
        flex-grow: 1;
        margin-right: auto;

        .desc {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            padding-bottom: 6px;
            text-transform: capitalize;
        }

        .name {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 25px;
            color: #130F26;
            padding-bottom: 16px;
            text-transform: capitalize;
        }

        .size {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: rgba(19, 15, 38, 0.7);
        }

    }

    .rightPan {
        flex: 0;

        .rightPanText {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 16px;
            text-transform: capitalize;
            color: #00866E;
        }
    }


    .line {
        border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);
        margin-bottom: 24px;
    }

    .headerText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #130F26;
        margin-bottom: 12px;
    }

    .aboutProductText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: rgba(19, 15, 38, 0.6);
        padding-bottom: 28px;
    }
}




.shippingDetailsContainer {
    background-color: rgba(0, 134, 110, 0.06);
    border-radius: 20px;
    padding: 22px 20px 8px;

    .shippingDetails {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 16px;
        color: #00866E;
    }
}

.paymentContainer {
    min-height: 100px;
    background-color: rgba(0, 134, 110, 0.06);
    border-radius: 20px;
    padding: 22px 20px 8px;

    .paymentText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 16px;
        color: #00866E;
    }
}


.errorText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #00866E;
    margin: 10px 0;
    text-align: center;
}


.subtotalContainer {
    border-bottom: 0.5px solid rgba($color: #000000, $alpha: 0.1);
    height: 52px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .totalText {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }

    .totalPriceText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }
}

.shippingContainer {
    height: 52px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .totalText {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }

    .totalPriceText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }
}

.totalContainer {
    border-top: 0.5px solid #000000;
    border-bottom: 0.5px solid #000000;
    height: 52px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .totalText {
        font-style: normal;
        font-weight: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }

    .totalPriceText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }
}