.page {
    position: relative;
    background-color: #fff;
    padding-bottom: 76px;
}

.header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headertext {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #000000;
        display: flex;
    }

    .tagCount {
        min-width: 32px;
        height: 20px;
        padding: 5px 5px 3px 5px;
        border-radius: 100px;
        background-color: #00866E;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.24px;
        margin-left: 10px;
    }

    .headerIcon {
        margin-right: 24px;
    }


}


.inputContainer {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 12px 24px;

    .searchInput {
        flex: 1;
        background-color: rgba(19, 15, 38, 0.05);
        border-radius: 100px;
        padding: 0 12px;
    }
}

.list {
    padding: 24px;

    .listItem {
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;
        cursor: pointer;

        .listAvater {
            width: 48px;
            height: 48px;
            margin-right: 16px;
        }

        .listTextContainer {
            padding: 12px 0;
        }

        .patientsText {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
        }
    }
}