.page {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: rgba(19, 15, 38, 0.2);
    padding-bottom: 204px;

    .deleteButton {
        width: 144px;
        height: 48px;
        background-color: #fff;
        border: 1.5px solid rgba(0, 0, 0, 0.2);
        border-radius: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #130F26;
        position: fixed;
        bottom: 24px;
        left: 50%;
        transform: translateX(-152px);
        text-transform: none;

        &:hover {
            background-color: #fff;
        }
    }

    .confirmButton {
        width: 144px;
        height: 48px;
        background-color: #00866E;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
        border-radius: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        position: fixed;
        bottom: 24px;
        right: 50%;
        transform: translateX(152px);
        text-transform: none;

        &:hover {
            background-color: #00866E;
        }
    }
}

.header {
    height: 68px;
    padding: 0 24px 12px;
    display: flex;
    align-items: center;

    .headerTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #130F26;
        margin-left: auto;
        margin-right: auto;
        transform: translateX(-20px);
    }


}

.modalContent {
    height: calc(100vh - 68px);
    overflow-y: auto;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    padding: 40px 0;
    border-radius: 24px 24px 0 0;
    padding: 32px 24px 114px;

    .modalHeader {
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
    }

    .dateContainer {
        margin-bottom: 32px;
    }

    .recipeTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        color: #130F26;
        text-transform: capitalize;
        margin-bottom: 16px;
    }

    .img {
        height: 232px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(0, 134, 110, 0.06);
        margin-bottom: 16px;
        border-radius: 20px;
    }

    .ingredient {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: rgba(19, 15, 38, 0.7);
        margin-bottom: 20px;
    }

    .energyContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .energyItemTitle {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba(19, 15, 38, 0.6);
            margin-bottom: 6px;
        }

        .energyItemBar {
            width: 68px;
            height: 6px;
            background-color: rgba(19, 15, 38, 0.2);
            border-radius: 100px;
            margin-bottom: 4px;
            position: relative;

            &::before {
                content: "";
                width: 41%;
                height: 100%;
                position: absolute;
                left: 0;
                background-color: #130F26;
                border-radius: 100px;
            }
        }

        .energyItemValue {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #000000;
        }
    }

    .seeAll {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
        color: #00866E;
        display: flex;
        align-items: center;
        margin-top: 36px;

        span {
            margin-right: 6px;
        }
    }
}