.page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 40px 24px 80px;

    .header {
        display: flex;
        align-items: center;
        margin-top: 16px;

        .authorImgCtn{
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;

            .img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        .authorNameSpecialization{
            padding: 8px 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 8px;

            .authorName{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #130F26;
            }

            .specialization{
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #130F26;
                opacity: 0.7
            }
        }
    }

    .title{
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #130F26;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    .subTitle {
        font-family: Arial, sans-serif;
        font-weight: bold; 
        font-size: 20px;
        line-height: 1.5;      
    }

    .coverImage{
        width: 100%;
        height: 216px;
        object-fit: cover;
        margin-bottom: 16px;
        border-radius: 12px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 24px;
    }

    .paragraph {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        overflow-wrap: break-word;
        color: rgb(0, 0, 0);
        font-family: Arial, sans-serif;
    }

    .button {
        position: fixed !important;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        background-color: #00866E !important;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2) !important;
        width: 200px;
        height: 48px;
        border-radius: 100px;
        border: none;
        outline: none;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        

        .btnTxt{
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}