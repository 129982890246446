.page {
    background-color: #fff;
    position: relative;
    padding-top: 96px;
    padding-bottom: 96px;
}


.img {
    position: absolute;
    top: 12px;
    left: 24px;
    height: 32px;
    width: auto;
}


.content {

    padding: 48px 24px 0;

    .uploadImage {
        display: block;
        width: 90%;
        max-width: 210.09px;
        height: auto;
        margin: 0 auto 24px;

    }

    .congratulations {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: #130F26;
        margin-bottom: 8px;
    }

    .congratulationsLightText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #130F26;
        margin-bottom: 40px;
    }

    .reciptText {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #00866E;
        margin-bottom: 40px;
    }

}