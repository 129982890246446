.articleContainer {
    padding: 0 12px;
    margin-bottom: 32px;

    .articleContent {
        display: flex;
        margin-bottom: 12px;
        text-align: left;
        border-radius: 12px;
    }

    .articleImage {
        flex: none;
        width: 96px;
        height: 96px;
        border-radius: 12px;
        margin-right: 16px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .articleAuthorContainer {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    .articleAuthorImage {
        flex: none;
        width: 20px;
        height: 20px;
        border: 2px solid #FFFFFF;
        border-radius: 100px;
        margin-right: 8px;
    }

    .articleAuthorName {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: rgba($color: #130F26, $alpha: 0.7);
        text-transform: capitalize;
    }

    .articleTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #130F26;
        margin-bottom: 8px;
        /* number of lines to show */
        height: 37px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        
    }

    .articleReadTime {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: rgba($color: #130F26, $alpha: 0.7);
    }

}