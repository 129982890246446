.page {
    flex: none;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    max-width: 300px;
    width: 100%;
    position: relative;

}

.heading {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.headerIcon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.headingText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #130F26;
    margin: 0
}

.description {
    color: #130F26;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;  
    margin-bottom: 24px;  
    height: 27px;
}

.price {
    background: rgba(19, 15, 38, 0.1);
    border-radius: 8px; 
    padding: 8px;  
    margin-bottom: 24px; 
    display: flex;
    width: fit-content; 
}

.priceText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #130F26;    
}

.offerContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 16px;
    position: relative;
}

.offer {
    margin-bottom: 10px;
    padding: 20px 0;
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

 .offerIcon {
    width: 18px;
    height: 18px;
    background-image: url(../../assets/tick-good.svg);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}

.offerText {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #130F26;
    opacity: 0.7; 
    margin-left: 5px;   
}

.btnCtn {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 16px;
    cursor: pointer;
    height: 100%;
}

.btn {
    box-shadow: 0px 10px 20px rgba(19, 15, 38, 0.1);
    border-radius: 100px;
    padding: 12px 24px;
    border: none;
    line-height: normal;
    
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
    }
}