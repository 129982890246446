.page {
    background-color: #fff;
    position: relative;
    min-height: calc(101vh - 56px);
    padding-bottom: 76px;

    .seeAllButton {
        width: 118px;
        height: 48px;
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        border: 2px solid rgba(19, 15, 38, 0.15);
        border-radius: 100px;
        text-transform: none;
        overflow: hidden;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        background-color: #fff;
        z-index: 2;
    }


    .header {
        height: 56px;
        padding: 0 24px;
        background-color: #fff;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;


        .arrowback {
            width: 24px;
            height: 24px;
            color: #130F26;
        }

        .heartIcon {
            width: 24px;
            height: 24px;
        }

        .shareIcon {
            width: 17.78px;
            height: 20px;
            color: #130F26;
        }

    }

    .headerImgContainer {
        width: 100%;
        height: 212px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .letsCook {
            width: 176px;
            height: 48px;
            background: #00866E;
            box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
            border-radius: 100px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #FFFFFF;
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-88px);
            z-index: 2;
        }
    }

    .headerBottom {
        height: 32px;
        background: #fff;
        border-radius: 20px;
        transform: translateY(-16px);
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: flex-end;

        .iconButton {
            margin-right: 20px;
            margin-top: 10px;
        }
    }

    .course {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.04em;
        color: rgba(0, 0, 0, 0.5);
        margin-right: auto;
    }

    .starIcon {
        color: #00866E;
        width: 16px;
        height: 15.5;
    }

    .rating {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.04em;
        color: #130F26;
        margin-right: 18px;
    }

    .vote {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
        color: #00866E;
        cursor: pointer;
    }

    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: #130F26;
        padding: 0 24px;
        text-transform: capitalize;
    }

    .chefIcon {
        width: 36px;
        height: 36px;
    }

    .chefName {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        text-transform: capitalize;
    }

    .levelContain {
        min-width: 0;
        flex: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .icon {
            width: 24px;
            height: 24px;
            margin-bottom: 12px;

        }

        .firstText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 6px;
        }

        .secondText {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
        }
    }

    .line {
        height: 1px;
        background-color: rgba(19, 15, 38, 0.12);
        margin-bottom: 24px;
    }


    .calulatorTextContain {
        min-width: 0;
        flex: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .firstText {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            color: #130F26;
            margin-bottom: 8px;

        }

        .secondText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: rgba(19, 15, 38, 0.6);
        }

    }


    .calculator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 104px;
        height: 40px;
        padding: 6px;
        background-color: rgba(0, 134, 110, 0.06);
        border-radius: 100px;

        .button {
            cursor: pointer;
            color: #00866E;
        }

        .number {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #130F26;
        }
    }


    .headerText {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #130F26;
        margin-bottom: 16px;
    }

    .lineHalf {
        height: 1px;
        background-color: rgba(19, 15, 38, 0.12);
        margin-bottom: 16px;
    }

    .ingredientListBoldText {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        margin-bottom: 6px;
    }

    .ingredientListListText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 16px;
    }

    .chip {
        min-width: 56px;
        height: 36px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #00866E;
        background-color: #fff;
        border-radius: 100px;
        margin: 0 12px 12px 0;
        mix-blend-mode: normal;
        border: 1.5px solid rgba($color: #00866E, $alpha:0.2);
        text-transform: capitalize;
    }

    .friendsRecipeContainer {
        background: rgba(19, 15, 38, 0.02);
        border-radius: 20px 20px 0 0;

        .cardContainer {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            margin: 0 -24px 28px;

            /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .card {
            flex: none;
            width: 204px;
            background: #FFFFFF;
            mix-blend-mode: normal;
            box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
            border-radius: 20px;
            padding: 16px 0;
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            cursor: pointer;


            .cardHeader {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                color: #130F26;
                padding: 0 16px 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .image {
                height: 122px;
                margin: 0 4px 8px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .date {
                padding: 0 16px 8px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 130%;
                color: rgba(0, 0, 0, 0.6);
            }

            .description {
                padding: 0 16px;
                height: 54px;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                color: #130F26;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

        }

        .makePictureButton {
            display: block;
            width: 284px;
            height: 48px;
            border: 2px solid rgba(19, 15, 38, 0.15);
            border-radius: 100px;
            margin: 0 auto 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #130F26;
        }

    }

}

.moveUp {
    border-radius: 20px 20px 0 0;
    position: relative;
    transform: translateY(-20px);
    background-color: #fff;
}