.lin {
  color: #292D32;
  font-family: Epilogue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 0;
  padding-bottom: 17px;
  margin-bottom: -2px;
  width: 93px;
  text-align: center;
}
.active {
  color: #292D32;

  font-family: Epilogue;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
    border-bottom: 2px solid #00866E;
}
.con {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid rgba(19, 15, 38, 0.12);
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 200;
  background-color: white;
  padding: 2px 20px;

}
