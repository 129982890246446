.page {
    background-color: #fff;
    padding-bottom: 104px;
}


.header {
    height: 56px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;


    .arrowback {
        width: 24px;
        height: 24px;
        color: #130F26;
    }

    .orderText {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        margin-left: 24px;
    }
}

.trackContainer {
    background-color: rgba(19, 15, 38, 0.03);
    min-height: 500px;
    padding: 48px 24px;

    .orderImage {
        display: block;
        width: 90%;
        max-width: 210.09px;
        height: auto;
        margin: 0 auto 24px;

    }

    .orderStatusText {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: #130F26;
        margin-bottom: 8px;
    }

    .orderStatusSubText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #130F26;
        margin-bottom: 40px;
    }

    .statusLineContainer {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        margin-bottom: 8px;

        .statusLineDone {
            width: 72px;
            height: 4px;
            background-color: #000000;
            border-radius: 100px;
            margin-bottom: 7px;
        }

        .statusLineCurrent {
            position: relative;
            width: 72px;
            height: 4px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            margin-bottom: 7px;

            &::before {
                position: absolute;
                left: 20px;
                top: 0;
                content: "";
                width: 32px;
                height: 4px;
                background-color: #00866E;
                border-radius: 100px;
            }
        }

        .statusLineUndone {
            position: relative;
            width: 72px;
            height: 4px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            margin-bottom: 7px;
        }

        .statusTextDone {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 160%;
            text-align: center;
            color: #000000;
        }


        .statusTextCurrent {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 160%;
            text-align: center;
            color: #00866E;
        }

        .statusTextUndone {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 160%;
            text-align: center;
            color: #000000;
            opacity: 0.2;
        }
    }
}

.orderListContainer {
    transform: translateY(-24px);
    border-radius: 24px 24px 0 0;
    background-color: #FFFFFF;
    padding: 24px 24px 0;

    .orderOverviewText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        margin-bottom: 24px;
    }
}

.list {

    .listItem {
        margin-bottom: 24px;
        display: flex;

        .image {
            flex: none;
            width: 72px;
            height: 72px;
            border-radius: 16px;
            overflow: hidden;
            background-size: auto 80%;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 16px;
        }

        .rightPan {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .topContainer {
                display: flex;
                width: 100%;

                .left {
                    width: 0;
                    flex-grow: 1;
                    flex-basis: 0;
                    display: flex;
                    flex-direction: column;
                    margin-right: auto;

                    .discription {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 12px;
                        margin-bottom: 5px;
                        text-transform: capitalize;
                    }

                    .name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 14px;
                        color: #130F26;
                        margin-bottom: 15px;
                        text-transform: capitalize;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .priceContainer {
                        display: flex;
                        align-items: center;

                        .price {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 14px;
                            letter-spacing: -0.01em;
                            color: #00866E;
                            margin-right: 8px;
                        }

                        .discount {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 12px;
                            letter-spacing: -0.01em;
                            color: #130F26;
                        }

                    }
                }

                .right {
                    width: 0;
                    flex-grow: 1;
                    flex-basis: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: space-between;

                    .totalPrice {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 14px;
                        text-align: right;
                        color: #130F26;
                    }

                    .totalQuantity {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 12px;
                        letter-spacing: -0.01em;
                        color: #130F26;
                        margin-bottom: 14px;
                    }
                }
            }


            .deliveryContainer {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;

                .tag {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin-bottom: 5px;

                    .leftTag {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 14px;
                        letter-spacing: -0.01em;
                        color: #130F26;
                    }

                    .rightTag {
                        border-radius: 100px;
                        padding: 5px 10px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        letter-spacing: -0.01em;
                        background-color: #00866E;
                        color: #fff;
                    }
                }

            }
        }
    }
}


.totalContainer {
    border-top: 0.5px solid #000000;
    border-bottom: 0.5px solid #000000;
    height: 52px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .totalText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }

    .totalPriceText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }
}

.shippingDetailsContainer {
    background-color: rgba(19, 15, 38, 0.04);
    border-radius: 16px;
    padding: 22px 20px 16px;

    .shippingDetails {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 16px;
    }

    .line {
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        margin: 0 -20px 16px;
    }

    .nameText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
        margin-bottom: 8px;
    }

    .addressText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        color: #130F26;
    }
}