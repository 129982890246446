.page {
  position: relative;
  background-color: #fff;
  padding-bottom: 76px;
}

.header {
  height: 68px;
  padding: 0 24px 12px;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .headertext {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
  }

  .headerIcon {
    margin-right: 24px;
  }


}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .titleText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #130f26;
  }

  .seeAllButton {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 100px;
  }

  .seeAllText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    color: #00866e;
    margin-right: 6px;
  }
}

.eventCard {
  padding: 16px;
  border-radius: 16px;
  border: 1.5px solid rgba(0, 0, 0, 0.00);
  background: rgba(19, 15, 38, 0.04);
  margin-bottom: 8px;

  .header {
    padding: 0px 0px 8px 0px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
      color: #130F26;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .phone {
      color: #00866E;
    }
  }

  .body {
    padding: 12px 0px 0px 0px;
    display: flex;
    align-items: center;

    .avatar {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    .name {
      color: #130F26;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 6px;
    }

    .session {
      color: rgba(19, 15, 38, 0.60);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}