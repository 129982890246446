.page {
    position: relative;
    background-color: #fff;
    padding-bottom: 76px;
}


.header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #00866E;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerBelow {
    position: relative;
    background-color: #00866E;
    height: 68px;

    .avater {
        background-color: #00866E;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: 80px;
        border: 3px solid #FFFFFF;
        z-index: 1;

    }
}



.content {
    background-color: #fff;
    padding: 48px 24px 0;
    border-radius: 20px 20px 0 0;
    transform: translateY(-30px);


    .username {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        color: #130F26;
        text-align: center;
        margin-bottom: 4px;
    }

    .email {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba(19, 15, 38, 0.6);
        text-align: center;
        margin-bottom: 30px;
    }


}

.sectionHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #130F26;
    margin-bottom: 12px;
}

.firstSection {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 45px;

    .icon {
        margin-right: 8px;
    }

    .firstSectionText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #130F26;
    }
}

.secondSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.5px solid rgba(19, 15, 38, 0.1);
    border-bottom: 0.5px solid rgba(19, 15, 38, 0.1);
    padding: 20px 0;
    margin-bottom: 45px;


    .secondSectionHeaderText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        text-align: center;
        margin-bottom: 8px;
    }

    .secondSectionSubText {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: rgba(19, 15, 38, 0.6);
    }
}


.sliderTagContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 24px;
    margin-bottom: 32px;
  
    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  
    .sliderTag {
      flex: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px 8px 22px;
      height: 36px;
      background-color: rgba(0, 134, 110, 0.1);
      border-radius: 0px 100px 100px 0px;
      margin-right: 12px;
      white-space: nowrap;
  
      cursor: pointer;
  
      .sliderTagDot {
        width: 6px;
        height: 6px;
        background-color: #ffffff;
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
  
    }
    .tagtext {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #130f26;
      }
  }


.suggestedAndRecomContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    grid-gap: 15px;
    margin-bottom: 12px;

    .item {
        background: rgba(19, 15, 38, 0.04);
        border-radius: 20px;
        padding: 16px;
        cursor: pointer;
    }

    .itemTop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        .itemCount {
            min-width: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 6px 3px;
            background-color: #FFFFFF;
            border-radius: 100px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            text-align: right;
            letter-spacing: 0.01em;
            color: #130F26;
        }
    }

    .itemBottom {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: #130F26;
    }
}

.caloriesContainer {
    background: rgba(19, 15, 38, 0.04);
    border-radius: 20px;
    padding: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    svg {
        flex: none;
        margin-right: 15px;
    }

    .itemTop {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #130F26;
        margin-bottom: 2px;
    }

    .itemBottom {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        color: rgba(19, 15, 38, 0.6);
    }



}

.listContainer {
    border-top: 0.5px solid rgba(19, 15, 38, 0.1);

    .list {
        display: flex;
        align-items: center;
        padding: 18px 0;
        border-bottom: 0.5px solid rgba(19, 15, 38, 0.1);
    }

    .listTextContainer {
        flex: 1;
        padding: 0 16px;
        display: flex;
        align-items: center;
    }

    .listText {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #130F26;
    }

    .listGreenDot {
        width: 8px;
        height: 8px;
        background-color: #00866E;
        margin-left: 8px;
        border-radius: 50%;
    }

    .listCount {
        display: flex;
        align-items: center;
        padding: 6px 6px 3px;
        min-width: 25px;
        background-color: rgba(19, 15, 38, 0.1);
        border-radius: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        text-align: right;
        letter-spacing: 0.01em;
        color: #130F26;
        text-align: center;
        margin-left: auto;
    }

}