.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}



.headerContainer {
    width: 100%;
    height: 56px;
    min-height: 56px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    flex: none;
    z-index: 1000;


    .icon {
        flex: none;
        width: 24px !important;
        height: 24px !important;
        padding: 0;
        color: #130F26;
    }

    .backIcon {
        width: 24px !important;
        height: 24px !important;
        color: #130F26;
    }


    .listItem {
        padding: 0 0 0 16px;
        min-width: 0;
        flex-grow: 1;



        .listAvatar {
            min-width: 44px;
        }

        .avatar {
            width: 32px;
            height: 32px;
            border-radius: 100%;
        }

        .primaryText {
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 15px;
            color: #130F26;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .secondaryText {
            width: 100%;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: rgba(19, 15, 38, 0.6);
            cursor: pointer;
            padding-top: 4px;
        }


    }
}

.iconImg {
    position: absolute;
}

.selectBoth {
    display: flex;
    position: relative;
}

.fileSelect {
    width: 24px;
    height: 24px;
    overflow: hidden;
    position: absolute;
    opacity: 0;
}

.chatContent {
    flex: 1 1 0px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;

    .timeStamp {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        text-transform: capitalize;
        color: rgba(19, 15, 38, 0.6);
        margin: 0 auto 2px;
    }



    .userChatContainer {
        max-width: 80%;
        margin-left: auto;
        background-color: rgba(0, 134, 110, 0.15);
        border-radius: 20px;
        padding: 18px 24px;
        margin-bottom: 16px;
        text-align: right;
        position: relative;

        .smallImg {
            height: 38px;
        }

        .imgShow {
            display: flex;
            flex-direction: row;
            background-color: #0000002d;
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            max-height: 70px;
            overflow: hidden;
        }

        .textSide {
            width: 70%;
            margin-left: 10px;
            font-size: 13px;
            text-align: left;
        }

        &::before {
            content: "";
            position: absolute;
            width: 0px;
            height: 0px;
            border: 5px solid;
            border-color: transparent transparent transparent rgba(0, 134, 110, 0.15);
            right: -9.9px;
            bottom: 15px;
        }

        .userChatThumbnail {
            width: auto;
            max-width: 95%;
            height: 200px;
            margin-bottom: 6px;
        }

        .userChatText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 120%;
            color: #00866E;
            text-align: left;
            word-wrap: break-word;
        }

        .replyMessage {
            background-color: #0000002d;
            padding: 10px 10px;
            height: 100%;
            width: 100%;
            max-height: 50px;
            font-size: 13px;
            border-radius: 10px;
            color: #00866E;
            text-align: left;
            overflow: hidden;
            margin-bottom: 10px;
        }

        .reply {
            cursor: pointer;
            font-size: 12px;
            margin-top: 10px;
        }

        &:hover {
            .dropdown {
                color: rgba(0, 0, 0, 0.54);
                background-color: transparent;
                position: absolute;
                z-index: 99;
                top: -8px;
                right: -10px;
                display: block;
            }

        }


    }




    .notUserChatContainer {
        max-width: 80%;
        margin-right: auto;
        background-color: rgba(19, 15, 38, 0.05);
        border-radius: 20px;
        padding: 12px 16px;
        margin-bottom: 16px;
        position: relative;

        .replyMessage {
            background-color: #0000002d;
            padding: 10px 10px;
            height: 100%;
            width: 100%;
            max-height: 50px;
            font-size: 13px;
            border-radius: 10px;
            color: #00866E;
            text-align: left;
            overflow: hidden;
            margin-bottom: 10px;
        }

        .reply {
            cursor: pointer;
            font-size: 12px;
            text-align: right;
            color: #00866E;
            margin-top: 10px;
        }

        .smallImg {
            height: 38px;
        }

        .imgShow {
            display: flex;
            flex-direction: row;
            background-color: #0000002d;
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            max-height: 70px;
            overflow: hidden;
        }

        .textSide {
            width: 70%;
            margin-left: 10px;
            font-size: 13px;
            text-align: left;
        }

        &:before {
            content: "";
            position: absolute;
            width: 0px;
            height: 0px;
            border: 5px solid;
            border-color: transparent rgba(19, 15, 38, 0.05) transparent transparent;
            left: -9.9px;
            bottom: 15px;
        }

        .notUserChatName {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 130%;
            // color: #005DB5;
            color: #00866E;
            margin-bottom: 6px;
            margin-right: 10px;
        }


        .notUserChatThumbnail {
            width: auto;
            max-width: 95%;
            height: 200px;
            margin-bottom: 6px;
        }


        .notUserChatText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 120%;
            color: #130F26;
            text-align: left;
            word-wrap: break-word;
        }

        &:hover {
            .dropdown2 {
                color: rgba(0, 0, 0, 0.54);
                background-color: transparent;
                position: absolute;
                z-index: 99;
                top: -8px;
                right: -10px;
                display: block;
            }

        }
    }

}


.list {

    .listItem {
        padding-left: 24px;
        padding-right: 24px;
    }

    .listAvatar {
        min-width: 64px;
    }

    .avatar {
        width: 48px;
        height: 48px;
        border-radius: 100%;
    }

    .primaryTextContainer {
        display: flex;
        align-items: center;

        .primaryTextleft {
            flex-grow: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.footer {
    flex: none;
    width: 100%;
    min-height: 72px;
    padding: 12px;
    background: #ebf2f3;
    position: relative;

    .joinConversionButton {
        height: 48px;
        width: 80%;
        margin: 0 10%;
        background-color: #00866E;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
        border-radius: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        text-transform: none;
    }

    .replyMessageDown {
        background-color: #cecaca;
        padding: 10px 10px;
        width: 93%;
        height: 70px;
        font-size: 13px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        // top: -37px;
        color: #00866E;
        text-align: left;
        margin-bottom: 10px;
        position: relative;
        z-index: 0;
    }

    .textReply {
        font-size: 13px;
        color: #00866E;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .smallImg {
        height: 38px;
    }

    .imgShow {
        display: flex;
        flex-direction: row;
    }

    .textSide {
        width: 70%;
        margin-left: 10px;
    }

    .con {
        position: relative;
        z-index: 5;
    }

    .close {
        background-color: #00866E;
        color: white;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        top: -9px;
        left: -6px;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #01614f;
        }
    }

    .closeCon {
        position: absolute;
    }

    .inputContainer {
        background: #ebf2f3;

        border-radius: 100px;
        height: 48px;
        display: flex;
        align-items: center;
        padding-left: 12px;
        z-index: 3;
        position: relative;

        .icon {
            width: 24px;
            height: 24px;
            color: #130F26;
        }

        .actionIcon {
            flex: none;
            width: 24px;
            height: 24px;
            padding: 0;
            color: #130F26;
            margin-right: 6px;
        }


        .input {
            flex-grow: 1;
            margin-right: 12px;
        }

        .send {
            flex: none;
            width: 44px;
            height: 44px;
            color: #00866E;
            // background-color: #00866E;
            box-shadow: 0px 10px 20pxrgba(0, 134, 110, 0.2);
            border-radius: 100px;
        }
    }

    .em-emoji-picker {
        // width: 100vw
    }

}

.emoj {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
}
.emojiReact{
    width: 100%;
    position: relative;
    /* display: flex; */
    /* justify-content: center; */
    /* padding: 24px; */

    em-emoji-picker {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 6px;
        height: 40vh
    }
}

.emojiText {
    position: absolute;
    font-size: 18px;
    bottom: -10px;
    left: 10px;
}

.menuBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    font-size: 15px;

    .iconM {
        width: 15px;
        height: 20px;
    }
}

.actionCtn {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 10px;
    font-size: 15px;
    border-radius: 10px;

    background: #ffffff;

    .form {
        // max-height: 48px;
        display: flex;
        align-items: center;
        padding-left: 6px;
        z-index: 3;
        position: relative;
        width: 100%;
        // overflow: scroll;      
    }

    .input {
        position: relative;
        overflow: scroll;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        /* Black */

        color: #130F26;
    }

    .iconM {
        width: 15px;
        height: 20px;
    }

    .actionIcon {
        flex: none;
        width: 24px;
        height: 24px;
        padding: 0;
        color: #130F26;
        margin: 0 6px;
    }

    .icon {
        width: 24px;
        height: 24px;

    }

    .iconSend {
        width: 12px;
        height: 12px;
        color: #00866E;
    }

    .send {
        flex: none;
        width: 24px;
        height: 24px;
        background-color: #00866E;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
        border-radius: 100px;
        margin: 0;
    }

}


.dropdown {
    display: none;
}

.dropdown2 {
    display: none;
}
.noShadow{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2);
}

.deleted {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: #00866E;
    text-align: left;
    word-wrap: break-word;
    font-style: italic;
    
}