.page {
	padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .imgBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        gap: 40px;
        margin-top: 20px;
    
        .text {
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
            text-align: center;
            color: #130F26;
            ;
            padding-top: 20px;
        }
    
        .subText {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            text-align: center;
            color: #130F26;
            margin-top: 10px;
            padding-bottom: 150px;
        }
    
        img {
            width: 260px;
            height: 260px;
        }
    }

    .bottomButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        margin-top: 30px;
    
    
        .prev {
            width: 48px;
            height: 48px;
            background: rgba(0, 134, 110, 0.1);
            border-radius: 1000px;
            color: #00866E;
            border: none;
            outline: none;
            font-size: 20px;
            font-weight: 800;
            position: fixed;
            bottom: 100px;
            left: 20px;
        }
    
        .next {
            width: 104px;
            height: 48px;
            background: #00866E;
            box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
            border-radius: 100px;
            color: white;
            border: none;
            outline: none;
            position: fixed;
            bottom: 100px;
            right: 20px;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
        }
    
        .more {
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            color: #00866E;
            cursor: pointer;
            margin-top: 20px;
            position: fixed;
            bottom: 100px;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
        }
    
        .nextCenter {
            width: 104px;
            height: 48px;
            background: #00866E;
            box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
            border-radius: 100px;
            color: white;
            border: none;
            outline: none;
            position: fixed;
            bottom: 130px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
        }
    }
}