.errorPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 40px 20px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px;
        margin-top: 48px;

        .errorTitle{
            color: #130F26;
            text-align: center;
            font-family: Epilogue;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 33.6px */     
            margin-top: 48px;       

        }

        .errorDescription {
            color: #130F26;
            text-align: center;
            font-family: Epilogue;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 22.4px */  
            margin-top: 16px;                     
        }

        .btn {
            width: 232px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 100px;
            background: #00866E;
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;  
            margin-top: 40px;                     
        }
    }
}