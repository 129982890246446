.homePage {
    position: relative;
    padding-bottom: 96px;

    .ProceedButton {
        width: 237px;
        height: 48px;
        position: fixed;
        left: 50%;
        bottom: 100px;
        transform: translateX(-50%);
        color: #FFFFFF;
        background: #00866E;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
        border: none !important;
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        z-index: 1000;

        &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            backdrop-filter: blur(10px);
        }
    }
}

.list {
    padding: 24px 24px 0;

    .listItem {
        width: 100%;
        margin-bottom: 24px;
        display: flex;

        .image {
            flex: none;
            width: 72px;
            height: 72px;
            border-radius: 16px;
            overflow: hidden;
            background-size: auto 80%;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 16px;
        }

        .rightPan {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .topContainer {
                display: flex;
                width: 100%;

                .left {
                    width: 0;
                    flex-grow: 1;
                    flex-basis: 0;
                    display: flex;
                    flex-direction: column;
                    margin-right: auto;

                    .discription {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 12px;
                        margin-bottom: 5px;
                        text-transform: capitalize;
                    }

                    .name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 14px;
                        color: #130F26;
                        margin-bottom: 15px;
                        text-transform: capitalize;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .priceContainer {
                        display: flex;
                        align-items: center;

                        .price {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 14px;
                            letter-spacing: -0.01em;
                            color: #00866E;
                            margin-right: 8px;
                        }

                        .discount {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 12px;
                            letter-spacing: -0.01em;
                            color: #130F26;
                        }

                    }

                    .resellButton {
                        display: flex;
                        align-items: center;
                        margin-top: 5px;
                        width: 50px;
                        height: 20px;
                        color: #FFFFFF;
                        background: #00866E;
                        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
                        border: none !important;
                        border-radius: 100px !important;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 12px;
                        letter-spacing: -0.01em;
                        text-transform: none !important;
                        text-align: center;
                    }
                }

                .right {
                    width: 0;
                    flex-grow: 1;
                    flex-basis: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: space-between;


                    .calculator {
                        width: 88px;
                        height: 32px;
                        padding: 0 5px;
                        color: #130F26;
                        background-color: rgba(0, 134, 110, 0.06);
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 16px;

                        .number {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 14px;
                            text-align: center;
                        }
                    }

                    .totalPrice {
                        padding: 0 5px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 12px;
                        letter-spacing: -0.01em;
                        color: #130F26;
                    }
                }

            }


            .deliveryContainer {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;

                .tag {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin-bottom: 5px;

                    .leftTag {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 14px;
                        letter-spacing: -0.01em;
                        color: #130F26;
                    }

                    .rightTag {
                        border-radius: 100px;
                        padding: 5px 10px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        letter-spacing: -0.01em;
                        background-color: #00866E;
                        color: #fff;
                    }
                }

            }

        }

    }
}

.totalContainer {
    border-top: 0.5px solid rgba($color: #000000, $alpha: 0.1);
    border-bottom: 0.5px solid rgba($color: #000000, $alpha: 0.1);
    height: 52px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .totalText {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }

    .totalPriceText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }
}

.saveContainer {
    min-height: 118px;
    background-color: rgba(19, 15, 38, 0.04);
    border-radius: 16px;
    margin-bottom: 8px;
    padding: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #130F26;

    .saveText {
        flex-grow: 1;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 140%;
        color: rgba(19, 15, 38, 0.6);
        padding-right: 24px;
    }

    .thumb {
        flex: none;
        width: 70.9px;
        height: 60px;
    }
}

.pointEarnedContainer {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    background-color: rgba(19, 15, 38, 0.04);
    border-radius: 16px;
    padding: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #130F26;

    .discountIcon {
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }


}