.header {
    height: 56px;
    padding: 0 24px;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;


}

.sliderCategoryContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 116;
    z-index: 2000;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 24px;
    padding-top: 24px;
    margin-bottom: 32px;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    .sliderCategoryContent {
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
    }

    .sliderCategoryContentImage {
        width: 64px;
        height: 64px;
        border-radius: 12px;
        // background-size: 150%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba($color: #000000, $alpha: 0.5);
        margin-bottom: 8px;
    }

    .sliderCategoryContentText {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #130F26;
        opacity: 0.7;
        width: 70px;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        margin-bottom: 8px;
        /* number of lines to show */
        height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .sliderCategoryContentImageMostPopular {
        width: 64px;
        height: 64px;
        border: 1.5px solid #00866E;
        background-color: #fff;
        border-radius: 12px;
        background-size: 150%;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .mostPopularContent {
            width: 56px;
            height: 56px;
            background-color: rgba(0, 134, 110, 0.7);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
        }

    }

    .sliderCategoryContentTextMostPopular {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #00866E;
        width: 70px;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        margin-bottom: 8px;
        /* number of lines to show */
        height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}