.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 96px;

    .nextButton {
        width: 104px;
        height: 48px;
        position: fixed;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        background-color: #00866E;
        z-index: 2;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);

        &:disabled {
            pointer-events: none;
            opacity: 0.8;
        }
    }
}



.header {
    height: 88px;
    background-color: #00866E;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;

    .icon {
        width: 24px;
        height: 24px;
        color: #fff;
    }

    .stepContainer {
        flex: none;
        min-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .stepIndicator {
            width: 24px;
            height: 4px;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 100px;
        }

        .stepIndicatorActive {
            width: 24px;
            height: 4px;
            background-color: #fff;
            border-radius: 100px;
        }
    }
}




.headerBottom {
    height: 44px;
    background: #fff;
    border-radius: 20px 20px 0 0;
    transform: translateY(-22px);
    position: -webkit-sticky;
    position: sticky;
    top: 88px;
    z-index: 1001;
}

.content {

    padding: 0 24px;

    .searchContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(19, 15, 38, 0.05);
        border-radius: 100px;
        padding-right: 12px;
        margin-bottom: 24px;
    }

    .searchIcon {
        width: 24px !important;
        height: 24px !important;
    }

    .searchInput {
        flex-grow: 1;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.5);
        caret-color: #00866E;
    }


    .headerText {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: #130F26;
    }


    .lightText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: rgba(19, 15, 38, 0.6);
    }

    .itemHeaderText {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #130F26;
    }

}

.productWidgetListSlider {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0 -24px 20px;
    padding: 16px 24px;
    border-top: 0.5px solid rgba(19, 15, 38, 0.12);
    border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.line {
    border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);
    margin: 0 -24px 28px;
}

.headerSearching {
    height: 56px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;

    .backIcon {
        width: 24px !important;
        height: 24px !important;
        color: #130F26;
    }

    .searchInput {
        flex-grow: 1;
        min-width: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        caret-color: #00866E;
    }

    .closeIcon {
        width: 20px !important;
        height: 20px !important;
        color: #130F26;
        background-color: rgba(19, 15, 38, 0.1);
        border-radius: 100%;
        padding: 2px;
    }
}