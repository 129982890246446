.page {
  position: relative;
  background-color: #fff;
  padding: 24px 24px 76px 24px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .titleText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #130f26;
  }

  .seeAllButton {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 100px;
  }

  .seeAllText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    color: #00866e;
    margin-right: 6px;
  }
}

.eventCard {
  padding: 16px;
  border-radius: 16px;
  border: 1.5px solid rgba(0, 0, 0, 0.00);
  background: rgba(19, 15, 38, 0.04);
  margin-bottom: 8px;

  .header {
    padding: 0px 0px 8px 0px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
      color: #130F26;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .phone {
      color: #00866E;
    }
  }

  .body {
    padding: 12px 0px 0px 0px;
    display: flex;
    align-items: center;

    .invitee {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .avatar {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    .name {
      color: #130F26;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 6px;
    }

    .session {
      color: rgba(19, 15, 38, 0.60);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.actions {
  display: flex;
  padding-top: 12px;
  align-items: flex-start;
  gap: 8px;
  margin-top: 12px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.10); 


  .reschedule {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-radius: 1000px;
    background: rgba(0, 134, 110, 0.20);

    .span {
      color: var(--Black, #130F26);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .cancel {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-radius: 1000px;
    background: var(--Green, #00866E);

    .text {
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}