.container {
    position: relative;
    background-color: #fff !important;
}

.makeMobile {
    position: relative;
    background-color: #fff !important;
    width: 100%;
    max-width: 375px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    min-height: calc(100vh);
    border-left: 1px solid rgb(235, 238, 240);
    border-right: 1px solid rgb(235, 238, 240);
}