.datePicker{
    display: flex;
    position: fixed;
    padding: 0 28.25px;
    width: 100%; 
    max-width: inherit;
    justify-content: space-between;
    background-color: #FFFFFF;
    z-index: 2;
    height: 60px;
    align-items: center;

    .dateField{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;

   
    .input{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #000000;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
    }

    .today{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 200px;
        width: 100%;
    }
    .inputWidth {
        width: 40%;
    }
    .text {
        margin-left: 2px;
        margin-bottom: 0;
    }

 }
  
}