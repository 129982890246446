.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 76px;

    .seeAllButton {
        width: 118px;
        height: 48px;
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        border: 2px solid rgba(19, 15, 38, 0.15) !important;
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        background-color: #fff;
        z-index: 2;
    }


    .header {
        height: 56px;
        padding: 0 24px 0;
        display: flex;
        align-items: center;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;

        .headerText {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #130F26;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .appBar {
        box-shadow: none !important;
        position: -webkit-sticky;
        position: sticky;
        top: 56px !important;
        z-index: 1000;
        border-bottom: none;
        background-color: #fff !important;

        .tab {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: rgba(19, 15, 38, 0.7);
            text-transform: capitalize !important;
            min-width: 72px !important;
        }

        .tabSelected {
            color: #00866E;
        }

        .indicator {
            display: flex;
            justify-content: center;
            background-color: transparent !important;

            span {
                width: 100%;
                max-width: 40px;
                height: 2px;
                background-color: #00866E;
            }
        }

    }

    .sliderCategoryContainer {
        position: -webkit-sticky;
        position: sticky;
        top: 116;
        z-index: 2000;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding-left: 24px;
        padding-top: 24px;


        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        .sliderCategoryContent {
            flex: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
        }

        .sliderCategoryContentImage {
            width: 64px;
            height: 64px;
            border-radius: 12px;
            background-size: 150%;
            background-position: center;
            background-repeat: no-repeat;
            background-color: rgba($color: #000000, $alpha: 0.5);
            margin-bottom: 8px;
        }

        .sliderCategoryContentText {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #000000;
            width: 70px;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
            margin-bottom: 8px;
            /* number of lines to show */
            height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .containerPopular {
        padding-top: 12px;
        padding-left: 22px;
        padding-right: 22px;
        padding-bottom: 24px;

        .typographyHeader {
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 20px !important;
            line-height: 20px !important;
            color: #000000 !important;
        }
    }

}