/* overwrited css */
* {
    box-sizing: border-box;
}

html {
    margin: 0;
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    color: #130F26;
    background-color: #ffff;
    font-family: 'Epilogue', 'Roboto', "sans-serif";
    position: relative;
    touch-action: manipulation;
}


.slick-slide,
.slick-slide div,
.slick-slide:focus,
.slick-slide:focus div {
    outline: none !important;
}


// Remove arrow from number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//Additionally, you can use this to change the text color:
input:-webkit-autofill {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
}

//Remove border from IOS
video {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}

.infinite-scroll-component {
    overflow-x: hidden !important;
}