.page {
    background-color: #fff;
    padding-bottom: 104px;
}


.header {
    height: 56px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;


    .arrowback {
        width: 24px;
        height: 24px;
        color: #130F26;
    }
}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #130F26;
    padding: 24px 16px 0;
    margin: 0;
}

.list {
    .listAvatar {
        min-width: 64px;
    }

    .avatar {
        width: 48px;
        height: 48px;
        background: rgba(19, 15, 38, 0.04);
        border-radius: 12px;

        img {
            width: 24px;
            height: 24px;
        }
    }

    .primaryTextContainer {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .primaryText {
            flex-grow: 1;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
        }

        .primaryTextRight {
            flex: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
        }

        .badge {
            flex: none;
            width: 16px;
            height: 16px;
            margin: 0 6px;
        }
    }


    .secondaryTextContainer {
        width: 100%;
        display: flex;
        align-items: center;

        .secondaryText {
            flex-grow: 1;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.6);
        }

        .secondaryTextRight {
            flex: none;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.6);
            margin-left: 6px;
        }
    }

    .cartListItem {
        padding-left: 48px;
    }

}