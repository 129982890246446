
.header {
    // height: 68px;
    // padding: 0 24px 12px;
    // background-color: #fff;
    // position: -webkit-sticky;
    // position: sticky;
    // top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);

    .headertext {
        // font-style: normal;
        // font-weight: 500;
        // font-size: 18px;
        // line-height: 18px;
        // color: #000000;
        // margin-right: auto;

        color: rgba(19, 15, 38, 0.60);
        font-family: Epilogue;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 18px;
    }

    .headerIcon {
        margin-right: 24px;
    }

    .active{
        color: #292D32;
        font-family: Epilogue;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-bottom: 2px solid #00866E;
    }


}