.input {
    .label {
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 14px !important;
        color: rgba(19, 15, 38, 0.6) !important;
    }


    .select {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #130F26;

        &:focus {
            background-color: transparent;
        }
    }

    .dropDownIcon {
        width: 12px;
        height: 12px;
        top: calc(50% - 6px);
        right: 8px;
        transform: rotateZ(-90deg);
        color: #00866E;
    }

    .underLine::before {
        border-bottom: 1px solid rgba(19, 15, 38, 0.2) !important;
    }

    .underLine:after {
        border-bottom: 1px solid rgba(19, 15, 38, 0.2) !important;
    }

}