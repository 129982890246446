.page {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    padding: 24px 0;
 }

 .header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
 
    .back {
       margin-right: 16px;
    }
 
    .avater {
       width: 40px;
       height: 40px;
       margin-right: 12px;
    }
 
    .headerTitle {
       font-style: normal;
       font-weight: 600;
       font-size: 15px;
       line-height: 15px;
       color: #130f26;
       margin-bottom: 6px;
    }
 
    .headerSubText {
       font-style: normal;
       font-weight: 400;
       font-size: 13px;
       line-height: 13px;
       color: rgba(0, 0, 0, 0.6);
    }
 }

 .articleContainer {
   padding: 0 24px;
   margin-bottom: 15px;
   position: relative;

   .articleContent {
       display: flex;
       margin-bottom: 12px;
       text-align: left;
       border-radius: 12px;
   }

   .articleImage {
       flex: none;
       width: 72px;
       height: 72px;
       border-radius: 12px;
       margin-right: 16px;
       background: rgba(80, 44, 127, 0.15);
       background-size: cover;
       background-position: center;
       background-repeat: no-repeat;
   }

   .resourceText {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      height: 72px;

   }

   .articleTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #130F26;
      margin-bottom: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
       
   }

   .articleReadTime {
       font-style: normal;
       font-weight: 400;
       font-size: 12px;
       line-height: 12px;
       color: rgba($color: #130F26, $alpha: 0.7);
       opacity: 0.7;
   }

   .remove {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: -18px;
   }

}

.content {
   padding: 24px;
   display: flex;
   flex-direction: column;
   gap: 20px;
   margin-bottom: 42px;
}

.text{
   margin: 0;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 16px;
   color: #130f26;
   padding: 8px;
}

.label {
   margin: 0;
}

.upload {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 40px;
   gap: 8px;
   margin: 5px 0;
   background: transparent;
   border-radius: 16px;
   border: 1.5px solid rgba(19, 15, 38, 0.1);
   color: #FFFFFF;
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 14px;
   cursor: pointer;
}

.doneBtn {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   padding: 10px 0;
   position: fixed;
   bottom: 10px;

   .btnCtn {
      width: 154px;
      height: 48px;

      .btn {
         background-color: #00866e;
         box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
         border: none !important;
         border-radius: 100px !important;
         z-index: 3000;
         text-transform: none !important;
         overflow: hidden !important;
         width: 100%;
         height: 100%;
         font-style: normal;
         font-weight: 600;
         font-size: 16px;
         line-height: 16px;
         text-align: center;
         color: #ffffff;

         &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            background: #a7a6a6;
          
         }
      }
   }
}

.file{
   display: flex;
   align-items: center;
   width: 100%;
   height: 100%;
   padding: 10px 0;
   gap:10px;


  .filetext {
      max-width: 70%;
      font-style: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 400;
      font-size: 12px;
      margin: 0;
  }

  .delete {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  }


}

