.splashScreen {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #019079;
    z-index: 3000;
    top: 0;
    left: 0;
    bottom: 0;
    // display: flex;
    // align-items: center;
    // justify-content: center;
}