.customProgessBar{
    display: flex;
    position: relative;
    width: 100%;

    .label{
        position: absolute;
        left: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin: 8px 8px;
    }

    .labelLow {
        color: #EE3D3F;
    }

    .labelHigh {
        color: #ffffff;
    }
}