.container {
    width: 100%;
    min-height: 200px;
    padding: 24px;
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #00866E;
}