.modal {
   position: fixed;
   background-color: rgba(19, 15, 38, 0.2);
   width: 100%;
   height: 100%;
   max-width: inherit;
   left: 0;
   // bottom: 0;
   z-index: 2005;
   top: 0;
   opacity: 0;
   transition: all 0.5s ease-in-out;
   pointer-events: none;
   display: flex;
   flex-direction: column;
   // padding-top: 96px;

   .modalContent {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      // border-radius: 20px 20px 0 0;
      position: relative;
      transform: translateY(100%);
      transition: all 0.5s ease-in-out;
      padding: 4px 24px 51px 24px;
      flex: 1;
   }
   .modalHeaderIcon2 {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
   }

   .modalHeader {
      display: flex;
      justify-content: center;
   }



   .modalBody {
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: scroll;

      .text {
         margin-top: 16px;
         margin-bottom: 0;
         text-align: center;
      }


      .items {
         display: flex;
         flex-direction: column;
         // height: 255px;

         .dataContainer {
            display: flex;
            gap: 8px;
            flex-direction: column;
            height: 100%;

            .dataBox {
               display: flex;
               flex-direction: column;
               align-items: flex-start;
               padding: 16px;
               gap: 8px;
               width: 100%;
               // height: 71px;
               border: 1.5px solid rgba(19, 15, 38, 0.1);
               border-radius: 16px;

               .dataContent {
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
               }

               .dataText {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 6px;
                  max-width: 235px;

                  .dataTextHeading {
                     font-family: "Epilogue";
                     font-style: normal;
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 19px;
                     color: #130f26;
                     margin: 0;
                     text-transform: capitalize;
                  }

                  .text {
                     font-style: normal;
                     font-weight: 400;
                     font-size: 14px;
                     line-height: 14px;
                     color: #130f26;
                     opacity: 0.6;
                     margin: 0;
                  }
               }
            }

            .add {
               background: #e5f3f0;
               border: 1.5px solid #e5f3f0;
            }
         }
      }

      .disableScrollbars {
         scrollbar-width: none;
         /* Firefox */
         -ms-overflow-style: none;
         /* IE 10+ */

         &::-webkit-scrollbar {
            background: transparent;
            /* Chrome/Safari/Webkit */
            width: 0px;
         }
      }

      .notesCtn {
         display: flex;
         flex-direction: column;
         gap: 16px;
         position: relative;

         .textarea {
            background: rgba(19, 15, 38, 0.04);
            border-radius: 16px;
            width: 100%;
            padding: 16px;
            border: none;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
         }
      }
      .doneBtn {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: 100%;
         padding: 10px 0;

         .btnCtn {
            width: 154px;
            height: 48px;

            .btn {
               background-color: #00866e;
               box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
               border: none !important;
               border-radius: 100px !important;
               z-index: 3000;
               text-transform: none !important;
               overflow: hidden !important;
               width: 100%;
               height: 100%;
               font-style: normal;
               font-weight: 600;
               font-size: 16px;
               line-height: 16px;
               text-align: center;
               color: #ffffff;

               &:disabled {
                  pointer-events: auto;
                  opacity: 0.8;
                  backdrop-filter: blur(10px);
               }
            }
         }
      }
   }
}

.open {
   opacity: 1;
   pointer-events: visible;

   .modalContent {
      transform: translateY(0);
   }
}

.searchButton {
   width: 106px;
   height: 48px;
   padding: 17px 32px 15px;
   margin: 0 auto;
   position: fixed;

   left: 50%;
   bottom: 84px;
   transform: translateX(-50%);

   background: #00866e;
   box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
   border: none !important;
   border-radius: 100px !important;
   text-transform: none !important;
   overflow: hidden !important;
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 16px;
   text-align: center;
   color: #ffffff;
   z-index: 3000;
   display: flex;
   justify-content: center;
   align-items: center;

   &:disabled {
      pointer-events: auto;
      opacity: 0.8;
      backdrop-filter: blur(10px);
   }
}
