.page {
  width: 100%;
  margin: auto;
  padding-top: 50px;
  padding-left: 20px;
}

.vi {
  position: absolute;
  background-color: white;
  padding: 5px;
  width: 130px;
  z-index: 1;
}

.date {
  width: 160px;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .cal {
    width: 20px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
}
.allBtns {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
}
.btn {
  background-color: #00866e;
  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
  border: none !important;
  border-radius: 100px !important;
  z-index: 30;
  text-transform: none !important;
  overflow: hidden !important;
  height: 100%;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-left: 5px;

  &:disabled {
    pointer-events: auto;
    opacity: 0.8;
    backdrop-filter: blur(10px);
  }
}
.meals {
  margin-top: 50px;
  overflow: scroll;

  .tags {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: sticky;
    top: 0px;
    .tag {
      border-radius: 11px;
      border: 1.5px solid var(--brand-blue-100, #edf3fb);
      background: var(--brand-blue-100, #edf3fb);
      color: var(--brand-blue-300, #1d65ce);
      font-family: Epilogue;
      font-size: 14px;
      text-align: center;
      min-width: 100px;
      padding: 5px 20px;
      margin-right: 22px;
      font-size: 13px;
    }
  }
  .days {
    display: flex;
    flex-direction: column;
    .day {
      color: #130f26;
      font-family: Epilogue;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-bottom: 1px solid rgba(19, 15, 38, 0.12);
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .cards {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-top: -30px;
      .card {
        flex: none;
        width: 105px !important;
        height: 105px !important;
        mix-blend-mode: normal !important;
        border-radius: 10px !important;
        margin-top: 30px;
        // box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08) !important;
        outline: none !important;
        margin-bottom: 12px;
        margin-right: 20px;
        cursor: pointer;

        &:focus {
          outline: none;
        }

        .img {
          height: 128px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: rgba(0, 134, 110, 0.06);
        }

        .titleContainer {
          padding: 10px 16px;
          display: flex;
          align-items: center;
          position: relative;
        }

        .chefImg {
          width: 36px;
          height: 36px;
          margin-right: 8px;
          border: 2px solid #fff;
          overflow: hidden !important;
          transform: translateY(-25%);

          img {
            border-radius: 100%;
            overflow: hidden;
          }
        }

        .chefName {
          text-transform: capitalize;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
          color: #000000;
        }

        .title {
          text-transform: capitalize;
          padding: 10px 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 15px;
          color: #000;
          // margin-bottom: 12px;
          /* number of lines to show */
          height: 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 80px;
        }

        .line {
          border: 0.5px solid #000000;
          height: 0px;
          opacity: 0.1;
        }

        .footer {
          padding: 0 16px;
          height: 39px;
          display: flex;
          align-items: center;
          justify-content: right;

          .img {
            width: 14px;
            height: 14px;
            margin-right: 7px;
          }
          .img1 {
            width: 18px;
            height: 18px;
            margin-right: -7px;
            margin-bottom: 17px;
          }

          .footerText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #130f26;
          }
        }
      }
    }
  }
}

// loader
.con {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 75px;
  height: 34px;
  background: rgb(245, 245, 245);
  border-radius: 20px;
}

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsEllipsis div {
  position: absolute;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #1d65ce;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: ldsEllipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: ldsEllipsis3 0.6s infinite;
}
@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.text {
  position: absolute;
  top: 5px;
  right: -205px;
  font-size: 13px;
  width: 200px;
  color: #1d65ce;
}
.save {
  position: fixed !important;
  z-index: 1;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 100px;
}
.no {
  position: fixed !important;
  z-index: 3;
  top: 50%;
  left: 30%;
  transform: translateX(-50% -50%);
  // height: 50px;
  width: 39%;
  padding: 40px;
  border: 2px solid #00866e;
}
.emp {
  width: 105px !important;
  height: 105px !important;
  padding: 12px 11px 12px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: rgba(19, 15, 38, 0.04);
  margin-top: 30px;
  outline: none !important;
  margin-bottom: 12px;
  margin-right: 20px;
}
.cd {
  align-self: flex-start;
  justify-content: center;
}
.clear {
  color: transparent;
}

.diet {
  .note,
  .ai {
    border-radius: 16px;
    border: 1.5px solid rgba(0, 0, 0, 0);
    background: rgba(0, 134, 110, 0.06);
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    width: 90%;
    height: 100%;
    padding: 20px;
    .textArea {
      border-radius: 16px;
      border: 1.5px solid rgba(0, 0, 0, 0);
      background: #fff;
      outline: none;
      padding: 10px;
    }
    .notes {
      color: #130f26;
      font-family: Epilogue;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .saveNote {
      position: fixed !important;
      z-index: 1;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      height: 50px;
      width: 100px;
      background-color: #00866e;
      box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
      border: none !important;
      border-radius: 100px !important;
      z-index: 30;
      text-transform: none !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #ffffff;
      padding: 10px;
      width: 90px;
      margin-top: 20px;
      &:disabled {
        pointer-events: auto;
        // opacity: 0.8;
        backdrop-filter: blur(10px);
        background-color: rgb(212, 212, 212);
        color: #898686;
      }
    }
  }
}
.vis {
  border-radius: 16px;
  border: 1.5px solid rgba(0, 0, 0, 0);
  background: rgba(0, 134, 110, 0.06);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  width: 93%;
  margin-top: 30px;

  .val {
    .tops {
      color: #130f26;
      font-family: Epilogue;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 10px;
    }
    .bots {
      color: rgba(19, 15, 38, 0.6);
      font-family: Epilogue;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .dietImg {
    border-radius: 48px;
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
}
.btnBlock {
  border-radius: 1000px;
  border: 1.5px solid var(--Utility-Grey, #5a5767);
  background: var(--Utility-Grey, #5a5767);
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  color: var(--Brand-Green-100, #ebf5f3);
  font-family: Epilogue;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 40px;
  .block {
    width: 8px;
    height: 8px;
    background: var(--Green, #00866e);
    border-radius: 1000px;
    margin-left: 5px;
  }
}
.right {
  margin-right: 20px;
}
.ilo {
  width: 10px;
  height: 10px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  img{
    // width: 20px;
    height: 10px;
  }
}