
.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 50px;
    .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #130F26;
    }
    .back {
        width: 24.68px;
        height: 24px;
        position: absolute;
        left: 20px;
        cursor: pointer;
    }
}

.weeks {
    padding: 20px;
    .list {
        margin-top: 40px;
        .month {
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #000000;
            border-bottom: 1px solid rgba(208, 208, 210, 0.44);
            padding-bottom: 10px;
        }
    }
    .both {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(208, 208, 210, 0.44);
        padding: 10px 0;
        .day {
            display: flex;
            flex-direction: row;
            align-items: center;
            .week {
                width: 48px;
                height: 48px;
                background: rgba(0, 134, 110, 0.1);
                font-weight: 500;
                font-size: 21px;
                line-height: 22px;
                color: #00866E;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            .data {
                font-style: normal;
                margin-left: 10px;
                .title {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 14px;
                    color: #130F26;
                    padding-bottom: 5px;
                }
                .sub {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 13px;
                    color: rgba(19, 15, 38, 0.6);
                }
            }
        }
    }
}