.page {
   position: relative;
   background-color: #fff;
   padding: 24px 0;
}

.header {
   height: 68px;
   padding: 0 24px 12px;
   background-color: #fff;
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   z-index: 2000;
   display: flex;
   align-items: center;

   .back {
      margin-right: 16px;
   }

   .avater {
      width: 40px;
      height: 40px;
      margin-right: 12px;
   }

   .headerTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 15px;
      color: #130f26;
      margin-bottom: 6px;
   }

   .headerSubText {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: rgba(0, 0, 0, 0.6);
   }
}

.content {
   display: flex;
   flex-direction: column;
   padding: 10px 24px;

   .card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 16px;
      gap: 12px;

      .cardHeader {
         display: flex;
         align-items: center;
         width: 100%;
         gap: 8px;

         .cardNo {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.7);
            margin: 0;
         }

         .cardText {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #000000;
         }
      }

      .cardBody {
         display: flex;
         flex-wrap: wrap;
         gap: 8px;

         .cardTags {
            padding: 5px 10px;
            background: rgba(19, 15, 38, 0.06);
            border-radius: 4px;

            .cardTagText {
               font-style: normal;
               font-weight: 500;
               font-size: 12px;
               line-height: 12px;
               color: rgba(0, 0, 0, 0.7);
            }
         }
      }
   }
}

.divider {
   height: 0.3px;
   background-color: rgba(0, 0, 0, 0.5);
   width: 100%;
}

.cuisineContainer {
   display: flex;
   flex-wrap: wrap;
   gap: 8px;
   align-items: center;

   .p {
      margin: 0;
   }
}

.noData {
   text-align: center;
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 14px;
   color: #00866e;
   margin: 0;
}
