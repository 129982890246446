.page {
    position: relative;
    display: flex;
    padding-bottom: 90px;
    flex-direction: column;
    
    .initialStage {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        padding-top: 56px;
        margin-top: 32px;
    
        .content {
            padding: 0 12px;
        }
    
        .text {
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
            text-align: center;
            color: #130F26;
            margin-top: 40px;
            margin-bottom: 12px;
        }
    
        .subText {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            color: #130F26;
            padding-bottom: 30px;
            opacity: 0.7;
        }
    
        .premuimText {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            margin: 0;
            text-align: start;
            color: #130F26;
            margin-top: 32px;
        }
    
        .imgCon {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 40px;
        }
    
        .img {
            width: 100%;
            height: 100%;
        }
    
        .startButton {
            height: 48px;
            background: #00866E;
            box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
            border-radius: 100px;
            color: white;
            border: none;
            outline: none;
            bottom: 100px;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
        }
    
        .priceContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
    
        .pricing {
            display: flex;
            gap: 8px;
            width: 100%;
            padding: 24px 0;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
    
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}