.productWidgetContainer {
    position: relative;
    width: 100%;
    height: 265px;
    border-radius: 20px !important;
    box-shadow: none !important;
    padding-top: 20px;
    cursor: pointer;

    .add {
        min-width: 36px;
        min-height: 36px;
        text-align: center;
        padding: 6px;
        position: absolute;
        top: 0px;
        right: 0px;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        border-bottom-left-radius: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #130F26;
    }

    .selected {
        min-width: 36px;
        min-height: 36px;
        text-align: center;
        padding: 6px;
        position: absolute;
        top: 0px;
        right: 0px;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        border-bottom-left-radius: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #00866E;
    }

    .img {
        width: 112.44px;
        height: 132px;
        mix-blend-mode: normal;
        margin-left: auto;
        margin-right: auto;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 17px;
    }

    .desc {
        height: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        padding: 0 12px;
        margin-bottom: 5px;
        text-transform: capitalize;
    }

    .name {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #130F26;
        padding: 0 12px;
        margin-bottom: 10px;
        text-transform: capitalize;
    }

    .size {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.01em;
        color: rgba(19, 15, 38, 0.7);
        padding: 0 12px;
        text-transform: capitalize;
    }

    .pointContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;


        .points {
            display: flex;
            align-items: center;
            min-width: 61px;
            height: 24px;
            padding: 0 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.01em;
            text-align: center;
            color: #fff;
        }
    }
}