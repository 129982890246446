.productWidgetContainer {
  position: relative;
  width: 100%;
  height: 265px;
  border-radius: 20px !important;
  box-shadow: none !important;
  padding-top: 30px;
  cursor: pointer;

  .soldOutContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #fff, $alpha: 0.4);
    z-index: 1;

    .soldOut {
      padding: 7px 8px 4px;
      min-width: 73px;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: #ffffff;
    }
  }

  .add {
    position: absolute;
    top: 0px;
    right: 0px;
    min-width: 36px;
    min-height: 36px;
    text-align: center;
    padding: 6px;
    background: #130f26;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    border-bottom-left-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ImgCtn {
    display: flex;
    width: 100%;
    position: relative;
    width: 112.44px;
    height: 132px;
    margin: 0 auto;
    margin-bottom: 10px;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    
  }

  .desc {
    height: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    padding: 0 12px;
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: #130f26;
    padding: 0 12px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  .sizeCtn {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
    padding: 0 12px;
  }

  .size {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.01em;
    color: rgba(19, 15, 38, 0.7);
    text-transform: capitalize;
  }

  .specialOfferText{
    color:#FFFFFF;
    padding: 4.5px 4px;
    background: #EE3D3F;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
  }

  .specialOfferContainer{
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    height: 32px;
    border-top: 0.5px solid rgba(19, 15, 38, 0.2);
    padding: 0 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #130f26;

    .specialOffer{
      display: flex;
      gap: 6px;

    }
  }

  .priceContainer {
    display: flex;
    align-items: center;
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 0 12px;
    height: 32px;
    border-top: 0.5px solid rgba(19, 15, 38, 0.2);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #130f26;

    .divider {
      height: 100%;
      width: 0.5px;
      background-color: rgba(19, 15, 38, 0.2);
      margin-left: 11px;
      margin-right: 8px;
    }

    .discountContainer {
      display: flex;
      align-items: center;
      padding: 0 6px;

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 1px 2px 0;
        min-width: 18px;
        height: 18px;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.01em;
        color: #ffffff;
        background: rgba(19, 15, 38, 0.7);
        border-radius: 4px;
        margin-right: 6px;
      }
    }

    .leftText {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      text-align: right;
      letter-spacing: -0.01em;
      color: rgba(19, 15, 38, 0.7);
      margin-left: auto;
    }
  }

}

.addWidgetContainer {
  position: relative;
  width: 100%;
  height: 265px;
  border-radius: 20px !important;
  box-shadow: none !important;
  padding-top: 20px;
  background-color: rgba(19, 15, 38, 0.75) !important;
  backdrop-filter: blur(50px);

  .headerText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    margin-bottom: 16px;
  }

  .calculator {
    width: 108px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    margin-bottom: 24px;
    color: #130f26;
    background: #ffffff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .number {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
    }
  }

  .priceContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #ffffff;
    margin-bottom: 24px;

    .activePrice {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .discountContainer {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 5px 6px;

      .number {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 2px 0;
        min-width: 18px;
        height: 18px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #ffffff;
        background: rgba(19, 15, 38, 0.7);
        border-radius: 3px;
        margin-right: 6px;
      }
    }
  }

  .doneContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 40px;
      height: 40px;
      color: #fff;
      background: #00866e;
      border-radius: 100%;
      padding: 8px;
    }
  }

  .footer {
    position: absolute;
    width: 30px;
    height: 3px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 100px;
    bottom: 3px;
    left: 50%;
    transform: translateX(-15px);
  }
}

.GroceryWidgetContainer {
  padding: 24px;
}

.tile {
  overflow: visible !important;
}

.productWidgetContainerAsyc {
  position: relative;
  width: 100%;
  height: 265px;
  border-radius: 20px !important;
  box-shadow: none !important;
  padding-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}