.modal {
  position: fixed;
  background: rgba(19, 15, 38, 0.2);
  width: 100%;
  display: flex;
  max-width: inherit;
  z-index: 2005;
  top: 0;
  bottom: 0;
  max-height: 100vh;
  // align-items: flex-end;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  pointer-events: none;

  &.makeMobile {
    max-width: 375px !important;
  }

  .modalContent {
    width: 100%;
    background: #ffffff;
    position: relative;
    transform: translateY(100%);
    transition: all 0.5s ease-in-out;
    height: 100vh;
  }

  .modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .headerText {
      color: #000000;
      font-family: "Epilogue";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      margin-top: 20px;
    }
  }
}

.open {
  opacity: 1;
  pointer-events: visible;

  .modalContent {
    transform: translateY(0);
  }
}

.hide {
  display: none;
}
.icns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  .it {
    color: var(--Black, #130f26);
    font-family: Epilogue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px 0;
  }
}

//extra

.addFoodContainer {
  z-index: 2003;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  background: #ffffff;
  width: 100vw;
  position: fixed;
  max-width: inherit;
  transform: translateX(-50%);
  padding: 54px 24px 0;
  // height: 100vh;

  .input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .imh {
    position: absolute;
    top: 15px;
    left: 20px;
    width: 17px;
    height: 17px;
  }
  .canc {
    position: absolute;
    top: 17px;
    right: 70px;
    width: 12px;
    height: 12px;
    z-index: 4;
  }

  .searchInput {
    flex-grow: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    background: rgba(19, 15, 38, 0.05);
    border-radius: 100px;
    border: none;
    outline: none;
    height: 48px;
    padding-left: 50px;
    padding-top: 5px;
    padding-right: 40px;
    margin-right: 20px;
  }

  .bat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }

  .bar {
    width: 24px;
    height: 24px;
  }

  .sticky {
    position: sticky;

    .header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .headerText {
        display: flex;
        gap: 25.65px;
        align-items: center;

        .text {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          color: #130f26;
        }
      }
    }
  }

  .tabsContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 156px);

    .tabs {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      max-width: inherit;
      width: 100%;

      .tablists {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.01em;
        color: #130f26;
        margin: 0;
        cursor: pointer;
        padding: 8px 0;
      }

      .active {
        color: #00866e;
        border-bottom: 1px solid #00866e;
      }
    }

    .dataContainer {
      margin: 24px 0;
      display: flex;
      gap: 8px;
      flex-direction: column;
      overflow-y: scroll;

      .dataBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 8px;
        width: 100%;
        // height: 71px;
        border: 1.5px solid rgba(19, 15, 38, 0.1);
        border-radius: 16px;

        .dataContent {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        }

        .listImage {
          width: 48px;
          height: 48px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          border-radius: 6px;
          margin-right: 12px;
        }

        .dataText {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 6px;
          max-width: 235px;

          .dataTextHeading {
            font-family: "Epilogue";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #130f26;
            margin: 0;
            text-transform: capitalize;
          }

          .text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #130f26;
            opacity: 0.6;
            margin: 0;
          }
        }
      }

      .add {
        background: #e5f3f0;
        border: 1.5px solid #e5f3f0;
      }
    }

    .disableScrollbars {
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* IE 10+ */

      &::-webkit-scrollbar {
        background: transparent;
        /* Chrome/Safari/Webkit */
        width: 0px;
      }
    }
  }
}

.hide {
  display: none;
}

.searchButton {
  width: 106px;
  height: 48px;
  padding: 17px 32px 15px;
  margin: 0 auto;
  position: fixed;

  left: 50%;
  bottom: 84px;
  transform: translateX(-50%);

  background: #00866e;
  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
  border: none !important;
  border-radius: 100px !important;
  text-transform: none !important;
  overflow: hidden !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    pointer-events: auto;
    opacity: 0.8;
    backdrop-filter: blur(10px);
  }
}

.donebtn {
  width: 154px;
  height: 48px;
  position: relative;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  background-color: #00866e;
  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
  border: none !important;
  border-radius: 100px !important;
  text-transform: none !important;
  overflow: hidden !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  z-index: 3000;
  margin: 18px 0;

  &:disabled {
    pointer-events: auto;
    opacity: 0.8;
    backdrop-filter: blur(10px);
  }
}

.buttonBase {
  position: sticky;
  max-width: inherit;
  width: 100%;
  z-index: 2003;
  bottom: 0;
  gap: 16px;

  .count {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.2);
    border-radius: 20px 20px 0px 0px;
    gap: 16px;
    width: 100%;

    .textCtn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 24px 16px;

      .text {
        margin: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #130f26;
      }

      .counter {
        background: #00866e;
        border-radius: 100px;
        padding: 5px 8px;
        margin: 0;
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: -0.01em;
        color: #ffffff;
      }
    }
  }
}

.modal {
  position: fixed;
  background-color: rgba(19, 15, 38, 0.2);
  width: 100%;
  z-index: 2005;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
  display: flex;
  flex-direction: column;

  .modalContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    position: relative;
    padding-top: 24px;
  }

  .modalContent {
    width: 100%;
    height: 100%;
    position: relative;
    transition: all 0.5s ease-in-out;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // overflow-y: scroll;
    padding-bottom: 24px;

    .text {
      margin-top: 16px;
      margin-bottom: 0;
    }

    .items {
      display: flex;
      flex-direction: column;
      // max-height: 255px;

      .dataContainer {
        display: flex;
        gap: 8px;
        flex-direction: column;
        // overflow-y: scroll;
        height: 100%;

        .dataBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
          gap: 8px;
          width: 100%;
          // height: 71px;
          border: 1.5px solid rgba(19, 15, 38, 0.1);
          border-radius: 16px;

          .dataContent {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          }

          .dataCtn {
            display: flex;
            gap: 8px;
            align-items: center;
          }

          .listImage {
            width: 48px;
            height: 48px;
            border-radius: 6px;
          }

          .thumbnail {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .dataText {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 6px;
            max-width: 235px;

            .dataTextHeading {
              font-family: "Epilogue";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 19px;
              color: #130f26;
              margin: 0;
              text-transform: capitalize;
            }

            .text {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 14px;
              color: #130f26;
              opacity: 0.6;
              margin: 0;
            }
          }
        }

        .add {
          background: #e5f3f0;
          border: 1.5px solid #e5f3f0;
        }
      }
    }

    .disableScrollbars {
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* IE 10+ */

      &::-webkit-scrollbar {
        background: transparent;
        /* Chrome/Safari/Webkit */
        width: 0px;
      }
    }

    .notesCtn {
      display: flex;
      flex-direction: column;
      position: relative;

      .textarea {
        background: rgba(19, 15, 38, 0.04);
        border-radius: 16px;
        width: 100%;
        padding: 16px;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .doneBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px 0;

      .btnCtn {
        width: 154px;
        height: 48px;

        .btn {
          background-color: #00866e;
          box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
          border: none !important;
          border-radius: 100px !important;
          z-index: 3000;
          text-transform: none !important;
          overflow: hidden !important;
          width: 100%;
          height: 100%;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: #ffffff;

          &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            backdrop-filter: blur(10px);
          }
        }
      }
    }
  }
}

.modal2 {
  position: fixed;
  background: #e5f3f0;
  width: 100%;
  height: 100%;
  // max-width: inherit;
  z-index: 3000;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
  display: flex;
  flex-direction: column;

  .modalHeader2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;
    gap: 53px;
    padding: 10px 24px;

    .modalHeaderIcon2 {
      display: flex;
      justify-content: space-between;
    }

    .text {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 25px;
      color: #130f26;
    }
  }

  .modalContainer2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 20px 20px 0 0;
    position: relative;
  }

  .modalContent2 {
    width: 100%;
    position: relative;
    transition: all 0.5s ease-in-out;
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    overflow-y: auto;

    .quantity {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      margin-bottom: 0;
    }

    .subHeading {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #130f26;
    }

    .quantityCtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 0;

      .input {
        margin-bottom: 0;
      }
    }

    .notes {
      display: flex;
      flex-direction: column;
      position: relative;

      .note {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: rgba(19, 15, 38, 0.6);
      }

      .textarea {
        background: rgba(19, 15, 38, 0.04);
        border-radius: 16px;
        width: 100%;
        padding: 16px;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .doneBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 10px 0;

      .btnCtn {
        width: 154px;
        height: 48px;

        .btn {
          background-color: #00866e;
          box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
          border: none !important;
          border-radius: 100px !important;
          z-index: 3000;
          text-transform: none !important;
          overflow: hidden !important;
          width: 100%;
          height: 100%;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: #ffffff;

          &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            backdrop-filter: blur(10px);
          }
        }
      }
    }
  }
}

.open {
  opacity: 1;
  pointer-events: visible;

  .modalContent {
    transform: translateY(0);
  }
}

.pag {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #00866e;
}

.pagination {
  display: flex;
  flex: row;
  justify-content: center;
  margin: 30px 0;
  margin-bottom: 60px;
}

.num {
  color: #00866e;
  font-size: 15px;
  margin: 5px 20px;
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.noResult {
  width: 300px;
  height: 300px;
  margin: 20px auto;
}

.hold {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.texter {
  font-size: 14px;
  padding-top: 20px;
  text-align: center;
}

.holdScanner {
  position: relative;

  .indicator {
    position: absolute;
    top: 150px;
    left: 35px;
    width: 80%;
    border: 5px solid #00866e;
    height: 100px;
  }

  .warn {
    background-color: #00866e;
    color: white;
    text-align: center;
    position: absolute;
    top: 270px;
    left: 35px;
    padding: 5px 10px;
    font-size: 13px;
    width: 80%;
  }

  .warna {
    text-align: center;
    padding: 5px 10px;
    font-size: 13px;
    width: 100%;
  }
}
.fab {
  color: #ffffff !important;
  background-color: #00866e !important;
  width: 15px;
  height: 15px;
  font-size: 20px;
  border-radius: 50%;
  margin: 0 9px;
  box-sizing: border-box;
}

.loader {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sin {
  width: 79px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: rgba(19, 15, 38, 0.6);
}

sin b {
  color: #000000;
}
.fles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
::-webkit-progress-bar {
  background-color: rgba(19, 15, 38, 0.2);
}

::-webkit-progress-value {
  background-color: #130f26;
}

::-moz-progress-bar {
  background-color: #130f26;
}

progress {
  -webkit-appearance: none;
  border: 0;
  height: 7px;
  border-radius: 20px;
  margin: 10px 0;
  width: 68px;
}
progress::-webkit-progress-bar {
  border: 0;
  height: 7px;
  border-radius: 20px;
}
progress::-webkit-progress-value {
  border: 0;
  height: 7px;
  border-radius: 20px;
}
progress::-moz-progress-bar {
  border: 0;
  height: 7px;
  border-radius: 20px;
}

.bod {
  border-right: 1px solid rgba(19, 15, 38, 0.2);
}

.dropDown {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  // border: 1.5px solid rgba(19, 15, 38, 0.1);
  border-radius: 16px;
  background-color: white;
  margin-top: 12px;
  transition: all 1s ease-in-out;

  .dropDownContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 16px;
    cursor: pointer;

    .quantity {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      margin-bottom: 0;
      width: 100%;
    }

    .subHeading {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #130f26;
      margin-bottom: 0;
    }

    .required {
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #ff0000;
      margin-bottom: 0;
      margin-top: 5px;
    }

    .quantityCtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 0;

      .input {
        margin-bottom: 0;
      }
    }
    .nutrientsBar {
      display: flex;
      margin-top: 16px;
      margin-bottom: 0;
      width: 100%;
    }

    .addPicture {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;

      .subHeading {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: rgba(19, 15, 38, 0.6);
        margin: 0;
      }

      .addPictureBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        gap: 8px;
        background: transparent;
        border-radius: 16px;
        border: 1.5px solid rgba(19, 15, 38, 0.1);
        color: #ffffff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;

        .editIcon {
          width: 24px;
          height: 24px;
          cursor: pointer;
          color: #130f26;
        }

        .text {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #130f26;
        }
      }
    }
  }
}

.showMore {
  background: white;
  border-radius: 100px;
  margin-right: 3px;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: auto;
  }

  .text {
    font-size: 12px;
    color: #00866e;
    margin: 0;
    text-align: center;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 0;
  padding: 0 16px;
}
.diet {
  .note,
  .ai {
    border-radius: 16px;
    border: 1.5px solid rgba(0, 0, 0, 0);
    background: rgba(0, 134, 110, 0.06);
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    width: 100%;
    padding: 20px;

    .textArea {
      border-radius: 16px;
      border: 1.5px solid rgba(0, 0, 0, 0);
      background: #fff;
      outline: none;
      padding: 10px;
    }
    .notes {
      color: #130f26;
      font-family: Epilogue;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 10px;
    }
    .saveNote {
      z-index: 1;

      height: 50px;
      width: 100px;
      background-color: #00866e;
      box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
      border: none !important;
      border-radius: 100px !important;
      z-index: 30;
      text-transform: none !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #ffffff;
      padding: 10px;
      width: 90px;
      margin-top: 20px;
      &:disabled {
        pointer-events: auto;
        // opacity: 0.8;
        backdrop-filter: blur(10px);
        background-color: rgb(212, 212, 212);
        color: #898686;
      }
    }
  }
}
.allBtns{
  width: 100%;
}
.btn {
  background-color: #00866e;
  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
  border: none !important;
  border-radius: 100px !important;
  z-index: 30;
  text-transform: none !important;
  overflow: hidden !important;
  height: 100%;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin-left: 5px;
  margin-top: 10px;

  &:disabled {
    pointer-events: auto;
    opacity: 0.8;
    backdrop-filter: blur(10px);
  }
}
.hr {
  color: #130f26;
  text-align: center;
  font-family: Epilogue;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  padding: 20px 0;
  padding-bottom: 10px;
}
.hm {
  color: #130f26;
  text-align: center;
  font-family: Epilogue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  padding: 20px 0;
  padding-bottom: 10px;
}
.pre {
  position: relative;
  padding-bottom: 30px;
  //   width: 100%;
  //   white-space: pre-wrap;
  //   overflow-wrap: break-word;
  max-height: 100%;
  //   overflow-y: auto;
  //   flex: 1;
  white-space: pre-line;
  background-color: white;
  border-radius: 20px;
  min-height: 100px;
  padding: 20px 20px;
  font-size: 14px;
}