.homePage {
    position: relative;
    padding-bottom: 96px;
}

.header {

    padding: 24px;
    position: -webkit-sticky;
    position: sticky;
    top: 56px;
    z-index: 1000;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .searchContainer {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: rgba(19, 15, 38, 0.05);
        border-radius: 100px;
        padding-right: 12px;
    }

    .searchIcon {
        width: 24px !important;
        height: 24px !important;
    }

    .searchInput {
        flex-grow: 1;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.5);
    }

    .addIcon {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        width: 48px;
        height: 48px;
        min-width: 36px;
        min-height: 36px;
        margin-left: 6px;
        background-color: rgba(0, 134, 110, 0.06);
        border: none;
        border-radius: 100%;

        svg {
            fill: #00866E;
        }
    }


}

.list {

    .listItem {
        padding-left: 24px;
        padding-right: 24px;
    }

    .listAvatar {
        min-width: 64px;
    }

    .avatar {
        width: 48px;
        height: 48px;
        border-radius: 100%;
    }

    .containerText {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .unreadContainer {
        display: flex;
        justify-content: end;
      
    }

    .unread{
        margin-left: 5px;
        display: inline-block;
        padding: 4px 4px;
        background-color: green;
        color: white;
        font-weight: bold;
        border-radius: 50%;
        font-size: 10px;
        text-align: center;
        min-width: 20px;
        line-height: 12px;
        transition: all 0.2s ease-in-out;
    }


    .primaryTextContainer {
        display: flex;
        align-items: center;

        .primaryTextleft {
            flex-grow: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: capitalize;
        }

        .primaryTextRight {
            max-width: 100%;
            margin-left: 6px;
            flex: none;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: rgba(19, 15, 38, 0.6);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }

    .secondaryText {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: rgba(19, 15, 38, 0.6);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}