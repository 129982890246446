.con {
  height: 94px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  border-bottom: 0.5px solid #d9d8df;
  top: 0;
  left: 0;
  z-index: 100;
  background: white;

}
.data {
  width: 100vw;
  height: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.title {
  color: var(--Black, #130f26);
  text-align: center;
  font-family: Epilogue;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.back {
  z-index: 20;
  width: 24px;
  height: 24px;
}
.settings {
    width: 30px;
}

.beta{
  display: flex;
  gap: 8px;
  align-items: center;
}

.betaText {
  margin: 0;
}

.betaText > a{
  text-decoration: underline;
  cursor: pointer;
  color: #130f26;
}