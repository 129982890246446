.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 76px;


    .header {
        height: 56px;
        padding: 0 27px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
        margin: 24px 0;
        background-color: #fff;

        .headerText {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #130F26;
            margin-left: auto;
            margin-right: auto;
        }
    }


    .content {
        padding-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;

        .dateReadTime {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #130F26;
            margin-bottom: 8px;
            display: flex;
            justify-content: space-between;
        }

        .authorInfo {
            display: flex;
            align-items: center;
            margin-top: 16px;
            ;

            .authorImgCtn {
                width: 50px;
                height: 50px;
                object-fit: cover;
                border-radius: 50%;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }

            .authorNameSpecialization {
                padding: 8px 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 8px;

                .authorName {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #130F26;
                }

                .specialization {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    color: #130F26;
                    opacity: 0.7
                }
            }
        }

        .articleTitle {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #130F26;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        .articleCoverImage {
            width: 100%;
            height: 216px;
            object-fit: cover;
            margin-bottom: 16px;
            border-radius: 12px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .articleContent {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            width: 100%;

            .editorContainer {
                width: 100%;
                height: 100%;

                a {
                    color: #00866E;
                    text-decoration: none;
                    font-weight: 600;
                    width: 100%;
                    word-wrap: break-word;
                }


                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                    margin-bottom: 16px;
                    border-radius: 12px;
                }
            }

            .ql-container.ql-snow {
                .ql-editor {
                    border: none !important;
                }
            }



        }

    }

    .similarArticles {
        padding: 0 24px 0;
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .headerText {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #130F26;
            margin: 0;
        }

        .seeAllText {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #00866E;
            opacity: 0.7;
            margin-right: 5px;
        }

    }
}