.header {
    padding: 0 24px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;


    .headerTop {
        height: 68px;
        display: flex;
        align-items: center;

        .backIcon {
            width: 24px !important;
            height: 24px !important;
            color: #130F26;
        }

        .searchText {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
            margin: 0 auto;
            transform: translateX(-15px);
            text-transform: capitalize;
        }
    }



}


.appBar {
    box-shadow: none !important;
    // border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1) !important;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 68px !important;
    z-index: 1001 !important;
    background-color: #fff !important;


    .tab {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        text-transform: none;
        color: #130F26;
        min-width: 0px;
        border-bottom: 2px solid rgba($color: #130F26, $alpha: 0.1);
    }


}