.dialog {
    z-index: 2001 !important;
    
    .header {
        display: flex;
        align-items: center;
        padding: 10px 24px;
        background: #FFFFFF;
        box-shadow: 0px 0px 40px rgb(0 0 0 / 10%);        
    }

    .thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgba(0, 134, 110, 0.06);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        margin-top: 32px;
    }

    .editIcon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .dialogAction {
        padding: 8px 24px;
    }

    .button{
        background: #00866E;
        box-shadow: 0px 10px 10px rgb(0 134 110 / 20%);
        border-radius: 100px;
        width: 66px;
        height: 66px;
        margin-bottom: 16px;
    }
}