.homePage {
    position: relative;
    padding-bottom: 96px;
}

.header {
    height: 56px;
    padding: 0 24px 0;
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;

    .icon {
        flex: none;
        width: 24px !important;
        height: 24px !important;
        color: #130F26;
        padding: 0;
        margin-right: 16px;
    }

    .arrowback {
        width: 24px;
        height: 24px;
    }

    .searchInput {
        flex-grow: 1;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.5);
    }
}

.listTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #130F26;
    padding: 24px 16px;
}

.list {

    .listItem {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .listAvatar {
        min-width: 64px;
    }

    .action {
        color: #00866E;
        width: 10%;
    }

    .avatar {
        width: 48px;
        height: 48px;
        border-radius: 100%;
    }


    .primaryText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
        margin-bottom: 5px;
    }

    .secondaryText {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: rgba(19, 15, 38, 0.6);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .joined {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 13px;
        color: #00866E;
    }

    .avatarGroupContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .avatarGroupIcon {
        width: 28px;
        height: 28px;
        font-family: Epilogue;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.01em;
        color: #0a0c86;
        background: linear-gradient(0deg, rgba(19, 15, 38, 0.7), rgba(19, 15, 38, 0.7)),
    }

    .avatarGroupText {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.01em;
        color: rgba(19, 15, 38, 0.6);
        margin-left: 8px;
    }

}