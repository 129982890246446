.container {
    z-index: 2000;
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    padding: 16px 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.makeMobile {
    z-index: 2000;
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    max-width: 375px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 16px 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.footerButton {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footerIcon {
        stroke: #130F26;
        margin-bottom: 6px;
    }

    .footerLabel {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        color: rgba(19, 15, 38, 0.7);
    }
}

.footerButtonActive {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        width: 16px;
        height: 2px;
        background-color: #00866E;
        border-radius: 5px;
    }

    .footerIcon {
        stroke: #00866E !important;
    }

    .footerLabel {
        color: #00866E !important;
    }
}