.showValueVertical{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
 
}
.valueText{

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
}

.showValueHorizontal{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 8px;
}