.page {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  padding: 24px 0;
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    padding-bottom: 50px;
    .head {
      color: #130f26;
      text-align: center;
      font-family: Epilogue;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .input {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .inputDate {
    border-bottom: 1px solid black;
  }
}
.dateTimeContainer {
  display: flex;
  gap: 23px;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;

  .date {
    display: flex;
    flex-direction: column;
    max-width: 152px;
    width: 100%;

    .dateField {
      display: flex;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      justify-content: space-between;
      align-items: center;
      color: #000000;
      //   gap: 10.5px;
      border-bottom: 1px solid rgba(19, 15, 38, 0.3);
      //   padding: 7px 0;
    }
  }

  .time {
    display: flex;
    flex-direction: column;
    max-width: 152px;
    width: 100%;

    .timeField {
      display: flex;
      //   gap: 10.5px;
      border-bottom: 1px solid rgba(19, 15, 38, 0.3);
      //   padding: 7px 0;

      .timeContainer {
        max-width: 120px;
        border: none;
      }
    }
  }

  .headers {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    padding: 0;
    color: rgba(19, 15, 38, 0.6);
  }

  .text {
    margin: 0;
  }

  .input {
    border: none;
  }
}
.notes {
  padding: 20px;
}
.allSymptoms {
  //   margin-top: 48px;
  padding: 20px;
  .chip {
    height: 46px;
    min-width: 56px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #130f26;
    background-color: #fff;
    border-radius: 100px;
    margin: 0 12px 12px 0;
    mix-blend-mode: normal;
    border: 1.5px solid rgba($color: #130f26, $alpha: 0.2);
    text-transform: capitalize;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }
  }

  .chipSelected {
    height: 46px;
    min-width: 56px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #ffff;
    background-color: #130f26;
    border-radius: 100px;
    margin: 0 12px 12px 0;
    text-transform: capitalize;

    &:hover {
      background-color: #130f26;
    }

    &:focus {
      background-color: #130f26;
    }
  }

  .addChip {
    height: 46px;
    min-width: 56px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #00866e;
    background-color: #fff;
    border: 1.5px solid rgba(0, 134, 110, 0.2);
    border-radius: 100px;
    margin: 0 12px 12px 0;
    text-transform: capitalize;
  }
}
.btn {
  background-color: #00866e;
  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
  border: none !important;
  border-radius: 100px !important;
  text-transform: none !important;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 17px 32px 15px 32px;
  border-radius: 100px;
  width: 200px;
  margin: auto;
  z-index: 1;
  &:disabled {
    pointer-events: auto;
    opacity: 0.8;
    backdrop-filter: blur(10px);
  }
}
.others {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	padding-top: 56px;

	.text {
		font-weight: 600;
		font-size: 24px;
		line-height: 140%;
		text-align: center;
		color: #130F26;
		;
		padding-top: 20px;
	}

	.subText {
		font-weight: 400;
		font-size: 14px;
		line-height: 160%;
		text-align: center;
		color: #130F26;
		padding-bottom: 150px;
	}

	img {
		width: 260px;
		height: 260px;
	}

	.otherConditionsContainer{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 16px;
		position: relative;
	
		.chip{
			display: inline-flex;
			padding: 9px 9px 9px 20px;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			gap: 8px;
			border-radius: 100px;
			border: 1.5px solid rgba(19, 15, 38, 0.20);
		}
	
	
	}

}
.main {
	width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
	padding: 20px 0;
	background-color: #fff;
  z-index: 1000;
	.pad {
		padding: 0 20px;
	}

	.addInput {
		border: none;
		outline: none;
	}

	::placeholder {
		color: #130F26;
		opacity: 0.3;
	}

	:-ms-input-placeholder {
		color: #130F26;
		opacity: 0.3;
	}

	::-ms-input-placeholder {
		color: #130F26;
		opacity: 0.3;
	}

	.title {
		color: #000000;
		font-size: 20px;
		font-weight: 600;
		line-height: 20px;
		color: #130F26;
	}

	.sub {
		font-size: 14px;
		font-weight: 400px;
		color: rgba(19, 15, 38, 0.6);
		margin-bottom: 20px;
		line-height: 19px;
		margin-top: 15px;
	}

	.tabHead {
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;

		.activeTab {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #00866E;
			width: 50%;
			border-bottom: 2px solid #00866E;
			padding-bottom: 14px;

		}

		.nonActiveTab {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #130F26;
			width: 50%;
			border-bottom: 0.5px solid #d4d2d8;

		}
	}

	.click {
		color: #06b494;
		cursor: pointer;
		text-decoration: underline;
		display: block;
		margin-top: 10px;
	}

	.chip {
		height: 46px;
		font-style: normal;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400px;
		padding: 0 10px;
		color: #130F26;
		background-color: #fff;
		border-radius: 100px;
		margin: 0 12px 12px 0;
		mix-blend-mode: normal;
		border: 1.5px solid rgba($color: #130F26, $alpha:0.2);
		text-transform: capitalize;
		&:hover {
			background-color: transparent;
		}

		;

		&:focus {
			background-color: transparent;
		}
	}

	.chipSelected {
		height: 46px;
		font-style: normal;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400px;
		padding: 0 10px;
		color: #ffff;
		background-color: #130F26;
		border-radius: 100px;
		margin: 0 12px 12px 0;
		text-transform: capitalize;
		&:hover {
			background-color: #130F26;
		}

		;

		&:focus {
			background-color: #130F26;
		}
	}

	.addChip {
		height: 46px;
		font-style: normal;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400px;
		padding: 0 10px;
		color: #00866E;
		background-color: #fff;
		border: 1px solid #00866E;
		border-radius: 100px;
		margin: 0 12px 12px 0;
		text-transform: capitalize;
	}
}
.bottomButton {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: auto;
	margin-top: 30px;


	.prev {
		width: 48px;
		height: 48px;
		background: rgba(0, 134, 110, 0.1);
		border-radius: 1000px;
		color: #00866E;
		border: none;
		outline: none;
		font-size: 20px;
		font-weight: 800;
		position: fixed;
		bottom: 100px;
		left: 20px;
	}

	.next {
		width: 104px;
		height: 48px;
		background: #00866E;
		box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
		border-radius: 100px;
		color: white;
		border: none;
		outline: none;
		position: fixed;
		bottom: 100px;
		right: 20px;
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
	}

	.more {
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		text-align: center;
		color: #00866E;
		cursor: pointer;
		margin-top: 20px;
        position: fixed;
		bottom: 100px;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
	}

	.nextCenter {
		width: 104px;
		height: 48px;
		background: #00866E;
		box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
		border-radius: 100px;
		color: white;
		border: none;
		outline: none;
		position: fixed;
		bottom: 130px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
	}
}
.td {
	display: flex;
	flex-direction: row;
	.t, .d {
		width: 180px;
		font-size: 12px;
	}
}