.page {
    position: relative;
    height: 95vh;
}



.contentContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1200;
    overflow-y: auto;

    .headerContainer {
        height: 56px;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0px;
        width: 100%;
        padding: 0 24px;
        background-color: #fff;
        z-index: 1300;
        display: flex;
        align-items: center;

        .logo {
            width: auto;
            height: 32px;
            cursor: pointer;
        }

        .arrowback {
            width: 24px;
            height: 24px;
        }
    }

    .appBar {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 56px;
        box-shadow: none !important;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        background-color: #fff !important;
        margin-bottom: 40px;

        .tab {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: rgba(19, 15, 38, 0.7);
            text-align: center;
            text-transform: none;
        }

        .selected {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            color: #00866E;
        }
    }


    .img {
        margin-top: 48px;
        display: block;
        width: 50%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
    }

    .headerText {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: #130F26;
        text-align: center;
        padding: 0 24px;
        margin-bottom: 4px;
    }

    .subText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #130F26;
        text-align: center;
        padding: 0 24px;
        margin-bottom: 40px;
    }



    .buttonContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        // padding-bottom: 22px;
    }

    .googleLoginButton {
        flex: none;
        width: 60px !important;
        height: 60px !important;
        border-radius: 12px !important;
        background-color: rgba(19, 15, 38, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none !important;

        .googleLoginButtonIcon {
            width: 32px;
            height: 32px;
            border-radius: 100%;
        }
    }

    .appleLoginButton {
        flex: none;
        width: 60px !important;
        height: 60px !important;
        border-radius: 12px !important;
        background-color: rgba(19, 15, 38, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none !important;


        .appleLoginButtonIcon {
            width: 26.05px;
            height: 32px;
        }

    }

    .facebookLoginButton {
        flex: none;
        width: 60px !important;
        height: 60px !important;
        border-radius: 12px !important;
        background-color: rgba(19, 15, 38, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none !important;


        .facebookLoginButtonIcon {
            width: 32px;
            height: 32px;
            border-radius: 100%;
        }

    }

    .withEmailContainer {
        padding: 24px;

        .line {
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            position: relative;
            height: 21px;
            margin-bottom: 50px;

            .lineText {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                padding: 0 21px;
                display: inline-block;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 160%;
                text-align: center;
                color: rgba(19, 15, 38, 0.7);
                white-space: nowrap
            }
        }

        .withEmailButton {
            width: 100%;
            height: 56px;
            background-color: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
            border: 2px solid rgba(19, 15, 38, 0.1);
            border-radius: 100px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #130F26;
        }
    }


    .emailPageContent {
        padding: 24px;

        .textField {
            margin-bottom: 28px;
        }

        .helperText {
            flex-grow: 1;
            display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #BAC2D0;
            margin-right: 16px;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 28px;

            .timer {
                display: inline-block;
                width: 40px;
                min-width: 40px;
                max-width: 40px;
                text-align: center;
            }
        }

        .resend {
            height: 48px;
            border-radius: 100px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #FFF;
            text-transform: none;
            margin-bottom: 28px;

            .icon {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }

        .button {
            height: 48px;
            border-radius: 100px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #FFF;
            text-transform: none;
        }
    }
}


.splashScreen {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #019079;
    z-index: 2000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .video {
        width: 100%;
        height: 100%;
    }

}