.page {
    background-color: #fff;
    min-height: calc(101vh - 56px);
    padding-bottom: 76px;
}

.header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;

    .inputContainer {
        flex: 1;
        height: 40px;
        background-color: rgba(19, 15, 38, 0.05);
        border-radius: 100px;
        display: flex;
        align-items: center;
        padding: 12px 18px;
    }

    .searchIcon {
        margin-right: 12px;
    }

    .headerIcon {
        margin-left: 12px;
    }
}

.advert {
    padding: 24px;

    .content {
        display: block;
        position: relative;
        width: 100%;
        height: 112px;
        border-radius: 20px;
        padding: 20px 20px 0;
        cursor: pointer;
        text-decoration: none;


        &:visited {
            text-decoration: none;
        }

        &:active {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        &.dietitian {
            background-image: url(./../../assets/recipes/ad-dietitian-top-bg.png), url(./../../assets/recipes/ad-dietitian-bottom-bg.png);
            background-size: 61px 63px, 112px 52px;
            background-position: 8.26% -6.25%, 70.72% 100%;
            background-repeat: no-repeat;
            background-color: #1B4664;
            color: #fff;

            .title {
                max-width: 246px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 10px;
                z-index: 1;
            }

            .subTitle {
                max-width: 200px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 0;
                z-index: 1;
            }

            .image {
                display: inline-block;
                position: absolute;
                width: 94.23px;
                height: 122px;
                right: 5px;
                bottom: 0%;
                z-index: 1;
            }
        }
    }
}



.sliderCategoryContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 116;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 24px;


    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    .sliderCategoryContent {
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
    }

    .sliderCategoryContentImage {
        width: 64px;
        height: 64px;
        border-radius: 12px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: rgba($color: #000000, $alpha: 0.5);
        margin-bottom: 8px;
    }

    .sliderCategoryContentText {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #000000;
        width: 70px;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        margin-bottom: 8px;
        /* number of lines to show */
        height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.slider {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.card {
    flex: none;
    width: 204px !important;
    height: 268px !important;
    mix-blend-mode: normal !important;
    border-radius: 20px !important;
    // box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08) !important;
    outline: none !important;
    margin-bottom: 12px;
    margin-left: 16px;
    cursor: pointer;

    &:focus {
        outline: none;
    }


    .img {
        height: 128px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: rgba(0, 134, 110, 0.06);
    }

    .titleContainer {
        padding: 0 16px;
        display: flex;
        align-items: center;
        position: relative;
    }

    .chefImg {
        width: 36px;
        height: 36px;
        margin-right: 8px;
        border: 2px solid #fff;
        overflow: hidden !important;
        transform: translateY(-25%);

        img {
            border-radius: 100%;
            overflow: hidden;
        }
    }


    .chefName {
        text-transform: capitalize;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #000000;
    }

    .title {
        text-transform: capitalize;
        padding: 0 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 12px;
        /* number of lines to show */
        height: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .line {
        border: 0.5px solid #000000;
        height: 0px;
        opacity: 0.1;
    }

    .footer {
        padding: 0 16px;
        height: 39px;
        display: flex;
        align-items: center;

        .img {
            width: 14px;
            height: 14px;
            margin-right: 7px;
        }

        .footerText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #130F26;
        }
    }

}

.containerForYou {

    .typographyHeader {
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 20px !important;
        color: #000000 !important;
        padding-left: 24px;
        margin-bottom: 12px;
    }
}

.containerHighLight {
    padding-bottom: 10px;

    .typographyHeader {
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 20px !important;
        color: #000000 !important;
        padding-left: 24px;
    }
}

.containerPopular {
    padding-top: 12px;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 24px;

    .typographyHeader {
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 20px !important;
        color: #000000 !important;
    }
}


.fab {
    position: fixed !important;
    z-index: 1;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFFFFF !important;
    background-color: #00866E !important;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2) !important;
}