.page {
  position: relative;
  background-color: #fff;
  padding-bottom: 76px;
}

.header {
  height: 68px;
  padding: 0 24px 12px;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .headertext {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
  }

  .headerIcon {
    margin-right: 24px;
  }
}

.sliderContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 24px;
  margin-bottom: 32px;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .sliderContent {
    flex: none;
    color: rgba(19, 15, 38, 0.70);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 100px;
    background: rgba(19, 15, 38, 0.08);
    padding: 8px 16px;
    margin-right: 12px;

    &.sliderContentActive {
      color: #FFF;
      font-weight: 500;
      background: #00866E;
    }

  }
}

.content {
  padding: 0 24px;
}

.totalPrice {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.08px;
}

.transactionText {
  color: rgba(19, 15, 38, 0.60);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 50px;
}

.checkoutcardContainer {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;

  .checkoutcardProfile {
    flex: none;
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .checkoutcardTextContainer {
    flex-basis: 0;
    flex-grow: 1;
  }

  .checkoutcardTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #130F26;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }

  .checkoutcardBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.60);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .cardElli {
    flex: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 5px;
  }
}