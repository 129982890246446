.sliderTagContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 24px;
    margin-bottom: 32px;

    .list {
        // border-top: 1px solid rgba(208, 208, 210, 0.44);
        padding-top: 10px;
        padding-bottom: 30px;
        padding-right: 30px;
        .chart {
            width: 100%;
        }
        .meals {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .number {
                font-weight: 600;
                font-size: 22px;
                line-height: 17px;                
                text-align: center;  
                color: #000000;
                padding-bottom: 10px;
                padding-top: 20px;
            }
            .type {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;                
                text-align: center;                
                color: #000000;
                padding-bottom: 10px;
            }
            .amount {
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;                
                text-align: center;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }

    .both {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        .day {
            display: flex;
            flex-direction: row;
            align-items: center;
            .icon {
                width: 48px;
                height: 48px;
            }
            .data {
                font-style: normal;
                margin-left: 10px;
                .title {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 14px;
                    color: #130F26;
                    padding-bottom: 5px;
                }
                .sub {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 13px;
                    color: rgba(19, 15, 38, 0.6);
                }
            }
        }
    }

    .progressBarCtn{
        margin-top: 24px;
        display: flex;
        gap: 11px;
    
        .progress{
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
        }
    
        .progressBarTitle{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #130F26;
        }
    }

}  

.tooltip {
    border-radius: 8px 8px 8px 0px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    color: #000;
    text-align: center;
    font-family: Epilogue;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 15px;
  }