.page {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  padding: 24px 0;
}

.header {
  height: 68px;
  padding: 0 24px 12px;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(243, 241, 241);

  .back {
    margin-right: 16px;
  }

  .avater {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  .headerTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: #130f26;
    margin-bottom: 6px;
  }

  .headerSubText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
  }
  .new {
   position: absolute;
   right: 30px;
   top: 17px;
  }
}

.content {
  margin-top: 20px;
  .add {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    border-bottom: 1px solid rgb(243, 241, 241);
    cursor: pointer;

    .icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 90%;
      .text {
        margin-bottom: 10px;
        padding: 10px 0;
        width: 80%;
      }
      img {
        margin: 0;
        padding: 0;
      }
      .name {
        color: #130f26;
        font-weight: 600;
        font-size: 14px;
      }
      .sub {
        font-size: 11px;
        color: rgba(19, 15, 38, 0.6);
        font-family: Epilogue;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .index {
        background-color: rgba(0, 134, 110, 0.1);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--Green, #00866e);
        font-family: Epilogue;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 13px;
      }
    }
  }
}

.tasks {
  display: flex;
  flex-direction: column;
  margin: 30px;
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .todo {
      color: #130f26;
      font-family: Epilogue;
      font-size: 20.198px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .status {
      color: var(--Green, #00866e);
      text-align: right;
      font-family: Epilogue;
      font-size: 14.138px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .task {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;    
      
      .taskHeader {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;        
      }
    }
    .add {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 1px solid rgb(243, 241, 241);
      background: rgba(255, 245, 221, 1);
      margin-top: 10px;
      padding: 12px;
      border-radius: 20px;
      width: 100%;
      gap: 5px;

      .icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        .text {
          margin-bottom: 10px;
        }
        img {
          margin: 0;
          padding: 0;
        }
        .name {
          color: #130f26;
          font-weight: 600;
          font-size: 14px;
        }
        .name::first-letter {
          text-transform: uppercase;
        }
        .sub {
          font-size: 11px;
          color: rgba(19, 15, 38, 0.6);
          font-family: Epilogue;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .index {
          background-color: rgba(0, 134, 110, 0.1);
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--Green, #00866e);
          font-family: Epilogue;
          font-size: 21px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 13px;
        }
      }
      .arr {
        background: rgba(19, 15, 38, 0.04);
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
