.sectionCtn {
   position: relative;
   margin-top: 60px;
   padding-bottom: 100px;

   .container {
      margin-top: calc(50vh - 60px);
      width: 100%;
      margin-bottom: calc(50vh - 100px);

      .timeContainer {
         scroll-margin-top: 118px;
         display: flex;
         gap: 1rem;
         min-height: 60px;
         margin: 0 1rem;
         border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
         position: relative;

         .text {
            align-self: flex-end;
            margin: 0;
            font-family: "Epilogue";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: rgba(19, 15, 38, 0.6);
            width: 28px;
            height: 12px;
            white-space: nowrap;
         }
      }
   }
}

// droplet styles
.waterAddButton {
   display: flex;
   position: fixed;
   left: 50%;
   transform: translate(-50%, 0);
   bottom: 80px;

   img {
      cursor: pointer;
   }
}

// events style
.events {
   display: flex;
   flex-direction: column;
   width: 100%;
   margin: 8px 0;
   justify-content: center;
   gap: 4px;

   .containerEvents {
      border-radius: 10px;
      max-width: 303px;
      display: flex;
      flex-direction: column;
      width: 100%;
      background: #e5f3f0 !important;
      padding: 12px;
      gap: 6px;

      .active {
         background: #e5f3f0 !important;
      }

      &:hover {
         background: #e5f3f0;
         border-color: #16cc9b;
      }

      &:hover > .button {
         border-color: #16cc9b;
         background-color: #16cc9b;
      }

      .header {
         display: flex;
         gap: 6px;
         font-family: "Epilogue";
         font-style: normal;
         font-weight: 600;
         font-size: 12px;
         line-height: 12px;
         letter-spacing: 0.04em;

         .time {
            color: #130f26;
            margin: 0;
         }

         .tag {
            color: #00866e;
            margin: 0;
         }
      }

      .content {
         font-family: "Epilogue";
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 14px;
         color: #130f26;
         margin-bottom: 6px;
         text-transform: capitalize;
      }
   }

   .containerNoEvents {
      display: inline-block;
      flex-direction: row;
      align-items: flex-start;
      padding: 11px 12px 9px;
      gap: 8px;
      max-width: 130px;
      width: fit-content;
      border-radius: 10px;

      .txt {
         font-style: normal;
         font-weight: 600;
         font-size: 12px;
         line-height: 12px;
         letter-spacing: 0.04em;
         margin: 0;
         text-transform: uppercase;
      }
   }

   .bgActive {
      background: #e5f3f0;
      color: #00866e;
   }

   .bgInactive {
      background: rgba(19, 15, 38, 0.1);
      color: rgba(19, 15, 38, 0.3);
   }

   .recipe {
      background-color: #e5f3f0;
      border-radius: 10px;
      padding: 12px 14px;

      .recipeHeaderContainer {
         display: flex;
         align-items: center;
         margin-bottom: 7px;

         .recipeHeaderTime {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.04em;
            color: #130f26;
            margin-right: 7px;
         }

         .recipeHeaderPlantext {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.04em;
            color: #00866e;
         }
      }

      .recipeContent {
         display: flex;
         align-items: center;
      }

      .recipeImage {
         flex: none;
         width: 40px;
         height: 40px;
         border-radius: 6px;
         background-position: center;
         background-repeat: no-repeat;
         background-size: cover;
         margin-right: 8px;
      }

      .recipeTitle {
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 19px;
         color: #130f26;
         // white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         /* number of lines to show */
         height: 38px;
         overflow: hidden;
         text-overflow: ellipsis;
         display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
      }

      .recipeTitleHeader {
         font-weight: 600;
      }
   }

   .water {
      display: flex;
      gap: 8px;
      padding: 8px 12px;
      align-items: center;
      background: rgba(210, 224, 245, 0.7);
      border-radius: 10px;
      max-width: 88px;

      .litre {
         font-family: "Epilogue";
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 14px;
         color: #130f26;
         margin: 0;
      }
   }

   .weight {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      max-width: 95px;
      height: 32px;
      background: rgba(129, 61, 216, 0.13);
      border-radius: 10px;

      .kg {
         font-family: "Epilogue";
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 14px;
         margin: 0;

         /* Red */

         color: #813dd8;
      }
   }

   .symptoms {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      background: rgba(238, 61, 63, 0.1);
      border-radius: 10px;
      max-width: 303px;
      flex-wrap: wrap;

      .condition {
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 14px;
         margin: 0;
         text-transform: capitalize;
         /* Red */

         color: #ee3d3f;
      }
   }
}

// day
.day {
   display: flex;
   position: fixed;
   align-items: flex-start;
   top: 124px;
   left: 50%;
   transform: translate(-50%, 0);
   padding: 8px 12px;
   background: #130f26;
   border-radius: 100px;
   z-index: 2000;
   width: 60px;
   margin: auto;
   margin-bottom: 20px;

   .span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
   }
}

.hide {
   display: none;
}

.metricline {
   position: relative;
}

.metricline::before {
   content: "";
   display: block;
   position: fixed;
   top: 50%;
   // left: 0;
   border-top: 2px solid #d5d1d1;
   width: 100%;
   transform: translateY(-50%);
   z-index: 1000;
   max-width: 375px;
}

.noScroll {
   overflow: hidden;
}
.sin {
   width: 79px;
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   color: rgba(19, 15, 38, 0.6);
}

sin b {
   color: #000000;
}
.fles {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   z-index: 1;
   background-color: white;
   width: 100%;
   padding-top: 10px;
   padding-left: 25px;
   padding-right: 25px;
}
::-webkit-progress-bar {
   background-color: rgba(19, 15, 38, 0.2);
}

::-webkit-progress-value {
   background-color: #130F26;
 }
 
 ::-moz-progress-bar {
   background-color: #130F26;
 }

 progress {
   -webkit-appearance: none;
   border: 0;
   height: 7px;
   border-radius: 20px;
   margin: 10px 0;
   width: 68px;
}
progress::-webkit-progress-bar {
   border: 0;
   height: 7px;
   border-radius: 20px;
}
progress::-webkit-progress-value {
   border: 0;
   height: 7px;
   border-radius: 20px;
}
progress::-moz-progress-bar {
   border: 0;
   height: 7px;
   border-radius: 20px;
}

.bod {
   border-right: 1px solid rgba(19, 15, 38, 0.2);
}
.data {
   position: fixed;
   top: 0px;
   width: 400px;
   background-color: white;
   z-index: 1;
   height: 150px;
   padding-top: 20px;
}

.con {
   display: flex;
   overflow-x: auto;
   height: 80px;
   padding: 10px;
   padding-bottom: 20px;
   -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;
   z-index: 1;
   // overflow: hidden
   background-color: white;
 }
 .con::-webkit-scrollbar { 
   display: none;  /* Safari and Chrome */
}
 
 .item {
   flex: 0 0 auto;
   width: 32px;
   height: 32px;
   background-color: rgba(19, 15, 38, 0.1);
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 18px;
   font-weight: bold;
   border-radius: 50%;
 }
 .selected {
   background-color: #00866E;
   color: white;
 }
 .tit {
   font-weight: 400;
   font-size: 12px;
   line-height: 11px;
   color: rgba(0, 0, 0, 0.7);
 }
 .allBoth {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   margin: 0 5px;
 }
 .date {
   width: 110px;
 }
 .icons img {
   margin: 0 10px;
 }
 .bothof {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
   padding-top: 25px;
   padding-left: 25px;
   padding-right: 25px;
 }