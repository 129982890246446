.switchOn{
    display: inline-block;
    position: relative;
    width: 52px;
    height: 32px;
    background-color: #00866E;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    .toggle {
        position: absolute;
        width: 48px;
        height: 48px;
        right: -8px;
        top: calc(50% - 48px/2);

        .toggleHandle{
            position: absolute;
            width: 24px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 23px;
            border: none;
            transition: left 0.2s ease;
            left: calc(50% - 24px/2);
            top: calc(50% - 24px/2);
        }
    }
}

.switchOff {
    display: inline-block;
    position: relative;
    width: 52px;
    height: 32px;
    background-color: #130F26;
    border-radius: 100px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    .toggle {
        position: absolute;
        width: 48px;
        height: 48px;
        left: -8px;
        top: calc(50% - 48px/2);

        .toggleHandle {
            position: absolute;
            width: 24px;
            height: 24px;
            background: #FFFFFF;
            border-radius: 23px;
            border: none;
            transition: left 0.2s ease;
            left: calc(50% - 24px/2);
            top: calc(50% - 24px/2);
        }
    }
}