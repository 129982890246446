body .confirmButton {
  width: 208px;
  height: 48px;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100px !important;
  text-transform: none !important;
  overflow: hidden !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  background-color: #00866e;
  z-index: 2;
  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.5);

  &:disabled {
    pointer-events: none;
    opacity: 0.8;
  }
}

.page {
  position: relative;
  background-color: #fff;
  padding-bottom: 204px;
}

.header {
  height: 68px;
  padding: 0 24px 12px;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2000;
  display: flex;
  align-items: center;

  .headerContentcontainer {
    flex: 1;
  }

  .back {
    margin-right: 16px;
  }

  .avater {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  .headerTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: #130f26;
    margin-bottom: 6px;
  }

  .headerSubText {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.container {
  padding: 0 24px;

  .titleContainer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 25.5px 0;
    margin-bottom: 37px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .titleImage {
      width: 48px;
      height: 48px;
      background: rgba(0, 134, 110, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 22px;
      color: #00866e;
      margin-right: 16px;
      margin-bottom: 12px;
      border-radius: 100%;
    }

    .titleTextContainer {
      flex: 1;
    }

    .titleHeadertext {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #130f26;
      margin-bottom: 8px;
      text-transform: capitalize;
      width: 100%;
    }

    .titleHeaderSubText {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: rgba(19, 15, 38, 0.6);
      text-transform: capitalize;
    }
  }

  .listHeaderContainer {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 21px;
    color: #130f26;

    svg {
      margin-right: 20px;
    }
  }

  .listText {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #130f26;
    display: flex;
    align-items: flex-start;
    gap: 5px;

    .listTextValue {
      flex: 1;
    }

    .listMoreIcon {
      transform: translateY(-30%);
    }
  }

  .addListText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #130f26;
    opacity: 0.5;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 45px;
  }
  .addListText1 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #130f26;
    opacity: 0.5;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .descriptionInput {
    background-color: rgba(0, 134, 110, 0.1);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 12px;
  }
}

.fab {
  position: fixed !important;
  z-index: 1;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff !important;
  background-color: #00866e !important;
  box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2) !important;
}
.btms {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 50px;
  .btm {
    border-radius: 20px;
    background: rgba(19, 15, 38, 0.04);
    display: flex;
    width: 156px;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    height: 122px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    .name {
      color: #130f26;
      font-family: Epilogue;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }
    .new {
      display: flex;
      padding: 6px 6px 3px 6px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: #fff;
      color: #130f26;
      text-align: right;
      font-family: Epilogue;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.12px;
    }
    img {
      margin-bottom: 5px;
    }
  }
}
.btn {
  border: 1.5px solid var(--Utility-Grey, #5a5767);
  background: var(--Utility-Grey, #5a5767);
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  color: var(--Brand-Green-100, #ebf5f3);
  border-radius: 100px !important;
  z-index: 30;
  text-transform: none !important;
  overflow: hidden !important;
  height: 100%;
  padding: 10px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  &:disabled {
    pointer-events: auto;
    opacity: 0.8;
    backdrop-filter: blur(10px);
  }
}
