.productWidgetContainer {
    position: relative;
    width: 100%;
    height: 265px;
    border-radius: 20px !important;
    box-shadow: none !important;
    padding-top: 20px;
    cursor: pointer;

    .add {
        min-width: 36px;
        min-height: 36px;
        text-align: center;
        padding: 6px;
        position: absolute;
        top: 0px;
        right: 0px;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        border-bottom-left-radius: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #130F26;
    }

    .selected {
        min-width: 36px;
        min-height: 36px;
        text-align: center;
        padding: 6px;
        position: absolute;
        top: 0px;
        right: 0px;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        border-bottom-left-radius: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #00866E;
    }

    .img {
        width: 112.44px;
        height: 132px;
        mix-blend-mode: normal;
        margin-left: auto;
        margin-right: auto;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 17px;
    }

    .desc {
        height: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        padding: 0 12px;
        margin-bottom: 5px;
        text-transform: capitalize;
    }

    .name {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #130F26;
        padding: 0 12px;
        margin-bottom: 10px;
        text-transform: capitalize;
    }

    .size {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.01em;
        color: rgba(19, 15, 38, 0.7);
        padding: 0 12px;
        text-transform: capitalize;
    }

    .pointContainer {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 12px;
        height: 32px;
        border-top: 0.5px solid rgba(19, 15, 38, 0.2);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.01em;
        color: #130F26;

        .avatar {
            width: 14px;
            height: 14px;
            margin-right: 6px;
        }

        .leftText {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: -0.01em;
            color: #130F26;
        }

        .packText {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            text-align: right;
            letter-spacing: -0.01em;
            color: rgba(19, 15, 38, 0.7);
            margin-left: auto;
        }


    }
}


.productWidgetContainerAsyc {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 20px;
    margin-right: 10px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible !important;

    .remove {
        position: absolute;
        top: -10px;
        right: -10px;
    }

    .removeIcon {
        width: 16px;
        height: 16px;
        padding: 3px;
        color: #fff;
        background-color: #00866E;
        border-radius: 50%;
    }

    .img {
        width: 53px;
        height: 53px;
        mix-blend-mode: normal;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
    }

}