.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 16px;

    .container {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
    }


}