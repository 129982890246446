body .confirmButton {
    width: 208px;
    height: 48px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100px !important;
    text-transform: none !important;
    overflow: hidden !important;


    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    background-color: #00866E;
    z-index: 2;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.5);

    &:disabled {
        pointer-events: none;
        opacity: 0.8;
    }
}

.page {
    position: relative;
    background-color: #fff;
    padding-bottom: 204px;
}


.header {
    height: 68px;
    padding: 0 24px 12px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headerTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        color: #130F26;
    }
}


.container {
    padding: 20px 24px 0;


    .titleContainer {

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 17.5px 0;
        display: flex;
        align-items: center;

        &:first-of-type {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }


        .titleImage {
            width: 48px;
            height: 48px;
            background: rgba(0, 134, 110, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            font-size: 21px;
            line-height: 22px;
            color: #00866E;
            margin-right: 16px;
            margin-bottom: 12px;
            border-radius: 100%;
        }

        .titleTextContainer {
            flex: 1
        }

        .titleHeadertext {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #130F26;
            margin-bottom: 8px;
            text-transform: capitalize;
        }

        .titleHeaderSubText {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 13px;
            color: rgba(19, 15, 38, 0.6);
            text-transform: capitalize;
        }
    }
}





.fab {
    position: fixed !important;
    z-index: 1;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFFFFF !important;
    background-color: #00866E !important;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2) !important;
}