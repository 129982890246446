.page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: inherit;
    background: rgba(19, 15, 38, 0.2);
    position: fixed;
    padding-top: 66px;
    top: 0;
    bottom: 0;
    z-index: 6000;
    transition: all 0.5s ease-in-out;
    opacity: 1;
    pointer-events: visible;
    
    
    .modal{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border-radius: 20px 20px 0 0;
        position: relative;
        padding: 24px;
        overflow: scroll;
        transition: all 0.5s ease-in-out;
        -ms-overflow-style: none;
        scrollbar-width: none;

            /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    }

    .modalHeader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        // padding: 20px 20px 0 20px;
        // border-bottom: 1px solid #E5E5E5;
        position: relative;

        .modalTitle {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            margin-top: 15px;
            /* or 34px */

            text-align: center;

            color: #130F26;
        }

        .description{
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            /* or 20px */
            text-align: center;
            opacity: 0.6;
            color: #130F26;
        }
       
    }

    .modalBody{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 28px;
        bottom: 0;
    }

    .benefitsTitle{
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.01em;
        padding: 12px 0;
        color: #130F26;
        border-bottom: 0.5px solid rgba(19, 15, 38, 0.1); 
    }

    .heading{
        display: flex;
        gap: 10px;
        padding: 12px 0;
        align-items: flex-start;
        border-bottom: 0.5px solid rgba(19, 15, 38, 0.1);       
    }

    .text{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #130F26;
    }

    .button{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
    }

    .btn{
        border: none;
        height: 56px;
        padding: 17px 32px 15px;
        background: #00866E;;
        border-radius: 100px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        color: #FFFFFF;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
    }
}

.hide{
    display: none;
}