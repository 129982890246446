.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;

    .img {
        width: 40%;
        height: auto;
        margin-bottom: 60px;
    }

    .header {
        width: 80%;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: #130F26;
    }

    .body {
        width: 70%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 160%;
        text-align: center;
        color: #130F26;
    }
}