.container{
    display: flex;
    justify-content: flex-start;
    gap: 16px;

    .imageContainer{
        width: 48px;
        height: 48px;
        border-radius: 100px;

        .img{
            width: 48px;
            height: 48px;
            border-radius: 100px;
        }
    }

    .list{
        width: 100%;

        .listItem{
            gap: 16px;
        }
    }

    .inline{
        display: inline;
    }


        .user{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #130F26;
            margin: 0;
            width: 100%;
            max-width: 100ch;
            text-overflow: ellipsis;
            text-transform: capitalize;

          
        }

        .date{
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
        }

        .icon{
                display: flex;
                width: 14px;
                height: 14px;
                cursor: pointer;
            }
  
            .body{
                text-transform: lowercase;
                font-size: 12px;

                .sender {
                    text-transform: capitalize;
                    font-size: 12px;
                    font-weight: 400;
                    color: #130F26;
                }
            }


 
}