.homePage {
    position: relative;
    padding-bottom: 96px;

    .confirmButton {
        width: 237px;
        height: 48px;
        position: fixed;
        left: 50%;
        bottom: 100px;
        transform: translateX(-50%);
        background-color: #00866E;
        box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.06);
        border: none !important;
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
        z-index: 1000;

        &:disabled {
            pointer-events: auto;
            opacity: 0.8;
            backdrop-filter: blur(10px);
        }
    }
}


.header {
    height: 56px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2000;

    .backIcon {
        width: 24px !important;
        height: 24px !important;
        color: #130F26;
    }

    .headerText {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        margin-left: 25px;
        margin-right: auto;
    }

    .stepText {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
        color: #130F26;
    }

    .stepTextRed {
        color: #00866E;
    }
}

.shippingDetailsContainer {
    background-color: rgba(0, 134, 110, 0.06);
    border-radius: 20px;
    padding: 22px 20px 8px;

    .shippingDetails {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 16px;
        color: #00866E;
    }

    .nameText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #130F26;
    }
}

.whenText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #130F26;
}

.appBar {
    box-shadow: none !important;
    border: none;
    background-color: #fff !important;

    .chip {
        height: 36px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #130F26;
        background-color: #ffff;
        border: 1.5px solid rgba(19, 15, 38, 0.2);
        border-radius: 100px;
        margin: 0 6px 0 0;
        text-transform: capitalize;

        &:hover {
            background-color: #ffff;
        }

        &:focus {
            background-color: #ffff;
        }
    }

    .chipSelected {
        height: 36px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #ffff;
        background-color: #130F26;
        border-radius: 100px;
        margin: 0 6px 0 0;
        text-transform: capitalize;

        &:hover {
            background-color: #130F26;
        }

        &:focus {
            background-color: #130F26;
        }
    }

    .indicator {
        display: none;
    }

}