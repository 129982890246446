.emailPageContent {
    padding: 24px;

    .subTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #3C3C3C;
        margin-bottom: 33px;
    }

    .textField {
        margin-bottom: 28px;
    }

    .helperText {
        flex-grow: 1;
        display: flex;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #BAC2D0;
        margin-right: 16px;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 28px;

        .timer {
            display: inline-block;
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            text-align: center;
        }
    }

    .resend {
        height: 48px;
        border-radius: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFF;
        text-transform: none;
        margin-bottom: 28px;
    }

    .button {
        height: 48px;
        border-radius: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #FFF;
        text-transform: none;
        margin-bottom: 28px;
    }
}
.pass {
    text-align: center;
    font-size: 20px;
    margin: 20px;
    margin-bottom: 40px;
}
.helpButton {
    position: fixed !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF !important;
    background-color: #00866E !important;
    width: 112px;
    height: 48px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.02);
    border-radius: 100px !important;
    z-index: 1000;
}

.helpIcon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 12px;
}
.cus {
    cursor: pointer;
}
.headerTop {
    position: fixed;
    top: 20px;
    left: 20px;
}
.forgot {
    cursor: pointer;
    font-size: 13px;
    color: #00866E !important;
}

.logoutIcon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 12px;
}


.logOutDialog {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #898989;
    z-index: 99999;
    cursor: pointer;


    .logOutText {
        color: #fff;
        width: 80%;
        text-align: center;
        margin-bottom: 40px;
        font-size: 1.3em;
    }

    .cancelLogoutButton {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        background-color: #f4f5f8;
        color: #000;
        width: 100%;
        height: 48px;
        z-index: 1;
    }

    .confirmLogoutButton {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 25px;
        background-color: #00866E;
        color: #fff;
        width: 100%;
        height: 48px;
        z-index: 1;
    }
}



.dialog {
    z-index: 2001 !important;

    .thumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 16px;
        background-color: rgba(0, 134, 110, 0.06);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        margin: auto;
    }

    .editIcon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .dialogAction {
        padding: 8px 24px;
    }
}