.page {
	position: relative;
	background-color: #fff;
	padding: 0 20px;
	height: 100vh;
}
.line{
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin: 15px 0;
}


.main {
	width: 100%;
	background-color: #fff;

	.pad {
		padding: 0 20px;
	}

	.title {
		color: #000000;
		font-size: 20px;
		font-weight: 600;
		line-height: 20px;
		color: #130F26;
	}

	.sub {
		font-size: 14px;
		font-weight: 400px;
		color: rgba(19, 15, 38, 0.6);
		margin-bottom: 20px;
		line-height: 19px;
		margin-top: 15px;
	}

	.click {
		color: #06b494;
		cursor: pointer;
		text-decoration: underline;
		display: block;
		margin-top: 10px;
	}




}

.dotContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	// bottom: 204px;
	left: 50%;
	transform: translateX(-50%);

	.dots {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		gap: 6px;
	
		.active {
			border-radius: 100px;
			background: #130F26;
			width: 18px;
			height: 6px;		
		}
	
		.inActive {
			border-radius: 100px;
			opacity: 0.3;
			background: #130F26;
			width: 6px;
			height: 6px;		
		}
	
	}
}

.bottomButton {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;

	.prev {
		width: 48px;
		height: 48px;
		background: rgba(0, 134, 110, 0.1);
		border-radius: 1000px;
		color: #00866E;
		border: none;
		outline: none;
		font-size: 20px;
		font-weight: 800;
	}

	.next {
		width: 104px;
		height: 48px;
		background: #00866E;
		box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
		border-radius: 100px;
		color: white;
		border: none;
		outline: none;
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
	}

	.more {
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		text-align: right;
		color: #00866E;
		cursor: pointer;
		margin-top: 20px;
        position: relative;
		bottom: 100px;
		left: 50%;
		transform: translateX(-50%);
	}

	.nextCenter {
		width: 104px;
		height: 48px;
		background: #00866E;
		box-shadow: 0px 10px 20px rgba(0, 134, 110, 0.2);
		border-radius: 100px;
		color: white;
		border: none;
		outline: none;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
	}
}



.imgBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px;
    margin-top: 100px;

	.img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

  .content {
	margin-top: 28px;
  }

	.text {
		font-weight: 600;
		font-size: 24px;
		line-height: 140%;
		text-align: center;
		color: #130F26;
		;
		padding-top: 20px;
	}

	.subText {
		font-weight: 400;
		font-size: 14px;
		line-height: 160%;
		text-align: center;
		color: #130F26;
		margin-top: 10px;
		padding-bottom: 50px;
	}
}


.header {
	height: 90px;
	color: #fff;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	font-size: 16px;

	.space {
		width: 100%;
		margin-top: 15px;
	}

	.down {
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		background-color: white;
		height: 200px;
		width: 100%;
	}

	.back {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px;
		width: 100%;
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
        margin-top: 20px;

		.logoCon {
			display: flex;
			width: 100px;

			.img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

	
	}
    .skip {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        text-decoration-line: underline;
        color: #130F26;
        cursor: pointer;
    }

}