.calenderly {
    height: 100vh;
    width: 100vw;
}
.back {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 35px;
    left: 30px;
}