.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 76px;

    .seeAllButton {
        width: 118px;
        height: 48px;
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        border: 2px solid rgba(19, 15, 38, 0.15) !important;
        border-radius: 100px !important;
        text-transform: none !important;
        overflow: hidden !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #130F26;
        background-color: #fff;
        z-index: 2;
    }


    .header {
        height: 56px;
        padding: 0 24px 0;
        display: flex;
        align-items: center;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;

        .arrowback {
            width: 24px;
            height: 24px;
        }

        .heartIcon {
            width: 24px;
            height: 24px;
        }

        .shareIcon {
            width: 17.78px;
            height: 20px;
        }
    }


    .headerImgContainer {
        width: 100%;
        height: 212px;
        position: relative;

        .headerImage {
            width: auto;
            max-width: 100%;
            height: 196px;
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            z-index: 2;
        }
    }



    .headerBottom {
        height: 32px;
        background-color: #fff;
        border-radius: 20px;
        transform: translateY(-16px);
    }

    .content {

        padding: 0 24px;
        padding-bottom: 16px;

        .contentHeading {
            display: flex;
            flex-direction: column;

            .text {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 8px;

                .size {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: -0.01em;
                    color: rgba(19, 15, 38, 0.7);
                    padding-top: 12px;
                }

            }
        }

        .desc {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            padding-bottom: 6px;
            text-transform: capitalize;
        }


        .name {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 25px;
            color: #130F26;
            text-transform: capitalize;
        }

        .activePrice {
            display: block;
            background-color: rgba(19, 15, 38, 0.1);
            border-radius: 8px;
            padding: 7px 10px 4px;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #130F26;
        }

        .nonActivePrice {
            display: block;
            padding: 7px 10px 4px;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #130F26;
        }

        .stockItems {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            text-align: right;
            letter-spacing: 0.01em;
            text-transform: lowercase;
            color: #130F26;
            white-space: nowrap;

        }

        .specialOfferCtn {
            max-width: 100%;
            border-top: 0.5px solid rgba(19, 15, 38, 0.12);
            border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;

            .specialPriceContainer{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #130F26;
                gap: 1rem;

            .specialOfferText{
                color:#FFFFFF;
                padding: 9px;
                background: #EE3D3F;
                border-radius: 8px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                letter-spacing: -0.01em;
                text-transform: uppercase;
                max-width: 75px;
                width: 100%;
            }

            .specialOfferDiscount{
                display: flex;
                align-items: center;
                gap: 0.8rem;
            }
        }

            .specialPrice {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #130F26;
            }
            
        }

        

        .priceContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            max-width: 100%;
            border-top: 0.5px solid rgba(19, 15, 38, 0.12);
            margin-top: 17px;

            .number {
                background: rgba(19, 15, 38, 0.7);
                border-radius: 6px;
                width: 32px;
                height: 32px;
                align-items: center;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 18px;
                letter-spacing: -0.01em;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
            }

        }

        .discountPriceContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-top: 0.5px solid rgba(19, 15, 38, 0.12);
            border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);
            max-width: 100%;

            .number {
                background: rgba(19, 15, 38, 0.7);
                color: #FFFFFF;
                border-radius: 6px;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 18px;
                letter-spacing: -0.01em;
                padding: 8px 7px 6px;
            }

            .leftContainer {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid rgba(19, 15, 38, 0.12);
                padding-top: 10px;
                padding-bottom: 10px;

                .percentage {
                    display: flex;
                    flex-direction: column;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: -0.01em;
                    color: #130F26;
                }
            }
        }

        .soldOutContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 16px 0;
        }

        .soldOut {
            flex: none;
            min-width: 73px;
            border-radius: 4px;
            padding: 7px 8px 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: #FFFFFF;
            background-color: rgba(19, 15, 38, 0.1);
        }

        .buttonContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 0;

            .calculator {
                background-color: rgba(0, 134, 110, 0.06);
                border-radius: 100px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 6px;
                flex-basis: 0;
                flex-grow: 1;
                margin-right: 12px;

                .number {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 20px;
                    color: #130F26;
                }
            }

            .buy {
                height: 56px;
                background-color: #00866E;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 0px;
                flex-basis: 0;
                flex-grow: 1;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                color: #FFFFFF;
                margin-left: 12px;
                text-transform: none;
            }
        }

        .deliveryContainer {
            padding: 32px 0;
            border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            .tag {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
                flex-wrap: nowrap;
                margin-bottom: 5px;


                .leftTag {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 13px;
                    letter-spacing: 0.01em;
                    color: #130F26;
                    opacity: 0.6;
                    padding-top: 17px;
                    white-space: nowrap;
                }

                .rightTag {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 13px;
                    text-align: right;
                    letter-spacing: 0.01em;
                    text-transform: capitalize;
                    color: #130F26;
                    margin-top: 6px;
                    white-space: nowrap;
                }
            }

        }


        .headerText {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: #130F26;
            margin-bottom: 12px;
            margin-top: 32px;
        }

        .aboutProductText {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.01em;
            color: rgba(19, 15, 38, 0.6);
            margin-bottom: 28px;
        }

        .aboutProductTextLess {
            /* number of lines to show */
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }


        .readMore {
            display: inline-block;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #00866E;
            margin-bottom: 28px;
            cursor: pointer;
        }

        .line {
            border-bottom: 0.5px solid rgba(19, 15, 38, 0.12);
            margin: 0 -24px 28px;
        }

    }

    .headerText {
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #130F26;
        margin-bottom: 12px;
    }


}