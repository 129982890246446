.page {
    background-color: #fff;
    position: relative;
    padding-bottom: 76px;


    .appBar {
        box-shadow: none !important;
        position: -webkit-sticky;
        position: sticky;
        top: 56px !important;
        z-index: 1000;
        border-bottom: none;
        background-color: #fff !important;
        padding: 0 18px;

        .tab {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: rgba(19, 15, 38, 0.7);
            text-transform: capitalize !important;
            min-width: 72px !important;
        }

        .tabSelected {
            color: #00866E;
        }

        .indicator {
            display: flex;
            justify-content: center;
            background-color: transparent !important;

            span {
                width: 100%;
                max-width: 40px;
                height: 2px;
                background-color: #00866E;
            }
        }

    }
}